import React, { FunctionComponent, useCallback, useState } from "react";
import { useIntl } from "react-intl";
import { parseIsbn } from "../../../utils/product/isbn";
import { barcodeValidator } from "@ozibooks/server/dist/helpers/validators/barcode";
import { removeDashes } from "@ozibooks/server/dist/helpers/csv/csvhelpers";
import { RequestStatus2, RequestStatusEnum } from "../../../interfaces/request";
import UIBlocker from "../../uiblocker";
import ErrorMessage from "../../errormessage/errormessage";
import "./form.scss";

type Props = {
    product_id?: string;
    initalText?: string;
    onClose: () => void;
    textLabel: string;
    onCreate: (product_id: string, text: string) => Promise<void>;
    onUpdate: (product_id: string, text: string) => Promise<void>;
};

type State = RequestStatus2 & {
    text: string;
    product_id: string;
    isNew: boolean;
    productError: boolean;
};

export const ProductWithTextForm: FunctionComponent<Props> = ({
    onCreate,
    onUpdate,
    textLabel,
    product_id,
    initalText,
    onClose,
}) => {
    const intl = useIntl();
    const [state, setState] = useState<State>({
        text: initalText || "",
        product_id: product_id || "",
        isNew: !product_id,
        productError: false,
        requestStatus: null,
        errorMsg: null,
    });
    const onSubmit = useCallback(
        (data: State) => {
            const { product_id, isNew, text } = data;
            let real_product_id = "";
            if (!text) {
                return;
            }

            if (isNew) {
                const isbn = parseIsbn(product_id);
                if (isbn) {
                    real_product_id = isbn.isbn10 ? isbn.isbn10 : isbn.isbn13;
                } else {
                    if (barcodeValidator(product_id)) {
                        real_product_id = removeDashes(product_id);
                    } else {
                        setState((old) => ({ ...old, productError: true }));
                        return;
                    }
                }
                setState((old) => ({
                    ...old,
                    requestStatus: RequestStatusEnum.ACTIVE,
                    errorMsg: null,
                }));
                return onCreate(real_product_id, text)
                    .then(onClose)
                    .catch((err) =>
                        setState((old) => ({
                            ...old,
                            errorMsg: err,
                            requestStatus: RequestStatusEnum.FAIL,
                        }))
                    );
            }

            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.ACTIVE,
                errorMsg: null,
            }));
            return onUpdate(product_id, text)
                .then(onClose)
                .catch((err) =>
                    setState((old) => ({
                        ...old,
                        errorMsg: err,
                        requestStatus: RequestStatusEnum.FAIL,
                    }))
                );
        },
        [onCreate, onClose, onUpdate]
    );

    return (
        <div className={"ob-productwithtext__form"}>
            {state.requestStatus === RequestStatusEnum.ACTIVE && <UIBlocker />}
            {state.errorMsg && (
                <ErrorMessage
                    err={state.errorMsg}
                    onClose={() =>
                        setState((old) => ({ ...old, errorMsg: null }))
                    }
                />
            )}
            <div className="form-group">
                {state.isNew && (
                    <div className="form-group">
                        <label>ISBN/EAN</label>
                        <input
                            className={"form-control"}
                            onChange={(e) => {
                                const val = e.target.value;
                                setState((old) => ({
                                    ...old,
                                    product_id: val,
                                    productError: false,
                                }));
                            }}
                        />
                        {state.productError && (
                            <div className="invalid-feedback">
                                Invalid ISBN/EAN
                            </div>
                        )}
                    </div>
                )}
                <label>{textLabel}</label>
                <textarea
                    className={"form-control"}
                    onChange={(e) => {
                        const val = e.target.value;
                        setState((old) => ({ ...old, text: val }));
                    }}
                    value={state.text}
                    rows={3}
                />
            </div>
            <div className={"ob-formbuttonbar"}>
                <button
                    className={"btn btn-success"}
                    onClick={() => onSubmit(state)}
                    disabled={
                        !state.product_id ||
                        initalText === state.text ||
                        !state.text
                    }
                >
                    {intl.formatMessage({ id: "save" })}
                </button>
                <button onClick={onClose} className={"btn btn-secondary"}>
                    {intl.formatMessage({ id: "cancel" })}
                </button>
            </div>
        </div>
    );
};
