import {Column, DataGridProps, SortDirection} from "react-data-grid";
import React, {useCallback, useMemo, useState} from "react";

const toNumber = (data: unknown): number => {
    if(typeof data === "number") {
        return data;
    }

    if(typeof data === "string") {
        const tmp = parseInt(data);
        if(!isNaN(tmp)) {
            return tmp;
        }
    }

    return 0;
};

export enum ColumnSortType {
    NUMBER = "NUMBER",
    STRING = "STRING",
}

export interface SortableColumn<TRow, TSummaryRow = unknown> extends Column<TRow, TSummaryRow> {
    sortType?: ColumnSortType | ((a: TRow, b: TRow) => number),
}

export interface UseReactDataGridOptions<TRow, TSummaryRow> {
    columns: SortableColumn<TRow, TSummaryRow>[],
    rows: TRow[],
    defaultSort: string;
    defaultSortDirection: SortDirection,
    rowKey: keyof TRow,
}

const useReactDataGrid = <TRow, TSummaryRow = unknown>(options: UseReactDataGridOptions<TRow, TSummaryRow>) => {
    const [selectedRows, onSelectedRowsChange] = useState(() => new Set<React.Key>());
    const [state, setState] = useState({
        gridRows: options.rows,
        columns: options.columns,
        sortColumn: options.defaultSort,
        sortDirection: options.defaultSortDirection,
        rowKey: options.rowKey,
    });
    const onSort = useCallback((sortColumn: string, sortDirection: SortDirection) => {
        setState(old => ({...old,sortColumn, sortDirection}))
    }, [setState]);
    const setRows = useCallback((rows: TRow[]) => {
        setState(old => ({...old, gridRows: rows}));
    }, [setState]);
    const setColumns = useCallback((columns: SortableColumn<TRow, TSummaryRow>[]) => {
        setState(old => ({...old, columns}));
    }, [setState]);

    const {sortColumn, sortDirection, gridRows, columns, rowKey} = state;

    const rows = useMemo<TRow[]>(() => {
        if(sortDirection === "NONE") {
            return gridRows;
        }

        const selectedColumn: SortableColumn<TRow, TSummaryRow> = columns.filter(e => e.key === sortColumn)[0];
        if(!selectedColumn) {
            return gridRows;
        }

        let sortedRows: TRow[] = [...gridRows];
        sortedRows = sortedRows.sort((a, b) => {
            if(typeof selectedColumn.sortType === "function") {
                return sortDirection === "ASC" ? selectedColumn.sortType(a, b) : selectedColumn.sortType(b, a);
            }
            if(selectedColumn.sortType === ColumnSortType.NUMBER) {
                return sortDirection === "ASC" ?
                    toNumber(a[sortColumn as keyof TRow]) - toNumber(b[sortColumn as keyof TRow]):
                    toNumber(b[sortColumn as keyof TRow]) - toNumber(a[sortColumn as keyof TRow]);
            }

            return sortDirection === "ASC" ?
                String(a[sortColumn as keyof TRow]).localeCompare(String(b[sortColumn as keyof TRow])):
                String(b[sortColumn as keyof TRow]).localeCompare(String(a[sortColumn as keyof TRow]));
        });

        return sortedRows;
    }, [sortColumn, sortDirection, gridRows, columns]);
    const rowKeyGetter = useCallback((row: TRow) => row[rowKey] as any, [rowKey]);


    return {
        setRows,
        setColumns,
        gridRows,
        props: {
            selectedRows,
            onSelectedRowsChange,
            onSort,
            sortColumn,
            sortDirection,
            columns,
            rows,
            rowKeyGetter,
        } as DataGridProps<TRow>
    };
};

export default useReactDataGrid;
