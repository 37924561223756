import {LangMessagesType} from "../../interfaces/lang";
import {addLangMessages} from "../../lang/messages";

const messages: LangMessagesType = {
    "sk": {
        "isbnprefix.addstar": "Pridať hviezdičku",
        "isbnprefix.removestar": "Zmazať hviezdičku",
        "isbnprefix.reloadfromweb": "Načítať data zo stránky",
        "isbnprefix.ignore": "Ignorovať",
        "isbnprefix.notignore": "Neignorovať",
        "isbnprefix.usedonly": "Len ako použité",
        "isbnprefix.notusedonly": "Nie ako použité",

    },
    "en": {
        "isbnprefix.addstar": "Add star",
        "isbnprefix.removestar": "Remove star",
        "isbnprefix.reloadfromweb": "Update data from page",
        "isbnprefix.ignore": "Ignore",
        "isbnprefix.notignore": "Not ignore",
        "isbnprefix.usedonly": "As used only",
        "isbnprefix.notusedonly": "Not as used only",
    }
};

addLangMessages(messages);
