import React, {FunctionComponent} from 'react';
import {BzOrderableInfoType} from "../../../../utils/helpers/orderable";
import {OrderableField} from "../../../../components/listingview/detail/components/orderable";
import {FormattedMessage} from "react-intl";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover"
import {DateTimeField} from "../../../../components/listingview/detail/components/DateTimeField";


type Props = {
    columnKey: keyof BzOrderableInfoType,
    info: BzOrderableInfoType[],
    starred: boolean,
};


const BzColumnsFormatter: FunctionComponent<Props> = ({columnKey, info, starred}) => {
    if(columnKey === "orderable") {
        return <OrderableField orderable={starred ? info[0].orderable_starred : info[0].orderable}/>
    }

    if(columnKey === "updated_at") {
        return <DateTimeField dateTime={info[0].updated_at}/>;
    }

    switch (info.length) {
        case 0:
            return <FormattedMessage id={"nodata"} />;
        case 1:
            return info[0][columnKey] === null ? <FormattedMessage id={"nodata"} /> : <>{info[0][columnKey]}</>;
        default:
            return (
                <div className="ob-rgb-cellwithpopup">
                    <span>{info[0][columnKey] === null ? <FormattedMessage id={"nodata"} /> : info[0][columnKey]}</span>
                    <OverlayTrigger
                        trigger="hover"
                        placement="auto"
                        overlay={(
                            <Popover id={"ob-rdg__popover"}>
                                <table className="table table-bordered">
                                    <thead>
                                    <tr>
                                        <th><FormattedMessage id={"activation.bzstatustype"} /></th>
                                        <th>BZ <FormattedMessage id={"publishingstatus.status"} /></th>
                                        <th>BZ <FormattedMessage id={"updated_at"} /></th>
                                        <th>BZ <FormattedMessage id={"publishingstatus.orderable"} />?</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    {info.map(e => (
                                        <tr>
                                            <td>{e.publishing_status}</td>
                                            <td>{e.status}</td>
                                            <td><DateTimeField dateTime={e.updated_at}/></td>
                                            <td><OrderableField orderable={e.orderable}/></td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </Popover>
                        )}
                    >
                        <span>⚠</span>
                    </OverlayTrigger>
                </div>
            );
    }
};

export default BzColumnsFormatter;
