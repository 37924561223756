import {LangMessagesType} from "../../interfaces/lang";

export default {
    sk: {
        "skusearch": "Hľadať SKU...",
    },
    en: {
        "skusearch": "Search SKU...",
    }
} as LangMessagesType;
