import {LangMessagesType} from "../../interfaces/lang";

export default {
    sk: {
        "blacklist.addtoblacklist": "Pridať na blacklist",
        "blacklist.reason": "Dôvod",
        "blacklist.edittitle": "Upraviť dôvod pre",
        "blacklist.deleteheader": "Zmazať {id} z blacklistu?",
        "blacklist.confirmdelete": "Ste si istý, že chcete {id} zmazať z blacklistu?",
        "blacklist.blacklisted": "Je na blackliste",
    },
    en: {
        "blacklist.addtoblacklist": "Add to blacklist",
        "blacklist.reason": "Reson",
        "blacklist.edittitle": "Edit reason for",
        "blacklist.deleteheader": "Remove {id} from blacklist?",
        "blacklist.confirmdelete": "Are you sure you want to delete {id} from blacklist?",
        "blacklist.blacklisted": "Blacklisted",
    }
} as LangMessagesType;
