import React, { FunctionComponent, memo } from "react";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import { FormattedMessage } from "react-intl";
import { DateTimeField } from "../../listingview/detail/components/DateTimeField";
import "./isbnprefixheaderdate.scss";

type Props = {
    item: IsbnPrefixEntity;
};

const IsbnPrefixHeaderDate: FunctionComponent<Props> = memo(({ item }) => {
    const clx = "ob-isbnprefixheader__date";
    if (!item.scraped_at) {
        return (
            <div className={clx}>
                <FormattedMessage id={"updatedat"} />:{" "}
                <FormattedMessage id={"never"} />
            </div>
        );
    }

    return (
        <div className={clx}>
            <FormattedMessage id={"updatedat"} />:{" "}
            <DateTimeField dateTime={item.scraped_at} />
        </div>
    );
});

export default IsbnPrefixHeaderDate;
