import React, {FunctionComponent} from 'react';
import {FormattedDate} from "react-intl";

type Props = {
    dateTime: string | number | null | Date | undefined,
};

export const DateTimeField: FunctionComponent<Props> = ({dateTime}) => {
    if(dateTime === null || dateTime === undefined || dateTime === "") {
        return <></>;
    }

    return <FormattedDate dateStyle={"short"} timeStyle={"short"} value={dateTime} />;
};
