import React, {ChangeEvent, FunctionComponent, memo, useCallback, useState} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSearch} from "@fortawesome/free-solid-svg-icons/faSearch";
import "./searchbox.scss";

type Props = {
    initialText: string,
    onSubmit: (txt: string) => void
};

const SearchBox: FunctionComponent<Props> = memo(({initialText, onSubmit}) => {
    const [text, setText] = useState(initialText);
    const onChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        setText(ev.target.value);
    }, []);
    return (
        <form
            className={"ob-searchbox"}
            onSubmit={e => {
                e.preventDefault();
                onSubmit(text.trim());
            }}
        >
            <div className="input-group">
                <input className={"form-control"} type={"search"} value={text} onChange={onChange}/>
                <div className="input-group-append">
                    <button
                        className={"btn btn-secondary"}
                        type={"submit"}
                        disabled={text === initialText}
                    ><FontAwesomeIcon icon={faSearch} /></button>
                </div>
            </div>
        </form>
    );
});

export default SearchBox;
