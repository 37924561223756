import {LangMessagesType} from "../../interfaces/lang";

export default {
    sk: {
        "isbnprefix.ignored": "Ignorovaný",
        "isbnprefix.usedonly": "Len ako použité",
        "isbnprefix.publishersforprefix": "Vydavateľia pre prefix",
        "isbnprefix.nocategory": "Bez kategórie",
        "isbnprefix.publishersaved": "Vydavateľ bol úspešne aktualizovaný!",
        "isbnprefix.anycategory": "Má kategóriu",
        "isbnprefix.starred": "Hviezdičkový prefix",

    },
    en: {
        "isbnprefix.ignored": "Ignored",
        "isbnprefix.usedonly": "As used only",
        "isbnprefix.publishersforprefix": "Publishers for prefix",
        "isbnprefix.nocategory": "No category",
        "isbnprefix.publishersaved": "Publisher was successfully updated!",
        "isbnprefix.anycategory": "Any category",
        "isbnprefix.starred": "Starred prefix",

    }
} as LangMessagesType;
