import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { ZiegelEntity } from "@ozibooks/server/dist/modules/ziegel/entities/ziegel.entity";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";

type Props = {
    item: ZiegelEntity;
};

const ListingViewZiegel: FunctionComponent<Props> = ({ item }) => {
    const rows: ReactNode[][] = useMemo(() => {
        return [
            ["ziegelid", item.ziegelid],
            ["title", item.title],
            ["product_id", item.product_id],
            ["product_id_type", item.product_id_type],
            ["wanted", item.wanted],
            ["price", item.price],
            ["quantity", item.quantity],
            ["standort", item.standort],
            ["created_at", <DateTimeField dateTime={item.created_at} />],
            ["updated_at", <DateTimeField dateTime={item.updated_at} />],
        ];
    }, [item]);
    return (
        <div className={"ob-listingviewziegel"}>
            <InfoTable items={rows} />
        </div>
    );
};

export default ListingViewZiegel;
