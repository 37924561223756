import React, {FunctionComponent} from 'react';
import {PageHeadingActionType} from "../page/page";
import Dropdown from "react-bootstrap/Dropdown";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons/faEllipsisV";
import "./actionmenu.scss";

export type ActionMenuActionType = {
    label: string,
    action: () => void,
    disabled?: boolean,
};

type Props = {
    actions: PageHeadingActionType[],
};

const ActionMenu: FunctionComponent<Props> = ({actions}) => {
    return (
        <Dropdown className={"ob-actionmenu"}>
            <Dropdown.Toggle variant={"secondary"} as={"span"}>
                <FontAwesomeIcon icon={faEllipsisV}/>
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {actions.map(e => (
                    <Dropdown.Item
                        disabled={e.disabled}
                        onClick={e.action}
                        eventKey={e.label}
                        key={e.label}
                    >{e.label}</Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown>
    );
};

export default ActionMenu;
