import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { FormattedMessage, useIntl } from "react-intl";
import { OrderableField } from "./orderable";
import {
    avaWebToOrderableInfo,
    balmerWebToOrderableInfo,
    bzToOrderableInfo,
    olfToOrderableInfo,
} from "../../../../utils/helpers/orderable";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";
import IconFullStar from "../../../icons/fullstar";
import IconStar from "../../../icons/star";
import YesNoIcon from "../../../yesno/yesno";
import "./status.scss";
import { RequestStatusEnum } from "../../../../interfaces/request";
import IconUpdate from "../../../icons/update";

type StatusTableDataType = {
    title: string;
    status: string | null;
    updated_at: string | Date | null;
    orderable: boolean | null;
    orderable_starred: boolean | null;
};

type ListingViewStatusProps = {
    entity: Amazonshop;
    avaWebRequest: RequestStatusEnum | null;
    balmerWebRequest: RequestStatusEnum | null;
    updateAvaWeb: () => void;
    updateBalmerWeb: () => void;
};

export const ListingViewStatus: FunctionComponent<ListingViewStatusProps> = ({
    entity,
    avaWebRequest,
    updateAvaWeb,
    updateBalmerWeb,
    balmerWebRequest,
}) => {
    const intl = useIntl();
    const data = useMemo(() => {
        const noData = intl.formatMessage({ id: "listingview.nodata" });
        const notOnWeb = intl.formatMessage({ id: "listingview.notonweb" });
        const notChecked = intl.formatMessage({ id: "listingview.notchecked" });
        const tmp: StatusTableDataType[] = [];

        bzToOrderableInfo(entity).forEach((e) => {
            const title = "BZ";
            if (e.nodata) {
                tmp.push({
                    ...e,
                    title,
                    status: noData,
                });
            } else {
                const status = e.publishing_status
                    ? `${e.status} (${e.publishing_status})`
                    : e.status;
                tmp.push({
                    ...e,
                    title,
                    status,
                });
            }
        });

        const olfInfo = olfToOrderableInfo(entity);
        if (olfInfo.nodata) {
            tmp.push({
                ...olfInfo,
                title: "Olf",
                status: noData,
            });
        } else {
            tmp.push({
                ...olfInfo,
                title: "Olf",
            });
        }

        avaWebToOrderableInfo(entity).forEach((e) => {
            const title = "Ava Web";
            if (e.notOnWeb) {
                return tmp.push({
                    ...e,
                    title,
                    status: notOnWeb,
                });
            }

            if (e.notChecked) {
                return tmp.push({
                    ...e,
                    title,
                    status: notChecked,
                });
            }

            tmp.push({
                ...e,
                title,
            });
        });

        balmerWebToOrderableInfo(entity).forEach((e) => {
            const title = "Balmer Web";
            if (e.notOnWeb) {
                return tmp.push({
                    ...e,
                    title,
                    status: notOnWeb,
                });
            }

            if (e.notChecked) {
                return tmp.push({
                    ...e,
                    title,
                    status: notChecked,
                });
            }

            tmp.push({
                ...e,
                title,
            });
        });

        return tmp;
    }, [entity, intl]);
    const isStarred = entity.isbnPrefix ? !!entity.isbnPrefix.starred : false;
    const orderable = data.some((e) =>
        isStarred ? e.orderable_starred === true : e.orderable === true
    );
    const infoData: ReactNode[][] = useMemo(() => {
        return [
            [
                "ISBN Prefix",
                isStarred ? <IconFullStar isActive={true} /> : <IconStar />,
            ],
            [
                intl.formatMessage({ id: "publishingstatus.orderable" }) + "?",
                <YesNoIcon yesno={orderable} />,
            ],
        ];
    }, [orderable, isStarred, intl]);

    return (
        <div className={"ob-listingviewdetail-status"}>
            <div className="ob-listingviewdetail-statusinfo">
                <InfoTable items={infoData} />
            </div>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th colSpan={3} />
                        <th colSpan={2}>
                            {intl.formatMessage({
                                id: "publishingstatus.orderable",
                            })}
                            ?
                        </th>
                        <th />
                    </tr>
                    <tr>
                        <th>
                            <FormattedMessage id={"publishingstatus.source"} />
                        </th>
                        <th>
                            <FormattedMessage id={"publishingstatus.status"} />
                        </th>
                        <th>
                            <FormattedMessage id={"updated_at"} />
                        </th>
                        <th>
                            {intl.formatMessage({
                                id: "publishingstatus.normalprefix",
                            })}
                        </th>
                        <th>
                            {intl.formatMessage({
                                id: "publishingstatus.starredprefix",
                            })}
                        </th>
                        <th />
                    </tr>
                </thead>
                <tbody>
                    {data.map((e) => (
                        <tr>
                            <td>{e.title}</td>
                            <td>{e.status}</td>
                            <td>
                                <DateTimeField dateTime={e.updated_at} />
                            </td>
                            <td>
                                <OrderableField orderable={e.orderable} />
                            </td>
                            <td>
                                <OrderableField
                                    orderable={e.orderable_starred}
                                />
                            </td>
                            <td>
                                <UpdateButton
                                    status={e}
                                    updateAvaWeb={updateAvaWeb}
                                    avaWebRequest={avaWebRequest}
                                    updateStr={intl.formatMessage({
                                        id: "isbnprefix.reloadfromweb",
                                    })}
                                    updatingStr={intl.formatMessage({
                                        id: "updating",
                                    })}
                                    balmerWebRequest={balmerWebRequest}
                                    updateBalmerWeb={updateBalmerWeb}
                                />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );
};

type UpdateButtonProps = {
    status: StatusTableDataType;
    avaWebRequest: RequestStatusEnum | null;
    balmerWebRequest: RequestStatusEnum | null;
    updateAvaWeb: () => void;
    updateBalmerWeb: () => void;
    updateStr: string;
    updatingStr: string;
};

const UpdateButton: FunctionComponent<UpdateButtonProps> = ({
    status,
    avaWebRequest,
    updateAvaWeb,
    updatingStr,
    updateStr,
    balmerWebRequest,
    updateBalmerWeb,
}) => {
    if (status.title === "Ava Web") {
        return avaWebRequest === RequestStatusEnum.ACTIVE ? (
            <IconUpdate spin={true} title={updatingStr} />
        ) : (
            <IconUpdate
                onClick={updateAvaWeb}
                style={{ cursor: "pointer" }}
                title={updateStr}
            />
        );
    }

    if (status.title === "Balmer Web") {
        return balmerWebRequest === RequestStatusEnum.ACTIVE ? (
            <IconUpdate spin={true} title={updatingStr} />
        ) : (
            <IconUpdate
                onClick={updateBalmerWeb}
                style={{ cursor: "pointer" }}
                title={updateStr}
            />
        );
    }

    return null;
};
