import React, {FunctionComponent, ReactNode} from 'react';
import "./infotable.scss";

type Props = {
    items: ReactNode[][],
};

const InfoTable: FunctionComponent<Props> = ({items}) => {
    return (
        <table className="table table-borderless ob-infotable">
            <tbody>
            {items.map((row, irow) => (
                <tr key={irow}>{row.map((e, i) => {
                    return i === 0 ? (<th>{e}</th>) : (<td>{e}</td>);
                })}</tr>
            ))}
            </tbody>
        </table>
    );
};

export default InfoTable;
