import React, {FunctionComponent, useMemo, useState} from 'react';
import {PublishingStatusFiltersType, PubloshingStatusItem} from "../types";
import Table from "react-bootstrap/Table";
import PublishingStatusTableHeader from "./header/statustableheader";
import filterPublishingStatusItems from "../helpers/filteritems";
import {YesNoButton} from "../../../components/yesnobutton/yesnobutton";

type ChangeOrderableType = (id: number, orderable: boolean) => void;

type PublishingStatusTableProps = {
    items: PubloshingStatusItem[],
    changeOrderable: ChangeOrderableType,
    changeOrderableStarred: ChangeOrderableType,
};



export const PublishingStatusTable: FunctionComponent<PublishingStatusTableProps> = ({items, changeOrderableStarred, changeOrderable}) => {
    const [filters, setFilters] = useState<PublishingStatusFiltersType>({
        source: "",
        search: "",
        orderable: "",
        orderableStarred: "",
    });
    const filteredItems = useMemo<PubloshingStatusItem[]>(() => {
        return filterPublishingStatusItems(items, filters);
    }, [filters, items]);
        return (
        <div className="ob-publishing-status-table">
            <Table>
                <PublishingStatusTableHeader filters={filters} changeFilters={setFilters}/>
                <tbody>
                {filteredItems.map(e => <PublishingStatusRow
                    key={e.id}
                    item={e}
                    changeOrderable={changeOrderable}
                    changeOrderableStarred={changeOrderableStarred}
                />)}
                </tbody>
            </Table>
        </div>
    );
};

type PublishingStatusRowProps = {
    item: PubloshingStatusItem,
    changeOrderable: ChangeOrderableType,
    changeOrderableStarred: ChangeOrderableType,
};


const PublishingStatusRow: FunctionComponent<PublishingStatusRowProps> = (
    {item, changeOrderable, changeOrderableStarred}
) => {
    return (
        <tr className={item.errorMsg ? "table-danger" : ""}>
            <td>{item.source}</td>
            <td>{item.publishing_status}</td>
            <td>
                <YesNoButton
                    value={item.orderable === null ? null: !!item.orderable}
                    changeValue={val => changeOrderable(item.id, val)}
                />
            </td>

            <td>
                <YesNoButton
                    value={item.orderable_starred === null ? null: !!item.orderable_starred}
                    changeValue={val => changeOrderableStarred(item.id, val)}
                />
            </td>
        </tr>
    );
};
