import {LangMessagesType} from "../../interfaces/lang";
import Isbn from "./detail/components/isbn.lang.json";

export default {
    sk: {
        "listingview.nodata": "Žiadne data",
        "listingview.notonweb": "Nie je na webe",
        "listingview.notchecked": "Neskontrolované",
        "listingview.noisbn": "Ponuka nemá ISBN",
        "listingview.invalidisbn": "Ponuka má neplatné ISBN",
        "listingview.listingprice": "Cena na ponuke",
        "listingview.waitingupload": "Ponuka čaká na upload so skladom {quantity}",
        "listingview.addtoupload": "Pridať do uploadu",
        "listingview.modifyupload": "Upraviť upload",
        ...(Isbn as LangMessagesType).sk,

    },
    en: {
        "listingview.nodata": "No data",
        "listingview.notonweb": "Not on web",
        "listingview.notchecked": "Not checked yet",
        "listingview.noisbn": "Listing has no ISBN",
        "listingview.invalidisbn": "Listing has invalid ISBN",
        "listingview.listingprice": "Listing price",
        "listingview.waitingupload": "Listing has queued upload with quantity {quantity}",
        "listingview.addtoupload": "Add to upload",
        "listingview.modifyupload": "Modify upload",
        ...(Isbn as LangMessagesType).en,
    }
} as LangMessagesType;
