import React, {
    FunctionComponent,
    MouseEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import DataGrid, { SelectColumn } from "react-data-grid";
import { FormattedMessage } from "react-intl";
import { RequestStatus2, RequestStatusEnum } from "../../../interfaces/request";
import { AmazonshopQuantityDto } from "@ozibooks/server/dist/modules/amazon/dto/amazonshopquantity.dto";
import useReactDataGrid, {
    ColumnSortType,
    SortableColumn,
} from "../../../utils/hooks/reactdatagrid";
import { HeaderRendererProps } from "react-data-grid/lib/types";
import Dropdown from "react-bootstrap/Dropdown";
import UIBlocker from "../../../components/uiblocker";
import LoadIndicator from "../../../components/loadindicator";
import ErrorMessage from "../../../components/errormessage/errormessage";
import { UnknownClientException } from "../../../utils/apiclient/exceptions";
import { AppException } from "../../../utils/exceptions";
import "../../../scss/loaders/reactdatagrid";
import "./index.scss";
import TextSlectCell from "../../../components/react-data-grid/components/textselectcell";
import { Modal } from "react-bootstrap";
import ListingView from "../../../components/listingview/listingview";

type Overwrite<T, U> = Pick<T, Exclude<keyof T, keyof U>> & U;
export type AmazonshopQuantityItem = Overwrite<
    AmazonshopQuantityDto,
    { new_quantity: number; total_quantity: number }
>;

export type QuantityTableProps = {
    loadItems: () => Promise<AmazonshopQuantityItem[]>;
    addToUpload: (items: AmazonshopQuantityItem[]) => Promise<void>;
};

const HeaderRenderer: FunctionComponent<
    HeaderRendererProps<AmazonshopQuantityItem, SummaryRow>
> = ({ column }) => <FormattedMessage id={column.name as string} />;

type QuantityTableState = RequestStatus2 & {
    items: AmazonshopQuantityItem[];
    isCreatingUpload: boolean;
    creatingUploadError: AppException | null;
};

type SummaryRow = {
    totalRows: number;
};

const defaultColumns: SortableColumn<AmazonshopQuantityItem, SummaryRow>[] = [
    SelectColumn,
    {
        key: "az_sku",
        name: "SKU",
        width: 210,
        frozen: true,
        sortType: ColumnSortType.STRING,
        sortable: true,
        resizable: true,
        summaryFormatter: () => <strong>Total:</strong>,
        formatter: ({ row, isCellSelected }) => (
            <div className="ob-rgb-cellwithpopup">
                <TextSlectCell
                    text={row.az_sku}
                    isCellSelected={isCellSelected}
                />
                <span role="img" data-sku={row.az_sku}>
                    🔎
                </span>
            </div>
        ),
    },
    {
        key: "az_real_product_id",
        name: "ISBN/EAN",
        width: 110,
        sortType: ColumnSortType.STRING,
        sortable: true,
        resizable: true,
        summaryFormatter: ({ row }) => <>{row.totalRows}</>,
    },
    {
        key: "az_asin1",
        name: "ASIN",
        width: 110,
        sortType: ColumnSortType.STRING,
        sortable: true,
        resizable: true,
    },
    {
        key: "az_status",
        name: "quantitytable.status",
        sortType: ColumnSortType.STRING,
        sortable: true,
        resizable: true,
        headerRenderer: HeaderRenderer,
    },
    {
        key: "az_quantity",
        name: "quantitytable.current",
        width: 150,
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
        headerRenderer: HeaderRenderer,
    },
    {
        key: "new_quantity",
        name: "quantitytable.newquantity",
        width: 120,
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
        headerRenderer: HeaderRenderer,
    },
    {
        key: "total_quantity",
        name: "quantitytable.totalquantity",
        width: 150,
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
        headerRenderer: HeaderRenderer,
    },
    {
        key: "av_quantity",
        name: "Ava",
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
    },
    {
        key: "br_quantity",
        name: "Balmer",
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
    },
    {
        key: "bzi_quantity",
        name: "BZ",
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
    },
    {
        key: "o_quantity",
        name: "Olf",
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
    },
    {
        key: "zl_quantity",
        name: "Ziegel",
        sortType: ColumnSortType.NUMBER,
        sortable: true,
        resizable: true,
    },
];

const QuantityTable: FunctionComponent<QuantityTableProps> = ({
    loadItems,
    addToUpload,
}) => {
    const { setRows, props } = useReactDataGrid<
        AmazonshopQuantityItem,
        SummaryRow
    >({
        columns: defaultColumns,
        rows: [],
        rowKey: "az_sku",
        defaultSortDirection: "DESC",
        defaultSort: "total_quantity",
    });
    const [skuToShow, setSkuToShow] = useState<string>("");
    const [state, setState] = useState<QuantityTableState>({
        items: [],
        requestStatus: null,
        errorMsg: null,
        isCreatingUpload: false,
        creatingUploadError: null,
    });
    const { requestStatus, items } = state;
    const loadItemsToState = useCallback(() => {
        setState((old) => ({
            ...old,
            errorMsg: null,
            requestStatus: RequestStatusEnum.ACTIVE,
            items: [],
        }));

        loadItems()
            .then((items) => {
                setState((old) => ({
                    ...old,
                    requestStatus: RequestStatusEnum.SUCCESS,
                    items,
                }));
                setRows(items);
            })
            .catch((err) =>
                setState((old) => ({
                    ...old,
                    requestStatus: RequestStatusEnum.FAIL,
                    errorMsg: err,
                }))
            );
    }, [setState, loadItems, setRows]);
    useEffect(() => {
        if (requestStatus !== null) {
            return;
        }

        loadItemsToState();
    }, [requestStatus, loadItemsToState]);
    const { selectedRows, onSelectedRowsChange, rows } = props;
    const createUpload = useCallback(async () => {
        if (!selectedRows) {
            return;
        }

        const tmp = items.filter((e) => selectedRows.has(e.az_sku));
        if (!tmp.length) {
            return;
        }

        setState((old) => ({
            ...old,
            isCreatingUpload: true,
            creatingUploadError: null,
        }));

        try {
            await addToUpload(tmp);
        } catch (e: any) {
            setState((old) => ({
                ...old,
                isCreatingUpload: false,
                creatingUploadError: e,
            }));

            return;
        }

        const newItems = items.filter((e) => !selectedRows.has(e.az_sku));
        setRows(newItems);
        setState((old) => ({
            ...old,
            items: newItems,
            isCreatingUpload: false,
            creatingUploadError: null,
        }));
        onSelectedRowsChange && onSelectedRowsChange(new Set<React.Key>());
    }, [
        items,
        selectedRows,
        addToUpload,
        setRows,
        setState,
        onSelectedRowsChange,
    ]);
    const clearError = useCallback(
        () => setState((old) => ({ ...old, creatingUploadError: null })),
        [setState]
    );
    const summary: SummaryRow[] = useMemo(() => {
        return [
            {
                totalRows: rows.length,
            },
        ];
    }, [rows]);

    if (requestStatus === RequestStatusEnum.ACTIVE || requestStatus === null) {
        return <LoadIndicator />;
    }

    if (requestStatus === RequestStatusEnum.FAIL && state.errorMsg) {
        return (
            <ErrorMessage
                err={
                    state.errorMsg instanceof AppException
                        ? state.errorMsg
                        : new UnknownClientException("")
                }
                onRetry={loadItemsToState}
            />
        );
    }

    const showModal = (ev: MouseEvent<HTMLSpanElement>) => {
        const toShow = (ev as any)?.target?.dataset["sku"];
        if (toShow) {
            setSkuToShow(toShow);
        }
    };

    return (
        <div className="ob-rdg__wrapper" onClick={showModal}>
            {skuToShow && (
                <Modal
                    show={!!skuToShow}
                    animation={true}
                    size="xl"
                    onHide={() => setSkuToShow("")}
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <ListingView sku={skuToShow} />
                    </Modal.Body>
                </Modal>
            )}
            {state.isCreatingUpload && <UIBlocker />}
            {state.creatingUploadError && (
                <ErrorMessage
                    err={state.creatingUploadError}
                    onClose={clearError}
                    onRetry={createUpload}
                />
            )}
            <div className={"ob-rdg__buttonbar"}>
                <Dropdown>
                    <Dropdown.Toggle variant={"primary"}>
                        <FormattedMessage id={"actions"} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            eventKey={"1"}
                            onClick={loadItemsToState}
                        >
                            <FormattedMessage id={"reload"} />
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"2"}
                            onClick={createUpload}
                            disabled={!selectedRows?.size}
                        >
                            <FormattedMessage
                                id={"quantitytable.addtoupload"}
                            />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div
                className="ob-rgd"
                onDoubleClick={(ev) => console.log(ev.target)}
            >
                <DataGrid {...props} summaryRows={summary} />
            </div>
        </div>
    );
};

export default QuantityTable;
