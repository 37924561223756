import React, {FunctionComponent} from 'react';
import SingleIsbnForm from "./components/singleisbn";
import {useIntl} from "react-intl";

import "./isbntools.page.lang"

type Props = {};

const IsbnToolsPage: FunctionComponent<Props> = (props) => {
    const intl = useIntl();
    return (
        <div className={"page"}>
            <h1>{intl.formatMessage({id: "isbntools"})}</h1>
            <SingleIsbnForm intl={intl}/>
        </div>
    );
};

export default IsbnToolsPage;
