import {IStorage} from "../../interfaces/storage";
import {AuthType} from "../../interfaces/auth";

const AUTH_KEY = "AUTH";
const LANG_KEY = "LANG";

export class AppStorage {

    constructor(private readonly storage: IStorage) {}

    async getAuth(): Promise<AuthType | null> {
        const ret = await this.storage.getItem(AUTH_KEY);
        return ret === null ? null : JSON.parse(ret) as AuthType;
    }

    async setAuth(data: AuthType): Promise<void> {
        return await this.storage.setItem(AUTH_KEY, JSON.stringify(data));
    }

    async getLang(): Promise<string | null> {
        return await this.storage.getItem(LANG_KEY);
    }

    async setLang(lang: string): Promise<void> {
        return await this.storage.setItem(LANG_KEY, lang);
    }
}
