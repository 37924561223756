import React, {FunctionComponent} from 'react';
import "./errormessage.scss";
import {
    BadRequestException,
    ForbiddenException,
    GatewayTimeoutException,
    HttpException,
    NetworkException,
    NotFoundException,
    ServerErrorException,
    TimeOutException,
    UnauthorizedException,
} from "../../utils/apiclient/exceptions";
import Alert from "react-bootstrap/Alert";
import { FormattedMessage } from "react-intl";
import { AppException } from "../../utils/exceptions";
import Button from "react-bootstrap/Button";

export type ErrorMessageProps = {
    onClose?: () => void;
    err: AppException;
    onRetry?: () => void;
};

const ErrorMessage: FunctionComponent<ErrorMessageProps> = (props) => {
    if (props.err instanceof NetworkException) {
        return <Network {...props} />;
    }

    if (props.err instanceof TimeOutException) {
        const { err, ...rest } = props;
        return <OneLine {...rest} text={"errors.timeout.title"} />;
    }

    if (props.err instanceof UnauthorizedException) {
        return <OneLine onClose={props.onClose} text={"errors.unauthorized"} />;
    }

    if (props.err instanceof ForbiddenException) {
        return <OneLine onClose={props.onClose} text={"errors.forbidden"} />;
    }

    if (props.err instanceof NotFoundException) {
        return <OneLine onClose={props.onClose} text={"errors.notfound"} />;
    }

    if (props.err instanceof ServerErrorException) {
        const { err, ...rest } = props;
        return <OneLine {...rest} text={"errors.servererror"} />;
    }

    if (props.err instanceof BadRequestException) {
        return (
            <OneLine
                onClose={props.onClose}
                showOriginal={true}
                text={"400: Bad Request"}
            />
        );
    }

    if (props.err instanceof GatewayTimeoutException) {
        const { err, ...rest } = props;
        return <OneLine {...rest} text="errors.gatewaytimeout" />;
    }

    if (props.err instanceof HttpException) {
        let text: string[] = [];
        if (props.err.statusCode) {
            text.push(String(props.err.statusCode));
        }

        if (props.err.message) {
            text.push(props.err.message);
        }

        if (!text.length) {
            text.push("HTTP Error");
        }

        return (
            <OneLine
                onClose={props.onClose}
                showOriginal={true}
                text={text.join(": ")}
            />
        );
    }

    const { title, debugInfo, body, errorMsg } = parseError(props.err);
    const showBody = !!(body.length || debugInfo || errorMsg);
    return (
        <Alert
            variant={"danger"}
            onClose={props.onClose}
            dismissible={!!props.onClose}
        >
            {title && (
                <Alert.Heading>
                    <FormattedMessage id={title} />
                </Alert.Heading>
            )}
            {showBody && (
                <p>
                    {body.length
                        ? body.map((e) => (
                              <>
                                  {e}
                                  <br />
                              </>
                          ))
                        : null}
                    {errorMsg ? errorMsg : null}
                    {debugInfo && (
                        <p>
                            <strong>
                                <FormattedMessage id={"errors.debuginfo"} />:
                            </strong>
                            <pre>{debugInfo}</pre>
                        </p>
                    )}
                </p>
            )}
        </Alert>
    );
};

const Network:FunctionComponent<ErrorMessageProps> = (props) => {
    return (
        <Alert
            variant={"danger"}
            dismissible={!!props.onClose}
            onClose={props.onClose}
        >
            <Alert.Heading>
                <FormattedMessage id={"errors.networkerror.title"} />
            </Alert.Heading>
            <p>
                <FormattedMessage id={"errors.networkerror.desc"} />
            </p>
            <ul>
                <li><FormattedMessage id={"errors.networkerror.desc1"} /></li>
                <li><FormattedMessage id={"errors.networkerror.desc2"} /></li>
                <li><FormattedMessage id={"errors.networkerror.desc3"} /></li>
            </ul>
            {props.onRetry && props.err.recoverable ? (
                <>
                    <hr />
                    <div className="ob-errormessage__info">
                        <Button
                            variant={"outline-danger"}
                            onClick={props.onRetry}
                        >
                            <FormattedMessage id={"errors.retry"} />
                        </Button>
                    </div>
                </>
            ) : null}
        </Alert>
    );
};

type OneLineProps = Omit<ErrorMessageProps, "err"> & {text: string, showOriginal?: boolean};

const OneLine:FunctionComponent<OneLineProps> = (props) => {
    return (
        <Alert
            variant={"danger"}
            dismissible={!!props.onClose}
            onClose={props.onClose}
        >
            <div className={"ob-errormessage__oneline"}>
                <strong>{props.showOriginal ? props.text : <FormattedMessage id={props.text}/>}</strong>
                {props.onRetry ? (
                    <Button
                        variant={"outline-danger"}
                        onClick={props.onRetry}
                    >
                        <FormattedMessage id={"errors.retry"} />
                    </Button>
                ) : null}
            </div>
        </Alert>
    );
};

type ParsedErrorType = {
    title: string,
    body: string[],
    debugInfo: string;
    errorMsg: string;
}

const parseError = (err: Error): ParsedErrorType => {
    let title: string = "";
    let body: string[] = [];
    let debugInfo: string = "";
    let errorMsg: string  = "";

    if(err instanceof AppException) {
        title = "errors.unknowclienterror.title";
        if(err.message) {
            errorMsg = err.message;
        }

        if(err.debugData) {
            debugInfo = err.debugData;
        }
    } else {
        title = "errors.unknowclienterror.title";
        if(err.message) {
            errorMsg = err.message;
        }

        if(err.stack) {
            debugInfo = err.stack;
        }
    }


    if(!body.length && title && !debugInfo && !errorMsg) {
        body.push(title);
        title = "";
    }

    return {title, body,debugInfo, errorMsg};
};

export default ErrorMessage;
