import React, { FunctionComponent, memo } from "react";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import IconFullStar from "../../icons/fullstar";
import IconStar from "../../icons/star";
import IconBan from "../../icons/ban";
import { IconRecycle } from "../../icons/recycle";
import "./isbnprefixheadericons.scss";
import { useIntl } from "react-intl";

type Props = {
    item: IsbnPrefixEntity;
};

const IsbnPrefixHeaderIcons: FunctionComponent<Props> = memo(({ item }) => {
    const intl = useIntl();
    return (
        <div className="ob-isbnprefixheader__icons">
            {item.starred ? (
                <IconFullStar
                    className={"ob-isbnprefixheader__icons--active"}
                    title={
                        intl.formatMessage({ id: "isbnprefix.starred" }) + "?"
                    }
                />
            ) : (
                <IconStar
                    title={
                        intl.formatMessage({ id: "isbnprefix.starred" }) + "?"
                    }
                />
            )}
            <IconRecycle
                className={
                    item.used_only ? "ob-isbnprefixheader__icons--active" : ""
                }
                title={intl.formatMessage({ id: "isbnprefix.usedonly" }) + "?"}
            />
            <IconBan
                className={
                    item.ignored ? "ob-isbnprefixheader__icons--active" : ""
                }
                title={intl.formatMessage({ id: "isbnprefix.ignored" }) + "?"}
            />
        </div>
    );
});

export default IsbnPrefixHeaderIcons;
