import React, {Component} from 'react';
import {AppException} from "../../utils/exceptions";
import ErrorMessage from "../errormessage/errormessage";
import Alert from "react-bootstrap/Alert";

type AlertItem = {
    id: number;
    text: string;
};

type ErrorItem = {
    id: number,
    err: AppException,
    onRetry?: () => void,
};

type State = {
    alerts: AlertItem[],
    errors: ErrorItem[];
};

class Alertbox extends Component<any, State>{
    protected count: number;
    constructor(props: any) {
        super(props);

        this.state = {
            alerts: [],
            errors: [],
        };
        this.count = 0;
    }

    addInfo = (text: string) => {
        this.setState(old => ({alerts: [...old.alerts, {id: this.count++, text}]}));
    };

    addError = (err: AppException, onRetry?: () => void) => {
        this.setState(old => ({
            errors: [...old.errors, {id: this.count++, err, onRetry}],
        }));
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <>
                {this.state.errors.map(e => (
                    <ErrorMessage
                        key={e.id}
                        err={e.err}
                        onClose={() => this.setState(old => ({errors: old.errors.filter(o => o.id !== e.id)}))}
                        onRetry={e.onRetry}
                    />
                ))}
                {this.state.alerts.map(e => (
                    <Alert
                        key={e.id}
                        variant={"success"}
                        dismissible
                        onClose={() => this.setState(old => ({alerts: old.alerts.filter(o => o.id !== e.id)}))}
                    >{e.text}</Alert>
                ))}
            </>
        );
    }
}

export default Alertbox;
