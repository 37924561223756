import { useCallback, useContext, useState } from "react";
import { AppContext } from "../../../utils/appcontext";
import { RequestStatus2, RequestStatusEnum } from "../../../interfaces/request";
import { NoteEntity } from "@ozibooks/server/dist/modules/notes/note.entity";

type NotesState = RequestStatus2 & {
    notes: NoteEntity[];
    isCreating: boolean;
    search: string;
    isEditing: null | NoteEntity;
    page: number;
    trashVisible: boolean;
};
export type UseNotesType = {
    state: NotesState;
    loadItems: () => void;
    setSearch: (val: string) => void;
    setCreating: (val: boolean) => void;
    setEditing: (val: null | NoteEntity) => void;
    replaceNote: (val: NoteEntity) => void;
    addNote: (val: NoteEntity) => void;
    deleteNote: (val: NoteEntity) => void;
    setTrashVisible: (val: boolean) => void;
    undeleteNote: (val: NoteEntity) => void;
};

export const useNotes = (): UseNotesType => {
    const context = useContext(AppContext);
    const [state, setState] = useState<NotesState>({
        requestStatus: null,
        notes: [],
        isCreating: false,
        search: "",
        isEditing: null,
        errorMsg: null,
        page: 0,
        trashVisible: false,
    });

    const setPartial = useCallback((data: Partial<NotesState>) => {
        setState((old) => ({
            ...old,
            ...data,
        }));
    }, []);

    if (!context) {
        throw new Error("No context");
    }

    const loadItems = () => {
        setPartial({
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
        });

        context.client.notes
            .getAll(state.page, state.search, state.trashVisible)
            .then((data) => {
                setPartial({
                    requestStatus: RequestStatusEnum.SUCCESS,
                    notes: data,
                });
            })
            .catch((err) => {
                setPartial({
                    requestStatus: RequestStatusEnum.FAIL,
                    errorMsg: err,
                });
            });
    };

    const setSearch = useCallback(
        (val: string) => {
            setPartial({
                search: val,
                requestStatus: null,
                errorMsg: null,
            });
        },
        [setPartial]
    );

    const setCreating = useCallback(
        (val: boolean) => {
            setPartial({ isCreating: val });
        },
        [setPartial]
    );

    const setEditing = useCallback(
        (val: null | NoteEntity) => {
            setPartial({ isEditing: val });
        },
        [setPartial]
    );

    const addNote = useCallback((val: NoteEntity) => {
        setState((old) => ({
            ...old,
            notes: [val, ...old.notes],
        }));
    }, []);

    const replaceNote = useCallback((val: NoteEntity) => {
        setState((old) => ({
            ...old,
            notes: old.notes.map((e) => (e.id === val.id ? val : e)),
        }));
    }, []);

    const deleteNote = useCallback(
        (ent: NoteEntity) => {
            const val = { ...ent };

            setState((old) => ({
                ...old,
                notes: old.notes.filter((e) => e.id !== val.id),
            }));

            context.client.notes.delete(val.id).catch((err) => {
                setState((old) => ({
                    ...old,
                    errorMsg: err,
                    notes: [val, ...old.notes].sort((a, b) => b.id - a.id),
                }));
                console.log(`Error deleteting note ${val.id} ${err?.message}`);
            });
        },
        [context]
    );

    const setTrashVisible = useCallback(
        (val: boolean) => {
            setPartial({
                trashVisible: val,
                requestStatus: null,
                errorMsg: null,
            });
        },
        [setPartial]
    );

    const undeleteNote = useCallback(
        (val: NoteEntity) => {
            setState((old) => ({
                ...old,
                notes: old.notes.filter((e) => e.id !== val.id),
            }));

            context.client.notes.undelete(val.id).catch((err) => {
                setState((old) => ({
                    ...old,
                    errorMsg: err,
                    notes: [val, ...old.notes].sort((a, b) => b.id - a.id),
                }));
                console.log(`Error undeleting id ${val.id} ${err?.message}`);
            });
        },
        [context]
    );

    return {
        state,
        loadItems,
        setSearch,
        setCreating,
        setEditing,
        addNote,
        replaceNote,
        deleteNote,
        setTrashVisible,
        undeleteNote,
    };
};
