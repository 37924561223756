import {LangMessagesType} from "../../../interfaces/lang";


const messages: LangMessagesType = {
    sk: {
        "quantitytable.current": "Amazon aktuálny",
        "quantitytable.status": "Stav",
        "quantitytable.newquantity": "Amazon nový",
        "quantitytable.totalquantity": "Dostupný celkovo",
        "quantitytable.addtoupload": "Pridať do uploadu",
        "quantity.toactivate": "Na aktivovanie",
        "quantity.todeactivate": "Na deaktivovanie",
        "quantity.activate": "Aktivovať",
        "quantity.deactivate": "Deaktivovať",
    },
    en: {
        "quantitytable.current": "Amazon current",
        "quantitytable.status": "Status",
        "quantitytable.newquantity": "Amazon new",
        "quantitytable.totalquantity": "Total available",
        "quantitytable.addtoupload": "Add to upload",
        "quantity.toactivate": "To activate",
        "quantity.todeactivate": "To deactivate",
        "quantity.activate": "Activate",
        "quantity.deactivate": "Deactivate",
    },
};

export default messages;
