import {LangMessagesType} from "../../interfaces/lang";
import tableMessages from "./components/table.lang";

const messages: LangMessagesType = {
    sk: {
        ...tableMessages.sk,
    },
    en: {
        ...tableMessages.en,
    },
};


export default messages;
