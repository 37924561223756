import Modal from "react-bootstrap/Modal";
import React, { FunctionComponent, useContext, useState } from "react";
import { NoteEntity } from "@ozibooks/server/dist/modules/notes/note.entity";
import { RequestStatus2, RequestStatusEnum } from "../../../interfaces/request";
import { AppContext } from "../../../utils/appcontext";
import ErrorMessage from "../../../components/errormessage/errormessage";

type Props = {
    setCreating: (val: boolean) => void;
    setEditing: (val: null | NoteEntity) => void;
    isEditing: null | NoteEntity;
    isCreating: boolean;
    replaceNote: (val: NoteEntity) => void;
    addNote: (val: NoteEntity) => void;
};

type State = RequestStatus2 & {
    title: string;
    text: string;
};

export const NoteForm: FunctionComponent<Props> = ({
    setCreating,
    setEditing,
    isCreating,
    isEditing,
    replaceNote,
    addNote,
}) => {
    const [state, setState] = useState<State>({
        title: isEditing?.title || "",
        text: isEditing?.text || "",
        requestStatus: null,
        errorMsg: null,
    });
    const context = useContext(AppContext);

    if (!context?.client) {
        throw new Error("No context");
    }

    let modalTitle = "Nová poznámka";
    if (isEditing !== null) {
        modalTitle = "Upraviť poznámku";
    }

    let disabled = false;

    if (
        isEditing &&
        state.title === isEditing.title &&
        state.text === isEditing.text
    ) {
        disabled = true;
    }

    if (!state.text) {
        disabled = true;
    }

    if (state.requestStatus === RequestStatusEnum.ACTIVE) {
        disabled = true;
    }

    const onHide = () => {
        if (isCreating) {
            setCreating(false);
        }

        if (isEditing !== null) {
            setEditing(null);
        }
    };

    const onSubmit = (data: State) => {
        const text = data.text.trim();
        const title = data.title.trim();
        if (disabled || !text) {
            return;
        }

        setState((old) => ({
            ...old,
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
        }));
        if (isCreating) {
            context.client.notes
                .create(title, text)
                .then((data) => {
                    setState((old) => ({
                        ...old,
                        requestStatus: RequestStatusEnum.SUCCESS,
                        title: "",
                        text: "",
                    }));
                    setCreating(false);
                    if (data) {
                        addNote(data);
                    }
                })
                .catch((err) => {
                    setState((old) => ({
                        ...old,
                        requestStatus: RequestStatusEnum.FAIL,
                        errorMsg: err,
                    }));
                });
        }

        if (isEditing) {
            context.client.notes
                .update(isEditing.id, title, text)
                .then((data) => {
                    setState((old) => ({
                        ...old,
                        requestStatus: RequestStatusEnum.SUCCESS,
                    }));
                    replaceNote(data);
                    setEditing(null);
                })
                .catch((err) => {
                    setState((old) => ({
                        ...old,
                        requestStatus: RequestStatusEnum.FAIL,
                        errorMsg: err,
                    }));
                });
        }
    };

    return (
        <Modal
            show={isCreating || !!isEditing}
            animation={true}
            onHide={onHide}
            size="xl"
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {state.errorMsg ? <ErrorMessage err={state.errorMsg} /> : null}
                <form
                    className="ob_note__form"
                    onSubmit={(ev) => {
                        ev.preventDefault();
                        onSubmit(state);
                    }}
                >
                    <div className="form-group">
                        <label htmlFor="">Nadpis</label>
                        <input
                            value={state.title}
                            type="text"
                            className="form-control"
                            onChange={(ev) => {
                                const txt = ev.target.value;
                                setState((old) => ({ ...old, title: txt }));
                            }}
                        />
                    </div>
                    <div className="form-group">
                        <label htmlFor="">Poznámka</label>
                        <textarea
                            className="form-control"
                            value={state.text}
                            rows={7}
                            onChange={(ev) => {
                                const txt = ev.target.value;
                                setState((old) => ({ ...old, text: txt }));
                            }}
                        />
                    </div>
                </form>
            </Modal.Body>
            <Modal.Footer>
                <button className="btn btn-secondary" onClick={() => onHide()}>
                    Zrušiť
                </button>
                <button
                    className="btn btn-success"
                    disabled={disabled}
                    onClick={() => onSubmit(state)}
                >
                    Uložiť
                </button>
            </Modal.Footer>
        </Modal>
    );
};
