import React, { Component, ContextType, createRef, RefObject } from "react";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import { IsbnPublisherEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnpublisher.entity";
import { AppContext } from "../../utils/appcontext";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import { NotFoundException } from "../../utils/apiclient/exceptions";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ActionMenuActionType } from "../actionmenu/actionmenu";
import { PageBody } from "../page/page";
import IsbnPrefixHeader from "./header/isbnprefixheader";
import Alertbox from "../alerbox/alertbox";
import "./isbnprefixcontainer.lang";
import IsbnPublisherContainer from "../isbnprefixpublisher/container";

type BaseProps = {
    entity?: IsbnPrefixEntity;
    prefix13: string;
    onPrefixEntityUpdate?: (ent: IsbnPrefixEntity) => void;
};

type Props = BaseProps & WrappedComponentProps<"intl">;

type State = RequestStatus2 & {
    item: IsbnPrefixEntity | null;
    publishers: IsbnPublisherEntity[];
    key: string;
};

class IsbnPrefixContainer extends Component<Props, State> {
    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;
    private locale: string;
    private actions: ActionMenuActionType[] = [];
    protected alertRef: RefObject<Alertbox>;
    protected publisherRef: RefObject<IsbnPublisherContainer>;

    constructor(props: Props) {
        super(props);
        const state: State = {
            requestStatus: RequestStatusEnum.SUCCESS,
            errorMsg: null,
            item: null,
            publishers: [],
            key: this.getKey(),
        };

        this.locale = this.props.intl.locale;
        this.alertRef = createRef();
        this.publisherRef = createRef();

        if (this.props.entity) {
            const item = { ...this.props.entity };
            if (item.publishers) {
                state.publishers = [...item.publishers];
            }
            delete item.publishers;
            state.item = item;
            state.requestStatus = RequestStatusEnum.SUCCESS;
        }

        this.state = state;
    }

    componentDidUpdate(
        prevProps: Readonly<Props>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        if (this.state.item !== prevState.item && this.state.item) {
            this.publisherRef.current?.setChangedPrefix(this.state.item);
            this.props.onPrefixEntityUpdate &&
                this.props.onPrefixEntityUpdate({
                    ...this.state.item,
                    publishers: this.state.publishers,
                });
        }
    }

    private getKey(): string {
        return Math.random().toFixed(6);
    }

    private createActions() {
        const { intl } = this.props;
        const { item } = this.state;
        this.actions = [];
        if (!item) {
            this.actions.push({
                label: intl.formatMessage({ id: "reload" }),
                action: this.loadEntity,
                disabled: false,
            });
            return;
        }

        this.actions = [
            {
                label: intl.formatMessage({
                    id: item.starred
                        ? "isbnprefix.removestar"
                        : "isbnprefix.addstar",
                }),
                action: this.toggleStar,
            },
            {
                label: intl.formatMessage({
                    id: item.used_only
                        ? "isbnprefix.notusedonly"
                        : "isbnprefix.usedonly",
                }),
                action: this.toggleUsedOnly,
            },
            {
                label: intl.formatMessage({
                    id: item.ignored
                        ? "isbnprefix.notignore"
                        : "isbnprefix.ignore",
                }),
                action: this.toggleIgnored,
            },
            {
                label: intl.formatMessage({ id: "isbnprefix.reloadfromweb" }),
                action: this.forceReload,
            },
        ];
    }

    private loadEntity = (force: boolean = false) => {
        const { prefix13, onPrefixEntityUpdate } = this.props;
        this.setState({
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
            key: this.getKey(),
            item: null,
            publishers: [],
        });
        this.context!.client!.isbnPrefix.getPrefix(prefix13, force)
            .then((pr) => {
                this.actions = [];
                if (!pr) {
                    this.setState({
                        requestStatus: RequestStatusEnum.FAIL,
                        errorMsg: new NotFoundException(),
                    });

                    return;
                }

                const item = { ...pr };
                delete item.publishers;
                this.setState({
                    requestStatus: RequestStatusEnum.SUCCESS,
                    item,
                    publishers: pr.publishers ? [...pr.publishers] : [],
                });
                onPrefixEntityUpdate && onPrefixEntityUpdate(pr);
            })
            .catch((errorMsg) => {
                this.setState({
                    requestStatus: RequestStatusEnum.FAIL,
                    errorMsg,
                });
            });
    };
    private forceReload = () => {
        this.loadEntity(true);
    };
    private toggleStar = () => {
        const { item } = this.state;
        const client = this.context?.client;
        if (!item || !client) {
            return;
        }
        const { starred } = item;
        const action = starred
            ? client.isbnPrefix.unstarPrefixes
            : client.isbnPrefix.starPrefixes;
        this.actions = [];
        action([item.prefix13])
            .then(() => {
                this.setState((old) => {
                    if (!old.item) {
                        return null;
                    }

                    return {
                        item: { ...old.item, starred: starred === 0 ? 1 : 0 },
                    };
                });
            })
            .catch((err) => {
                this.alertRef.current?.addError(err);
            });
    };
    private toggleIgnored = () => {
        const { item } = this.state;
        const client = this.context?.client;
        if (!item || !client) {
            return;
        }

        const { ignored } = item;
        const action = ignored
            ? client.isbnPrefix.setPrefixesAsNotIgnored
            : client.isbnPrefix.setPrefixesAsIgnored;
        this.actions = [];
        action([item.prefix13])
            .then(() => {
                this.setState((old) => {
                    if (!old.item) {
                        return null;
                    }

                    return { item: { ...old.item, ignored: ignored ? 0 : 1 } };
                });
            })
            .catch((err) => this.alertRef.current?.addError(err));
    };
    private toggleUsedOnly = () => {
        const { item } = this.state;
        const client = this.context?.client;
        if (!item || !client) {
            return;
        }

        this.actions = [];
        const { used_only } = item;
        const action = used_only
            ? client.isbnPrefix.unsetPrefixesAsUsedOnly
            : client.isbnPrefix.setPrefixesAsUsedOnly;
        action([item.prefix13])
            .then(() => {
                this.setState((old) => {
                    if (!old.item) {
                        return null;
                    }

                    return {
                        item: { ...old.item, used_only: used_only ? 0 : 1 },
                    };
                });
            })
            .catch((err) => this.alertRef.current?.addError(err));
    };

    render():
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        const { intl } = this.props;
        if (intl.locale !== this.locale || !this.actions.length) {
            this.locale = intl.locale;
            this.createActions();
        }

        return (
            <div className="page">
                <Alertbox ref={this.alertRef} />
                <PageBody
                    requestStatus={this.state.requestStatus}
                    errorMsg={this.state.errorMsg}
                >
                    <IsbnPrefixHeader
                        item={this.state.item || undefined}
                        prefix={this.props.prefix13}
                        actions={this.actions}
                    />
                    {!!this.state.publishers.length && (
                        <IsbnPublisherContainer
                            publishers={this.state.publishers}
                            client={this.context!.client}
                            ref={this.publisherRef}
                        />
                    )}
                </PageBody>
            </div>
        );
    }
}

export default injectIntl(IsbnPrefixContainer);
