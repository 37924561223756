import React, {FunctionComponent, useCallback, useContext} from 'react';
import {AppContext} from "../../utils/appcontext";
import {useParams} from "react-router";
import {ActivationTable} from "../quantity/activation/activation";

type Props = {

};

const LostQuantityPage: FunctionComponent<Props> = (props) => {
    const context = useContext(AppContext);
    const {skus} = useParams<{skus: string}>();

    if(!context) {
        throw new Error("Missing context");
    }

    const {client} = context;

    const loadLostQuantityItems = useCallback(() => {
        return client
            .amazonshop
            .getLostQuantityItems(skus.split(",").map(e => e.trim()));
    }, [client, skus]);
    const deactivateItems = useCallback(async (skus: string[]) => {
        await client.amazonshop.deactivateLostQuantity(skus);
    }, [client]);

    return (
        <div className={"page"}>
            <h3>Stratený sklad</h3>
            <ActivationTable
                key={"deactivate"}
                loadItems={loadLostQuantityItems}
                changeActivation={deactivateItems}
                actionButtonLabel={"quantity.deactivate"}
            />
        </div>
    );
};

export default LostQuantityPage;
