import React, { FunctionComponent, memo } from "react";
import { ISBNData } from "isbn3";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { FormattedMessage } from "react-intl";
import "./isbnprefix.scss";
import IsbnPrefixContainer from "../../../isbnprefix/isbnprefixcontainer";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";

type Props = {
    entity: Amazonshop;
    isbnData: ISBNData | null;
    onEntityUpdate?: (ent: IsbnPrefixEntity) => void;
};

const ListingVieDetailIsbn: FunctionComponent<Props> = memo(
    ({ entity, isbnData, onEntityUpdate }) => {
        let prefix = "";
        if (entity.isbnPrefix) {
            prefix = entity.isbnPrefix.prefix13;
        } else {
            if (isbnData && isbnData.isbn13h) {
                prefix = isbnData?.isbn13h.split("-").slice(0, 3).join("-");
            }
        }

        if (!prefix) {
            return (
                <div className={"ob-listingviewdetail-isbn"}>
                    <div className="alert alert-danger" role="alert">
                        <FormattedMessage id={""} />
                    </div>
                </div>
            );
        }

        return (
            <div className={"ob-listingviewdetail-isbn"}>
                <IsbnPrefixContainer
                    prefix13={prefix}
                    entity={entity.isbnPrefix || undefined}
                    onPrefixEntityUpdate={onEntityUpdate}
                />
            </div>
        );
    }
);

export default ListingVieDetailIsbn;
