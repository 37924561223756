import React, {
    ChangeEvent,
    FormEvent,
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
} from "react";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { IntlShape } from "react-intl";
import {
    RequestStatus2,
    RequestStatusEnum,
} from "../../../../interfaces/request";
import "./quantity.scss";
import UIBlocker from "../../../uiblocker";
import ErrorMessage from "../../../errormessage/errormessage";

type DetailViewQuantityProps = {
    entity: Amazonshop;
    intl: IntlShape;
    addUploadQuantity: (sku: string, quantity: number) => Promise<void>;
};

const toNumber = (data: unknown): number | null => {
    if (typeof data == "number" || data === null) {
        return data;
    }

    if (typeof data === "string") {
        const tmp = parseInt(data);
        return isNaN(tmp) ? null : tmp;
    }

    return null;
};

type State = RequestStatus2 & {
    newQuantity: string;
};

const ListingViewDetailQuantity: FunctionComponent<DetailViewQuantityProps> = ({
    addUploadQuantity,
    entity,
    intl,
}) => {
    const data = useMemo(() => {
        const tmp: Array<{ title: string; quantity: number | null }> = [
            {
                title: "Ava",
                quantity: entity.ava ? toNumber(entity.ava.quantity) : null,
            },
            {
                title: "Balmer",
                quantity: entity.balmer
                    ? toNumber(entity.balmer.quantity)
                    : null,
            },
            {
                title: "Olf",
                quantity: entity.olf ? toNumber(entity.olf.quantity) : null,
            },
            {
                title: "Ziegel",
                quantity: entity.ziegel
                    ? toNumber(entity.ziegel.quantity)
                    : null,
            },
        ];

        if (!Array.isArray(entity.bzInvent) || entity.bzInvent.length === 0) {
            tmp.push({ title: "BZ", quantity: null });
        } else {
            entity.bzInvent.forEach((e) => {
                tmp.push({
                    title: "BZ",
                    quantity: toNumber(e.quantity),
                });
            });
        }

        return [
            {
                title: intl.formatMessage({ id: "quantitytable.current" }),
                quantity: toNumber(entity.quantity),
            },
            {
                title: intl.formatMessage({
                    id: "quantitytable.totalquantity",
                }),
                quantity: tmp.reduce(
                    (a, e) => (e.quantity ? a + e.quantity : a),
                    0
                ),
            },
            ...tmp.sort((a, b) => (b.quantity || 0) - (a.quantity || 0)),
        ];
    }, [entity, intl]);
    const nextQuantity = data[1].quantity
        ? data[1].quantity > 20
            ? 20
            : data[1].quantity
        : 0;
    const [state, setState] = useState<State>({
        requestStatus: null,
        errorMsg: null,
        newQuantity: String(nextQuantity),
    });
    const handleChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value;
        if (val !== "") {
            const num = parseInt(val);
            if (isNaN(num)) {
                return;
            }
        }
        setState((old) => ({ ...old, newQuantity: val }));
    }, []);
    const handleReset = useCallback(() => {
        setState((old) => ({ ...old, newQuantity: String(nextQuantity) }));
    }, [nextQuantity]);
    const handleSubmit = (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        if (!state.newQuantity) {
            return;
        }

        const quantity = parseInt(state.newQuantity);
        if (isNaN(quantity) || quantity < 0) {
            return;
        }

        setState((old) => ({
            ...old,
            errorMsg: null,
            requestStatus: RequestStatusEnum.ACTIVE,
        }));
        addUploadQuantity(entity["seller-sku"], quantity)
            .then(() => {
                setState((old) => ({
                    ...old,
                    requestStatus: RequestStatusEnum.SUCCESS,
                }));
            })
            .catch((errorMsg) => {
                setState((old) => ({
                    ...old,
                    errorMsg,
                    requestStatus: RequestStatusEnum.FAIL,
                }));
            });
    };

    const pendingUpload = !!entity.uploadQuantity;
    const submitDisabled =
        state.newQuantity === "" ||
        (pendingUpload &&
            +state.newQuantity === entity.uploadQuantity?.quantity) ||
        entity.quantity === +state.newQuantity;
    const submitStr = pendingUpload
        ? "listingview.modifyupload"
        : "listingview.addtoupload";

    return (
        <div className={"ob-listingviewdetail-quantity"}>
            {state.requestStatus === RequestStatusEnum.ACTIVE && <UIBlocker />}
            {state.errorMsg && (
                <ErrorMessage
                    err={state.errorMsg}
                    onClose={() => {
                        setState((old) => ({ ...old, errorMsg: null }));
                    }}
                />
            )}
            {entity.uploadQuantity && (
                <div className="alert alert-info" role="alert">
                    {intl.formatMessage(
                        { id: "listingview.waitingupload" },
                        { quantity: entity.uploadQuantity.quantity }
                    )}
                </div>
            )}
            <table className="table table-bordered">
                <thead>
                    <tr>
                        {data.map((e) => (
                            <th key={e.title}>{e.title}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {data.map((e) => (
                            <td key={e.title}>{e.quantity}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
            <div className="ob-listingviewdetail__quantityformwrapper">
                <h5>{intl.formatMessage({ id: "upload.quantity" })}</h5>
                <form
                    className={"ob-listingviewdetail__quantityform form-inline"}
                    onSubmit={handleSubmit}
                >
                    <input
                        className="form-control mr-sm-2"
                        value={state.newQuantity}
                        onChange={handleChange}
                    />
                    <button
                        className={"btn btn-success mr-sm-2"}
                        disabled={submitDisabled}
                    >
                        {intl.formatMessage({ id: submitStr })}
                    </button>
                    <button
                        type={"button"}
                        className={"btn btn-secondary"}
                        onClick={handleReset}
                    >
                        Reset
                    </button>
                </form>
            </div>
        </div>
    );
};

export default ListingViewDetailQuantity;
