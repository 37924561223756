import {LangMessagesType} from "../../../interfaces/lang";

export default {
    sk: {
        "activation.bzstatustype": "BZ Typ stavu",
    },
    en: {
        "activation.bzstatustype": "BZ Status type",
    }
} as LangMessagesType;
