import {LangMessagesType} from "../../interfaces/lang";

export default {
    sk: {
        "tags.add": "Pridať tag",
        "tags.tagname": "Názov tagu",
        "tags.color": "Farba",
        "tags.selectcolor": "Zvoľ farbu",
        "tags.create": "Vytvoriť tag",
        "tags.edit": "Upraviť tag",
        "tags.systemtag": "Systémový tag",
        "tags.removefromproduct": "Odstániť tag z produktu",
    },
    en: {
        "tags.add": "Add tag",
        "tags.tagname": "Tag name",
        "tags.color": "Color",
        "tags.selectcolor": "Select color",
        "tags.create": "Create tag",
        "tags.edit": "Edit tag",
        "tags.systemtag": "System tag",
        "tags.removefromproduct": "Remove tag from product",
    }

} as LangMessagesType;
