import React, { ContextType, PureComponent, ReactElement } from "react";
import { IsbnPublisherFilters } from "./components/isbnpublisherfilters";
import { IsbnPublisherFiltersType } from "./types";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../utils/appcontext";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import { IsbnPUblisherCategory } from "@ozibooks/server/dist/modules/isbnprefix/types/category";
import { IsbnPublisherEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnpublisher.entity";
import LoadIndicator from "../../components/loadindicator";
import IsbnPublisherContainer from "../../components/isbnprefixpublisher/container";
import { FormattedMessage } from "react-intl";

const MAX_ITEMS_LIMIT = 100;

type State = {
    filters: IsbnPublisherFiltersType;
    page: number;
    entities: IsbnPublisherEntity[];
    hasNextPage: boolean;
} & RequestStatus2;

const validateIsbn13Prefix = (prefix: string): boolean => {
    return /^97[89]-\d+-\d+$/.test(prefix);
};

const getDefaultState = (): State => ({
    filters: {
        name: "",
        ignored: "",
        used_only: "",
        category: "",
    },
    page: 1,
    hasNextPage: false,
    requestStatus: null,
    errorMsg: null,
    entities: [],
});

class IsbnPublisherPage extends PureComponent<RouteComponentProps, State> {
    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;

    constructor(props: any) {
        super(props);
        this.state = getDefaultState();
    }

    onFiltersSubmit = (ev: React.FormEvent) => {
        ev.preventDefault();

        const name = this.state.filters.name.trim();
        if (name && validateIsbn13Prefix(name)) {
            return this.props.history.push("/isbnprefix/" + name);
        }

        this.loadItems(1).catch((e) => {
            this.setState({ errorMsg: e });
        });
    };

    private async loadItems(pageNumber: number) {
        const { name, ignored, used_only, category } = this.state.filters;
        this.setState({
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
            page: pageNumber,
            hasNextPage: false,
            entities: [],
        });

        try {
            const res = await this.context!.client.isbnPrefix.getAll(
                pageNumber,
                ignored === "" ? undefined : ignored === "0" ? 0 : 1,
                used_only === "" ? undefined : used_only === "0" ? 0 : 1,
                name === "" ? undefined : name.trim(),
                category === ""
                    ? undefined
                    : (category as IsbnPUblisherCategory | "*")
            );

            this.setState({
                requestStatus: RequestStatusEnum.SUCCESS,
                entities: res,
                hasNextPage: res.length >= MAX_ITEMS_LIMIT,
            });
        } catch (e: any) {
            this.setState({
                requestStatus: RequestStatusEnum.FAIL,
                errorMsg: e,
            });
        }
    }

    onFilterChange = (key: keyof IsbnPublisherFiltersType, val: string) => {
        this.setState((old) => {
            const newState = { ...old };
            newState.filters = { ...old.filters };
            newState.filters[key] = val;

            return newState;
        });
    };

    private renderBody(): ReactElement | null {
        if (this.state.requestStatus === RequestStatusEnum.ACTIVE) {
            return <LoadIndicator />;
        }

        if (
            this.state.requestStatus === RequestStatusEnum.FAIL ||
            this.state.requestStatus === null
        ) {
            return null;
        }

        if (this.state.entities.length === 0) {
            return (
                <div>
                    <FormattedMessage id={"noresult"} />
                </div>
            );
        }

        return (
            <IsbnPublisherContainer
                publishers={this.state.entities}
                client={this.context!.client}
            />
        );
    }

    render():
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        return (
            <div className="page">
                <h1>
                    ISBN <FormattedMessage id={"publishers"} />
                </h1>
                <IsbnPublisherFilters
                    filters={this.state.filters}
                    onSubmit={this.onFiltersSubmit}
                    onFilterChange={this.onFilterChange}
                />
                {this.renderBody()}
            </div>
        );
    }
}

export default IsbnPublisherPage;
