import { useCallback, useContext, useState } from "react";
import { UploadFileEntity } from "@ozibooks/server/dist/modules/upload/entities/uploadfile.entity";
import {
    UploadFileDestination,
    UploadFileUploadType,
} from "@ozibooks/server/dist/modules/upload/types";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import { AppContext } from "../../utils/appcontext";
import saveAs from "../../utils/dom/saveas";

export type UploadFileItem = UploadFileEntity & RequestStatus2;

type State = RequestStatus2 & {
    items: UploadFileItem[];
    isOpen: boolean;
};

const useFileBox = () => {
    const [state, setState] = useState<State>({
        items: [],
        requestStatus: null,
        errorMsg: null,
        isOpen: false,
    });

    const context = useContext(AppContext);
    const { client } = context!;

    const setItems = useCallback((newItems: UploadFileEntity[]) => {
        setState((old) => ({
            ...old,
            items: [
                ...old.items,
                ...newItems.map((e) => ({
                    ...e,
                    requestStatus: null,
                    errorMsg: null,
                })),
            ],
        }));
    }, []);

    const loadItems = useCallback(
        async (
            type?: UploadFileUploadType,
            destination?: UploadFileDestination,
            uploaded?: 0 | 1
        ) => {
            try {
                setState((old) => ({
                    ...old,
                    items: [],
                    errorMsg: null,
                    requestStatus: RequestStatusEnum.ACTIVE,
                }));
                const res = await client.upload.files.getAll(
                    type,
                    destination,
                    uploaded
                );

                setState((old) => ({
                    ...old,
                    requestStatus: RequestStatusEnum.SUCCESS,
                    items: res.map((e) => ({
                        ...e,
                        requestStatus: null,
                        errorMsg: null,
                    })),
                }));
            } catch (e: any) {
                setState((old) => ({
                    ...old,
                    requestStatus: RequestStatusEnum.FAIL,
                    errorMsg: e,
                }));
            }
        },
        [client]
    );

    const { items, isOpen, requestStatus, errorMsg } = state;
    const downloadFile = useCallback(
        async (id: number) => {
            const item = items.filter((e) => e.id === id)[0];
            if (!item) {
                return;
            }

            try {
                setState((old) => ({
                    ...old,
                    items: old.items.map((e) =>
                        e.id !== id
                            ? e
                            : {
                                  ...e,
                                  requestStatus: RequestStatusEnum.ACTIVE,
                                  errorMsg: null,
                              }
                    ),
                }));

                const blob = await client.upload.files.downloadFile(id);

                setState((old) => ({
                    ...old,
                    items: old.items.map((e) =>
                        e.id === id
                            ? { ...e, requestStatus: RequestStatusEnum.SUCCESS }
                            : e
                    ),
                }));

                saveAs(item.uploadfile, blob);
            } catch (err: any) {
                setState((old) => ({
                    ...old,
                    items: old.items.map((e) =>
                        e.id !== id
                            ? e
                            : {
                                  ...e,
                                  requestStatus: RequestStatusEnum.FAIL,
                                  errorMsg: err,
                              }
                    ),
                }));
            }
        },
        [client, items]
    );

    const markAsUploaded = useCallback(
        async (id: number) => {
            if (items.every((e) => e.id !== id)) {
                return;
            }

            try {
                setState((old) => ({
                    ...old,
                    items: old.items.map((e) =>
                        e.id !== id
                            ? e
                            : {
                                  ...e,
                                  requestStatus: RequestStatusEnum.ACTIVE,
                                  errorMsg: null,
                              }
                    ),
                }));

                await client.upload.files.markAsDownloaded(id);

                setState((old) => ({
                    ...old,
                    items: old.items.filter((e) => e.id !== id),
                }));
            } catch (err: any) {
                setState((old) => ({
                    ...old,
                    items: old.items.map((e) =>
                        e.id !== id
                            ? e
                            : {
                                  ...e,
                                  requestStatus: RequestStatusEnum.FAIL,
                                  errorMsg: err,
                              }
                    ),
                }));
            }
        },
        [client, items]
    );

    const toggleOpen = useCallback(
        () => setState((old) => ({ ...old, isOpen: !old.isOpen })),
        []
    );

    return {
        setItems,
        loadItems,
        requestStatus,
        errorMsg,
        fileboxProps: {
            downloadFile,
            items,
            markAsUploaded,
            toggleOpen,
            isOpen,
        },
    };
};

export default useFileBox;
