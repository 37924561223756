import React, { FunctionComponent, memo } from "react";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import { Link } from "react-router-dom";
import "./isbnprefixlink.scss";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import IsbnPrefixHeaderIcons from "../../isbnprefix/icons/isbnprefixheadericons";
import IsbnPrefixHeaderDate from "../../isbnprefix/date/isbnprefixheaderdate";

type Props = {
    item: IsbnPrefixEntity;
};

const IsbnPrefixLink: FunctionComponent<Props> = memo(({ item }) => {
    let clx = "ob-isbnpublisheritem__prefixlink";
    if (!item.scraped_at) {
        clx += " notscraped";
    }

    const { prefix13 } = item;

    const clx1 = item.starred ? "isbnpublisheritem__prefixlink--active" : "";
    const clx2 = item.used_only ? "isbnpublisheritem__prefixlink--active" : "";
    const clx3 = item.ignored ? "isbnpublisheritem__prefixlink--active" : "";
    return (
        <OverlayTrigger
            trigger={"hover"}
            overlay={
                <Popover id={"prefixlink-" + prefix13}>
                    <Popover.Content>
                        <IsbnPrefixHeaderIcons item={item} />
                        <IsbnPrefixHeaderDate item={item} />
                    </Popover.Content>
                </Popover>
            }
        >
            <Link className={clx} to={"/isbnprefix/" + item.prefix13}>
                <span>
                    <span className={clx1}>{prefix13[0]}</span>
                    <span className={clx2}>{prefix13[1]}</span>
                    <span className={clx3}>{prefix13[2]}</span>
                    {prefix13.slice(3)}
                </span>
            </Link>
        </OverlayTrigger>
    );
});

export default IsbnPrefixLink;
