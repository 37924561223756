import React, { FunctionComponent } from "react";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import ActionMenu, { ActionMenuActionType } from "../../actionmenu/actionmenu";
import IsbnPrefixHeaderIcons from "../icons/isbnprefixheadericons";
import IsbnPrefixHeaderDate from "../date/isbnprefixheaderdate";
import "./isbnprefixheader.scss";

type Props = {
    item?: IsbnPrefixEntity;
    prefix: string;
    actions: ActionMenuActionType[];
};

const IsbnPrefixHeader: FunctionComponent<Props> = ({
    prefix,
    item,
    actions,
}) => {
    return (
        <div className={"ob-isbnprefixheader__wrapper"}>
            <div className="ob-isbnprefixheader">
                <h2>ISBN Prefix {prefix}</h2>
                <ActionMenu actions={actions} />
            </div>
            {!!item && <IsbnPrefixHeaderDate item={item} />}
            {!!item && <IsbnPrefixHeaderIcons item={item} />}
        </div>
    );
};

export default IsbnPrefixHeader;
