import React, { FunctionComponent } from "react";
import {
    ProSidebar,
    SidebarContent,
    Menu,
    MenuItem,
    SubMenu,
} from "react-pro-sidebar";
import { Link } from "react-router-dom";
import "react-pro-sidebar/dist/css/styles.css";
import { FormattedMessage, useIntl } from "react-intl";

type Props = {};

export const Sidebar: FunctionComponent<Props> = () => {
    const intl = useIntl();

    return (
        <ProSidebar collapsed={false} toggled={false}>
            <SidebarContent>
                <Menu>
                    <MenuItem>
                        <Link to={"/"}>Home</Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <MenuItem>
                        <Link to={"/settings"}>
                            <FormattedMessage id="settings" />
                        </Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <MenuItem>
                        <Link to={"/publishing_status"}>
                            Publishing statuses
                        </Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <SubMenu title={"Sklad"}>
                        <MenuItem>
                            <Link to={"/amazonshop/quantity/high"}>
                                <FormattedMessage id={"quantity.high"} />
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to={"/amazonshop/quantity/low"}>
                                <FormattedMessage id={"quantity.low"} />
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to={"/amazonshop/quantity/zero"}>
                                <FormattedMessage id={"quantity.zero"} />
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to={"/amazonshop/quantity/critical"}>
                                <FormattedMessage id={"quantity.critical"} />
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to={"/amazonshop/quantity/activate"}>
                                <FormattedMessage id={"quantity.toactivate"} />
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to={"/amazonshop/quantity/deactivate"}>
                                <FormattedMessage
                                    id={"quantity.todeactivate"}
                                />
                            </Link>
                        </MenuItem>
                        <MenuItem>
                            <Link to={"/toomanyoffers"}>Viac ako 5 ponúk</Link>
                        </MenuItem>
                    </SubMenu>
                </Menu>
                <Menu>
                    <SubMenu title={"Upload"}>
                        <MenuItem>
                            <Link to={"/upload/quantity"}>
                                <FormattedMessage id={"quantityinventory"} />
                            </Link>
                        </MenuItem>
                    </SubMenu>
                </Menu>
                <Menu>
                    <MenuItem>
                        <Link to={"/isbnpublishers"}>ISBN Prefix</Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <MenuItem>
                        <Link to={"/tags"}>Tags</Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <MenuItem>
                        <Link to={"/blacklist"}>Blacklist</Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <MenuItem>
                        <Link to={"/notifications"}>
                            <FormattedMessage id={"notifications"} />
                        </Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <MenuItem>
                        <Link to={"/notes"}>Poznámky</Link>
                    </MenuItem>
                </Menu>
                <Menu>
                    <SubMenu title={intl.formatMessage({ id: "tools" })}>
                        <MenuItem>
                            <Link to={"/isbntools"}>
                                <FormattedMessage id={"isbntools"} />
                            </Link>
                        </MenuItem>
                    </SubMenu>
                </Menu>
            </SidebarContent>
        </ProSidebar>
    );
};
