import React, {FunctionComponent} from 'react';
import Tag from "./tag";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import {useIntl} from "react-intl";

type Props = {
    text: string,
    color?: string | null;
    onDelete: () => void,
};

export const TagDeletable: FunctionComponent<Props> = ({text, color, onDelete}) => {
    const intl = useIntl();
    let clx = "ob-tag ";
    if(color) {
        clx += "ob-tag--" + color;
    }

    return (
        <span className={"ob-tag__deletable"}><Tag
            text={text} color={color}
        /><span
            onClick={onDelete}
            className={"ob-tag--deleteicon " + clx}
            title={intl.formatMessage({id: "tags.removefromproduct"})}
        ><FontAwesomeIcon icon={faTimes}/></span></span>

    );
};
