import React, {FunctionComponent} from 'react';
import {useParams} from "react-router";
import ListingView from "../../components/listingview/listingview";

type Props = {

};

const ListingDetailPage: FunctionComponent<Props> = () => {
    const {sku} = useParams<{sku: string}>();
    console.log(sku);
    return (
        <div className={"page"}>
            <ListingView sku={sku} />
        </div>
    );
};

export default ListingDetailPage;
