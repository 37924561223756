import {LangMessagesType} from "../../interfaces/lang";
import serverMesssages from "./components/server.lang";


const messages: LangMessagesType = {
    sk: {
        ...serverMesssages.sk,
        settings: "Nastavenia"

    },
    en: {
        ...serverMesssages.en,
        settings: "Settings"
    },
};


export default messages;
