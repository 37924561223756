import React, { ContextType, createRef, PureComponent, RefObject } from "react";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import clone from "../../utils/clone";
import { AppContext } from "../../utils/appcontext";
import IsbnParser, { ISBNData } from "isbn3";

import "./listingview.scss";
import Alertbox from "../alerbox/alertbox";
import { TagEntity } from "@ozibooks/server/dist/modules/tags/entities/tag.entity";
import { UnknownClientException } from "../../utils/apiclient/exceptions";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ActionMenuActionType } from "../actionmenu/actionmenu";
import { PageBody, PageHeading } from "../page/page";
import { TagsContainer } from "../tagscontainer/tagscontainer";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import ListingViewDetailQuantity from "./detail/components/quantity";
import { ListingViewStatus } from "./detail/components/status";
import ListingVieDetailIsbn from "./detail/components/isbnprefix";
import { BlacklistForm } from "./detail/components/blacklistform";
import ListingViewPrices from "./detail/components/prices";
import { ListingViewListing } from "./detail/components/listing";
import { ListingViewBz } from "./detail/components/bz";
import ListingViewOlf from "./detail/components/olf";
import ListingViewAva from "./detail/components/ava";
import ListingViewBalmer from "./detail/components/balmer";
import ListingViewZiegel from "./detail/components/ziegel";
import ListingViewAvaWeb from "./detail/components/avaweb";
import ListingViewBalmerWeb from "./detail/components/balmerweb";
import ListingViewAmazondata from "./detail/components/amazondata";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";

export type ListingViewProps = WrappedComponentProps & {
    entity?: Amazonshop;
    sku: string;
};

type ListingViewStateBase = {
    entity: Amazonshop | null;
    tagsRequest: RequestStatusEnum | null;
    tags: TagEntity[];
    isbnData: ISBNData | null;
    avaWebRequest: RequestStatusEnum | null;
    balmerWebRequest: RequestStatusEnum | null;
    competitiveRequest: RequestStatusEnum | null;
    lowestOfersRequest: RequestStatusEnum | null;
};

type ListingViewState = ListingViewStateBase & RequestStatus2;

const defaultState: ListingViewState = {
    entity: null,
    requestStatus: null,
    errorMsg: null,
    tagsRequest: null,
    tags: [],
    isbnData: null,
    avaWebRequest: null,
    balmerWebRequest: null,
    competitiveRequest: null,
    lowestOfersRequest: null,
};

class ListingView extends PureComponent<ListingViewProps, ListingViewState> {
    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;
    protected alertRef: RefObject<Alertbox>;
    private actions: ActionMenuActionType[];
    private locale: string;

    constructor(props: ListingViewProps) {
        super(props);

        const state = clone(defaultState);
        if (props.entity) {
            state.entity = props.entity;
            state.requestStatus = RequestStatusEnum.SUCCESS;
        }

        this.state = state;
        this.alertRef = createRef<Alertbox>();
        this.loadEntity = this.loadEntity.bind(this);
        this.actions = [];
        this.locale = props.intl.locale;
        this.createActions();
    }

    private createActions() {
        this.actions = [
            {
                label: this.props.intl.formatMessage({ id: "reload" }),
                action: this.reload,
                disabled: false,
            },
        ];
    }
    private reload = () => this.loadEntity(this.props.sku);
    private async loadEntity(sku: string): Promise<void> {
        this.setState((old) => ({
            ...clone(defaultState),
            tagsRequest: old.tagsRequest,
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
            entity: null,
            isbnData: null,
        }));

        try {
            const entity = await this.context!.client.amazonshop.sku(sku);
            if (!entity) {
                throw new UnknownClientException("Entity is empty");
            }

            const idata = entity.real_product_id
                ? IsbnParser.parse(entity.real_product_id)
                : null;
            this.setState((state) => ({
                ...state,
                requestStatus: RequestStatusEnum.SUCCESS,
                errorMsg: null,
                entity,
                isbnData: idata ? idata : null,
            }));
        } catch (e: any) {
            this.setState((state) => ({
                ...state,
                requestStatus: RequestStatusEnum.FAIL,
                errorMsg: e,
            }));
        }
    }
    private loadTags = async () => {
        this.setState({
            tagsRequest: RequestStatusEnum.ACTIVE,
            tags: [],
        });
        try {
            const tags = await this.context!.client.tags.getAll();
            this.setState({
                tags,
                tagsRequest: RequestStatusEnum.SUCCESS,
            });
        } catch (e: any) {
            this.setState({ tagsRequest: RequestStatusEnum.FAIL });
            this.alertRef.current?.addError(e, this.loadTags);
        }
    };
    private addTag = async (id: number): Promise<void> => {
        if (!this.state.entity) {
            return;
        }
        const isbn = this.state.entity.real_product_id;
        if (!isbn) {
            return;
        }

        let currentTags: number[] = [];

        if (this.state.entity.tags || Array.isArray(this.state.entity.tags)) {
            currentTags = [...this.state.entity.tags.map((e) => e.id)];
        }

        if (currentTags.includes(id)) {
            return;
        }

        const addedTag = this.state.tags.filter((e) => e.id === id)[0];
        if (!addedTag) {
            return;
        }

        try {
            await this.context!.client.tags.updateProductTags(isbn, [
                ...currentTags,
                id,
            ]);

            this.setState((old) => {
                if (old.entity === undefined || old.entity === null) {
                    return { ...old };
                }

                const entity = { ...old.entity };
                entity.tags = old.entity.tags
                    ? [...old.entity.tags, addedTag]
                    : [addedTag];
                return {
                    ...old,
                    entity,
                };
            });
        } catch (e: any) {
            this.alertRef.current?.addError(e);
            throw e;
        }
    };
    private deleteTag = async (id: number) => {
        if (!this.state.entity) {
            return;
        }

        const isbn = this.state.entity.real_product_id;
        if (!isbn) {
            return;
        }

        if (!this.state.entity.tags || !this.state.entity.tags.length) {
            return;
        }
        const currentTags = [...this.state.entity.tags];
        const currentTagsIds = this.state.entity.tags.map((e) => e.id);
        if (!currentTagsIds.includes(id)) {
            return;
        }

        try {
            await this.context!.client.tags.updateProductTags(
                isbn,
                currentTagsIds.filter((e) => e !== id)
            );
            this.setState((old) => {
                if (!old.entity) {
                    return null;
                }

                return {
                    entity: {
                        ...old.entity,
                        tags: currentTags.filter((e) => e.id !== id),
                    },
                };
            });
        } catch (e: any) {
            this.alertRef.current?.addError(e);
            throw e;
        }
    };

    private addToBlacklist = (
        product_id: string,
        text: string
    ): Promise<void> => {
        return this.context!.client.blacklist.create(product_id, text).then(
            (item) => {
                this.setState((old) => {
                    if (!old.entity) {
                        return null;
                    }
                    return {
                        entity: {
                            ...old.entity,
                            blackList: item,
                        },
                    };
                });
            }
        );
    };
    private updateBlacklist = (
        product_id: string,
        text: string
    ): Promise<void> => {
        return this.context!.client.blacklist.update(product_id, text).then(
            () => {
                this.setState((old) => {
                    if (!old.entity) {
                        return null;
                    }
                    return {
                        entity: {
                            ...old.entity,
                            blackList: old.entity.blackList
                                ? { ...old.entity.blackList, reason: text }
                                : {
                                      product_id,
                                      reason: text,
                                      created_at: new Date(),
                                  },
                        },
                    };
                });
            }
        );
    };
    private deleteBlacklist = (product_id: string): Promise<void> => {
        return this.context!.client.blacklist.delete(product_id).then(() => {
            this.setState((old) => {
                if (!old.entity) {
                    return null;
                }

                return {
                    entity: { ...old.entity, blackList: null },
                };
            });
        });
    };

    private addToUpload = (sku: string, quantity: number): Promise<void> => {
        return this.context!.client.upload.quantity.update([
            { sku, quantity },
        ]).then(() => {
            this.setState((old) => {
                if (!old.entity) {
                    return null;
                }

                return {
                    entity: {
                        ...old.entity,
                        uploadQuantity: old.entity.uploadQuantity
                            ? { ...old.entity.uploadQuantity, quantity }
                            : { sku, quantity, created_at: new Date() },
                    },
                };
            });
        });
    };
    private onPrefixEntityUpdate = (ent: IsbnPrefixEntity) => {
        this.setState((old) => {
            if (!old.entity) {
                return null;
            }

            return {
                entity: {
                    ...old.entity,
                    isbnPrefix: ent,
                },
            };
        });
    };

    private updateAvaWeb = () => {
        if (this.state.avaWebRequest === RequestStatusEnum.ACTIVE) {
            return;
        }

        if (!this.context || !this.state.entity?.real_product_id) {
            return;
        }

        this.setState((old) => {
            return { ...old, avaWebRequest: RequestStatusEnum.ACTIVE };
        });

        const product_id = this.state.entity.real_product_id;
        this.context.client.avaWeb
            .update(product_id)
            .then((data) => {
                const { entities, response } = data;
                this.setState((old) => {
                    if (!old.entity) {
                        return {
                            ...old,
                            avaWebRequest: RequestStatusEnum.SUCCESS,
                        };
                    }
                    return {
                        ...old,
                        avaWebRequest: RequestStatusEnum.SUCCESS,
                        entity: {
                            ...old.entity,
                            avaWeb: [...entities],
                            webcheck: response.webcheck
                                ? { ...response.webcheck }
                                : undefined,
                        },
                    };
                });
            })
            .catch((err) => {
                this.alertRef.current?.addError(err, this.updateAvaWeb);
                this.setState((old) => ({
                    ...old,
                    avaWebRequest: RequestStatusEnum.FAIL,
                }));
            });
    };

    private updateBalmerWeb = () => {
        if (this.state.balmerWebRequest === RequestStatusEnum.ACTIVE) {
            return;
        }

        if (!this.context || !this.state.entity?.real_product_id) {
            return;
        }

        this.setState((old) => ({
            ...old,
            balmerWebRequest: RequestStatusEnum.ACTIVE,
        }));

        const product_id = this.state.entity.real_product_id;
        this.context.client.balmerWeb
            .update(product_id)
            .then((data) => {
                const { entities, response } = data;
                this.setState((old) => {
                    if (!old.entity) {
                        return {
                            ...old,
                            balmerWebRequest: RequestStatusEnum.SUCCESS,
                        };
                    }

                    return {
                        ...old,
                        balmerWebRequest: RequestStatusEnum.SUCCESS,
                        entity: {
                            ...old.entity!,
                            balmerWeb: [...entities],
                            webcheck: response.webcheck
                                ? { ...response.webcheck }
                                : null,
                        },
                    };
                });
            })
            .catch((err) => {
                this.alertRef.current?.addError(err, this.updateBalmerWeb);
                this.setState((old) => ({
                    ...old,
                    balmerWebRequest: RequestStatusEnum.FAIL,
                }));
            });
    };

    componentDidMount() {
        if (this.state.requestStatus !== null) {
            return;
        }

        if (this.state.tagsRequest === null) {
            this.loadTags().catch(console.error);
        }

        this.loadEntity(this.props.sku).catch(console.error);
    }

    componentDidUpdate(
        prevProps: Readonly<ListingViewProps>,
        prevState: Readonly<ListingViewState>,
        snapshot?: any
    ): void {
        if (this.props.sku !== prevProps.sku) {
            this.loadEntity(this.props.sku).catch(console.log);
        }
    }

    private getBlacklistkey() {
        if (!this.state.entity) {
            return "blacklistnoentity";
        }

        if (!this.state.entity.blackList) {
            return "blacklistnew";
        }

        return (
            "blacklist" +
            this.state.entity.real_product_id +
            this.state.entity.blackList.reason
        );
    }

    private checkAmazonCompetitive = async (asin: string) => {
        if (this.state.competitiveRequest === RequestStatusEnum.ACTIVE) {
            return;
        }

        this.setState((old) => ({
            ...old,
            competitiveRequest: RequestStatusEnum.ACTIVE,
        }));

        try {
            const res =
                await this.context!.client.amazonshop.checkCompetitivePricing(
                    asin
                );
            this.setState((old) => ({
                ...old,
                competitiveRequest: RequestStatusEnum.SUCCESS,
                entity: {
                    ...old.entity!,
                    amazonCompetitive: res,
                },
            }));
        } catch (err: any) {
            this.setState((old) => ({
                ...old,
                competitiveRequest: RequestStatusEnum.FAIL,
            }));
            this.alertRef.current?.addError(err);
        }
    };

    private checkLowestOffers = async (asin: string) => {
        if (this.state.lowestOfersRequest === RequestStatusEnum.ACTIVE) {
            return;
        }

        this.setState((old) => ({
            ...old,
            lowestOfersRequest: RequestStatusEnum.ACTIVE,
        }));

        try {
            const res = await this.context!.client.amazonshop.checkLowestOffers(
                asin
            );
            this.setState((old) => ({
                ...old,
                lowestOfersRequest: RequestStatusEnum.SUCCESS,
                entity: {
                    ...old.entity!,
                    amazonLowest: res,
                },
            }));
        } catch (err: any) {
            this.setState((old) => ({
                ...old,
                lowestOfersRequest: RequestStatusEnum.FAIL,
            }));
            this.alertRef.current?.addError(err);
        }
    };
    private renderInfoBlock() {
        const { entity } = this.state;
        if (!entity) {
            return null;
        }
        const real_product_id = entity.real_product_id;
        const { isbnData } = this.state;
        const { intl } = this.props;
        let isbn = "";
        if (isbnData) {
            isbn = `${isbnData.isbn13h} (${isbnData.isbn10})`;
        } else {
            isbn = real_product_id
                ? intl.formatMessage({ id: "listingview.invalidisbn" }) +
                  " " +
                  real_product_id
                : intl.formatMessage({ id: "listingview.noisbn" });
        }

        return (
            <table className="table table-borderless">
                <tbody>
                    {entity?.blackList && (
                        <tr>
                            <th>
                                {intl.formatMessage({
                                    id: "blacklist.blacklisted",
                                })}
                            </th>
                            <td>{entity?.blackList?.reason.slice(0, 100)}</td>
                        </tr>
                    )}
                    <tr key={"isbn"}>
                        <th>ISBN</th>
                        <td>{isbn}</td>
                    </tr>
                    <tr key={"amazonshopstatus"}>
                        <th>Status</th>
                        <td>{this.state.entity?.status}</td>
                    </tr>
                    <tr>
                        <th>Tags</th>
                        <td>
                            <TagsContainer
                                allTags={this.state.tags}
                                selectedTags={this.state.entity?.tags || []}
                                tagsRequest={this.state.tagsRequest}
                                addTag={this.addTag}
                                deleteTag={this.deleteTag}
                            />
                        </td>
                    </tr>
                </tbody>
            </table>
        );
    }

    render() {
        if (this.locale !== this.props.intl.locale) {
            this.createActions();
            this.locale = this.props.intl.locale;
        }

        const { entity, isbnData } = this.state;
        const { intl } = this.props;

        return (
            <div className="ob-listingview">
                <PageHeading
                    title={"SKU: " + this.props.sku}
                    actions={this.actions}
                />
                <Alertbox ref={this.alertRef} />
                <PageBody
                    requestStatus={this.state.requestStatus}
                    errorMsg={this.state.errorMsg}
                    onRetry={this.reload}
                >
                    <div className={"ob-listingviewdetail"}>
                        {this.renderInfoBlock()}
                        <Tabs defaultActiveKey={"quantity"}>
                            <Tab eventKey={"quantity"} title={"Sklad"}>
                                <ListingViewDetailQuantity
                                    addUploadQuantity={this.addToUpload}
                                    entity={entity!}
                                    intl={intl}
                                />
                            </Tab>
                            <Tab eventKey={"prices"} title={"Ceny"}>
                                <ListingViewPrices
                                    entity={this.state.entity!}
                                    intl={this.props.intl}
                                />
                            </Tab>
                            <Tab eventKey={"status"} title={"Status"}>
                                <ListingViewStatus
                                    entity={this.state.entity!}
                                    avaWebRequest={this.state.avaWebRequest}
                                    updateAvaWeb={this.updateAvaWeb}
                                    updateBalmerWeb={this.updateBalmerWeb}
                                    balmerWebRequest={
                                        this.state.balmerWebRequest
                                    }
                                />
                            </Tab>
                            <Tab eventKey={"listing"} title={"Amazonshop"}>
                                <ListingViewListing
                                    entity={this.state.entity!}
                                    intl={this.props.intl}
                                />
                            </Tab>
                            {!!this.state.entity?.bz?.length && (
                                <Tab eventKey={"bz"} title={"BZ"}>
                                    <ListingViewBz
                                        items={this.state.entity!.bz!}
                                        intl={this.props.intl}
                                    />
                                </Tab>
                            )}
                            {!!this.state.entity?.olf && (
                                <Tab eventKey={"olf"} title={"Olf"}>
                                    <ListingViewOlf
                                        item={this.state.entity.olf}
                                        intl={this.props.intl}
                                    />
                                </Tab>
                            )}
                            {!!this.state.entity?.ava && (
                                <Tab eventKey={"ava"} title={"Ava"}>
                                    <ListingViewAva
                                        item={this.state.entity.ava}
                                    />
                                </Tab>
                            )}
                            {!!this.state.entity?.balmer && (
                                <Tab eventKey={"balmer"} title={"Balmer"}>
                                    <ListingViewBalmer
                                        item={this.state.entity.balmer}
                                    />
                                </Tab>
                            )}
                            {!!this.state.entity?.ziegel && (
                                <Tab eventKey={"ziegel"} title={"Ziegel"}>
                                    <ListingViewZiegel
                                        item={this.state.entity.ziegel}
                                    />
                                </Tab>
                            )}
                            {!!this.state.entity?.avaWeb?.length && (
                                <Tab eventKey={"avaweb"} title={"Ava Web"}>
                                    <ListingViewAvaWeb
                                        items={this.state.entity.avaWeb}
                                    />
                                </Tab>
                            )}
                            {!!this.state.entity?.balmerWeb?.length && (
                                <Tab
                                    eventKey={"balmerweb"}
                                    title={"Balmer Web"}
                                >
                                    <ListingViewBalmerWeb
                                        items={this.state.entity.balmerWeb}
                                    />
                                </Tab>
                            )}
                            <Tab eventKey={"amazondata"} title={"Amazon data"}>
                                <ListingViewAmazondata
                                    item={this.state.entity!}
                                    intl={this.props.intl}
                                    competitiveRequest={
                                        this.state.competitiveRequest
                                    }
                                    updateCompetitive={
                                        this.checkAmazonCompetitive
                                    }
                                    lowestRequest={
                                        this.state.lowestOfersRequest
                                    }
                                    updateLowest={this.checkLowestOffers}
                                />
                            </Tab>
                            {!!isbnData && (
                                <Tab
                                    eventKey={"isbnprefix"}
                                    title={"ISBN Prefix"}
                                >
                                    <ListingVieDetailIsbn
                                        entity={entity!}
                                        isbnData={isbnData}
                                        onEntityUpdate={
                                            this.onPrefixEntityUpdate
                                        }
                                    />
                                </Tab>
                            )}
                            {!!this.state.entity?.real_product_id && (
                                <Tab eventKey={"balcklist"} title={"Blacklist"}>
                                    <BlacklistForm
                                        key={this.getBlacklistkey()}
                                        product_id={
                                            this.state.entity.real_product_id
                                        }
                                        initialText={
                                            this.state.entity?.blackList
                                                ?.reason || ""
                                        }
                                        isNew={!this.state.entity.blackList}
                                        onCreate={this.addToBlacklist}
                                        onUpdate={this.updateBlacklist}
                                        onDelete={this.deleteBlacklist}
                                    />
                                </Tab>
                            )}
                        </Tabs>
                    </div>
                </PageBody>
            </div>
        );
    }
}

export default injectIntl<"intl", ListingViewProps>(ListingView);
