import { AmazonshopQuantityDto } from "@ozibooks/server/dist/modules/amazon/dto/amazonshopquantity.dto";
import { AmazonshopQuantityItem } from "../table";
import { MAX_QUANTITY_WITH_STOCKS } from "../../../interfaces/constants";

const dtoToQuantityItems = (
    data: AmazonshopQuantityDto[]
): AmazonshopQuantityItem[] => {
    return data.map((e) => {
        const total = parseInt(e.total_quantity);
        return {
            ...e,
            new_quantity:
                total > MAX_QUANTITY_WITH_STOCKS
                    ? MAX_QUANTITY_WITH_STOCKS
                    : total,
            total_quantity: total,
        };
    });
};

export default dtoToQuantityItems;
