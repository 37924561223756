import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { Balmer } from "@ozibooks/server/dist/modules/balmer/entities/balmer.entity";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";

type Props = {
    item: Balmer;
};

const ListingViewBalmer: FunctionComponent<Props> = ({ item }) => {
    const rows: ReactNode[][] = useMemo(() => {
        return [
            ["product_id", item.product_id],
            ["product_id_type", item.product_id_type],
            ["wanted", item.wanted],
            ["price", item.price],
            ["quantity", item.quantity],
            ["created_at", <DateTimeField dateTime={item.created_at} />],
            ["updated_at", <DateTimeField dateTime={item.updated_at} />],
        ];
    }, [item]);

    return (
        <div className={"ob-listingviewbalmer"}>
            <InfoTable items={rows} />
        </div>
    );
};

export default ListingViewBalmer;
