import {LangMessagesType} from "../interfaces/lang";
import globalMessages from "./global";
import settingsMessages from "../pages/settings/settings.lang";
import publishingStatusMessages from "../pages/publishingstatus/publishingstatus.lang";
import quantityMessages from "../pages/quantity/quantitypage.lang";
import componentsMessages from "../components/components.lang";
import uploadQuantityMessages from "../pages/uploadquantity/uploadquantitypage.lang";
import prefixMessages from "../pages/isbnprefix/isbnprefix.lang";
import tagsMessages from "../pages/tags/tags.lang";
import blacklistMessages from "../pages/blacklist/blacklist.lang";


const allMessages: LangMessagesType =  {
    en: {
        ...globalMessages.en,
        ...settingsMessages.en,
        ...publishingStatusMessages.en,
        ...quantityMessages.en,
        ...componentsMessages.en,
        ...uploadQuantityMessages.en,
        ...prefixMessages.en,
        ...tagsMessages.en,
        ...blacklistMessages.en,
    },
    sk: {
        ...globalMessages.sk,
        ...settingsMessages.sk,
        ...publishingStatusMessages.sk,
        ...quantityMessages.sk,
        ...componentsMessages.sk,
        ...uploadQuantityMessages.sk,
        ...prefixMessages.sk,
        ...tagsMessages.sk,
        ...blacklistMessages.sk,
    }
};

export const addLangMessages = (data: LangMessagesType) => {
    if(typeof data.sk === "object") {
        for (const k in data.sk) {
            if(data.sk.hasOwnProperty(k)) {
                allMessages.sk[k] = data.sk[k];
            }
        }
    }

    if(typeof data.en === "object") {
        for (const k in data.en) {
            if(data.en.hasOwnProperty(k)) {
                allMessages.en[k] = data.en[k];
            }
        }
    }
};

export default allMessages;
