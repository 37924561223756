import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { AvaWebEntity } from "@ozibooks/server/dist/modules/ava/entities/avaweb.entity";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";

type Props = {
    items: AvaWebEntity[];
};

const ListingViewAvaWeb: FunctionComponent<Props> = ({ items }) => {
    const rows: ReactNode[][] = useMemo(() => {
        return [
            ["avaid", ...items.map((e) => e.avaid)],
            ["product_id", ...items.map((e) => e.product_id)],
            ["title", ...items.map((e) => e.title)],
            [
                "publish_date",
                ...items.map((e) => (
                    <DateTimeField dateTime={e.publish_date} />
                )),
            ],
            ["price", ...items.map((e) => e.price)],
            ["publisher", ...items.map((e) => e.publisher)],
            ["authors", ...items.map((e) => e.authors)],
            ["product_form", ...items.map((e) => e.product_form)],
            ["publishing_status", ...items.map((e) => e.publishing_status)],
            ["edition", ...items.map((e) => e.edition)],
            ["condition", ...items.map((e) => `[${e.condition ?? ""}]`)],
            ["rabat", ...items.map((e) => (e.rabat ? e.rabat + "%" : ""))],
            [
                "created_at",
                ...items.map((e) => <DateTimeField dateTime={e.created_at} />),
            ],
            [
                "updated_at",
                ...items.map((e) => <DateTimeField dateTime={e.updated_at} />),
            ],
        ];
    }, [items]);
    return (
        <div className={"ob-listingviewavaweb"}>
            <InfoTable items={rows} />
        </div>
    );
};

export default ListingViewAvaWeb;
