import {LangMessagesType} from "../../interfaces/lang";

export default {
    sk: {
        "filebox.nofiles" : "Žiadne súbory na stiahnutie",
        "filebox.files": "Súborov na stiahnutie:",
        "filebox.file": "Súbor",
        "filebox.downloadfile": "Stiahnúť súbor",
        "filebox.markasdone": "Označiť ako uploadnuté",
    },
    en: {
        "filebox.nofiles" : "No files to download",
        "filebox.files": "Files to download:",
        "filebox.file": "File",
        "filebox.downloadfile": "Download file",
        "filebox.markasdone": "Mark as uploaded",
    }
} as LangMessagesType;
