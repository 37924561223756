import React, {FunctionComponent, memo} from 'react';

type OrderableFieldProps = {
    orderable: boolean | null,
};

export const OrderableField: FunctionComponent<OrderableFieldProps> = memo(({orderable}) => {
    let ret = "❓";
    if(orderable !== null) {
        ret = orderable === true ? "✔" : "❌";
    }

    return <span className="ob-orderablefiled">{ret}</span>;
});
