import React, {FunctionComponent, useContext} from 'react';
import {AppContext} from "../utils/appcontext";
import en from "svg-country-flags/svg/gb.svg";
import sk from "svg-country-flags/svg/sk.svg";
import "./topbar.scss";
import SkuSearchBox from "../components/skusearch/skusearch";

type Props = {};

export const Topbar: FunctionComponent<Props> = () => {
    const context = useContext(AppContext);
    console.log(context!.currentLang);
    return (
        <div className="topbar">
            <SkuSearchBox/>
            <div className="ob-langselector">
                <span>
                        <img
                            src={sk}
                            alt={"Slovak language"}
                            title={"Slovak language"}
                            className={context?.currentLang === "sk" ? "active" : ""}
                            onClick={() => {
                                if (context!.currentLang !== "sk") {
                                    context!.changeLang("sk");
                                }
                            }}
                        />
                </span>
                <span>
                    <img
                        src={en}
                        alt={"English language"}
                        title={"English language"}
                        className={context!.currentLang === "en" ? "active" : ""}
                        onClick={() => {
                            if (context!.currentLang !== "en") {
                                context!.changeLang("en");
                            }
                        }}
                    />
                </span>
            </div>
        </div>
    );
};
