import {PublishingStatusFiltersType, PubloshingStatusItem} from "../types";

const filterPublishingStatusItems = (items: PubloshingStatusItem[], filters: PublishingStatusFiltersType) => {
    let ret = items;
    const {source, search, orderableStarred, orderable} = filters;
    if(source !== "") {
        ret = ret.filter(e => e.source === source);
    }

    if(search !== "") {
        ret = ret.filter(e => e.publishing_status.toLowerCase().includes(search.trim().toLowerCase()));
    }

    if(orderable !== "") {
        const tmp: number | null = orderable === "NULL" ? null : parseInt(orderable);
        ret = ret.filter(e => e.orderable === tmp);
    }

    if(orderableStarred !== "") {
        const tmp: number | null = orderableStarred === "NULL" ? null : parseInt(orderableStarred);
        ret = ret.filter(e => e.orderable_starred === tmp);
    }

    return ret;
};

export default filterPublishingStatusItems;
