import React, {FunctionComponent, ReactNode} from 'react';
import {RequestStatus2, RequestStatusEnum} from "../../interfaces/request";
import LoadIndicator from "../loadindicator";
import ErrorMessage from "../errormessage/errormessage";
import "./page.scss";
import ActionMenu, {ActionMenuActionType} from "../actionmenu/actionmenu";

type Props = RequestStatus2 & {
    children: ReactNode,
    onRetry?: () => void,
    onClose?: () => void,
};

export const PageBody: FunctionComponent<Props> = ({requestStatus, errorMsg, children, onClose, onRetry}) => {
    if(requestStatus === null || requestStatus === RequestStatusEnum.ACTIVE) {
        return <LoadIndicator/>
    }

    if(requestStatus === RequestStatusEnum.FAIL && errorMsg) {
        return <ErrorMessage err={errorMsg} onRetry={onRetry} onClose={onClose}/>
    }

    return (
        <div className={"page__body"}>
            {errorMsg && (
                <ErrorMessage
                    err={errorMsg}
                    onRetry={onRetry}
                    onClose={onClose}
                />
            )}
            {children}
        </div>
    );
};

export type PageHeadingActionType = ActionMenuActionType;

type PageHeadingProps = {
    title: string,
    actions?: PageHeadingActionType[],
}

export const PageHeading: FunctionComponent<PageHeadingProps> = ({title, actions}) => {
    return(
        <div className="ob-pageheading">
            <h1>{title}</h1>
            {actions && (
                <ActionMenu actions={actions}/>
            )}
        </div>
    );

};
