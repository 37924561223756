import React, { Component, ContextType } from "react";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import { AppException } from "../../utils/exceptions";
import { AppContext } from "../../utils/appcontext";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { ActionMenuActionType } from "../../components/actionmenu/actionmenu";
import Pagination from "react-bootstrap/Pagination";
import { PageBody, PageHeading } from "../../components/page/page";
import Notification from "./components/notification";

import "./notifications.lang";
import "./notifications.page.scss";

const PAGE_LIMIT = 20;

type Props = WrappedComponentProps<"intl"> & {};

export type NotificationItem = {
    requestActive: boolean;
    errMsg: AppException | null;
    isExpanded: boolean;
    id: number;
    user_id: number;
    notification_type: number;
    read: number;
    payload: any;
    created_at: Date;
    updated_at: Date;
};

type State = RequestStatus2 & {
    items: NotificationItem[];
    page: number;
    hasNextPage: boolean;
    read: boolean;
};

class NotificationsPage extends Component<Props, State> {
    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;
    private actions: null | ActionMenuActionType[] = null;
    private locale: string = "";

    constructor(props: Props) {
        super(props);
        this.state = {
            read: false,
            page: 1,
            hasNextPage: false,
            items: [],
            requestStatus: null,
            errorMsg: null,
        };
    }

    componentDidMount(): void {
        if (this.state.requestStatus === null) {
            this.loadItems(this.state.page, this.state.read);
        }
    }

    private loadItems(page: number, read: boolean) {
        this.actions = null;
        this.setState((old) => ({
            ...old,
            requestStatus: RequestStatusEnum.ACTIVE,
            items: [],
            errorMsg: null,
            page,
            read,
            hasNextPage: false,
        }));
        this.context!.client!.notifications.getAll(page, read)
            .then((data) => {
                this.actions = null;
                this.setState({
                    requestStatus: RequestStatusEnum.SUCCESS,
                    items: data.map((item) => {
                        try {
                            return {
                                ...item,
                                payload: JSON.parse(item.payload),
                                requestActive: false,
                                errMsg: null,
                                created_at: new Date(item.created_at),
                                updated_at: new Date(item.updated_at),
                                isExpanded: false,
                            };
                        } catch (e: any) {
                            throw new AppException(
                                "Invalid payload in notification id " + item.id,
                                "AppException"
                            );
                        }
                    }),
                    hasNextPage: data.length >= PAGE_LIMIT,
                });
            })
            .catch((errorMsg) => {
                this.actions = null;
                this.setState({
                    requestStatus: RequestStatusEnum.FAIL,
                    errorMsg,
                });
            });
    }
    private reload = () => this.loadItems(this.state.page, this.state.read);
    private toggleRead = () => {
        this.loadItems(1, !this.state.read);
    };
    private createActions = () => {
        const { intl } = this.props;
        const { requestStatus, read } = this.state;
        const disabled = requestStatus === RequestStatusEnum.ACTIVE;
        let showLabel = intl.formatMessage({ id: "show" }) + " ";
        this.locale = intl.locale;
        this.actions = [
            {
                label: intl.formatMessage({ id: "reload" }),
                action: this.reload,
                disabled,
            },
            {
                label:
                    showLabel +
                    intl.formatMessage({
                        id: read
                            ? "notifications.unread"
                            : "notifications.read",
                    }),
                disabled,
                action: this.toggleRead,
            },
        ];
    };
    private toggleReadItem = (id: number) => {
        const item = this.state.items.filter((e) => e.id === id)[0];
        if (!item) {
            return;
        }

        const client = this.context!.client!;

        const action = item.read
            ? client.notifications.markAsUnread
            : client.notifications.markAsRead;
        this.setState((old) => ({
            ...old,
            items: old.items.map((e) => {
                if (e.id !== id) {
                    return e;
                }

                return { ...e, requestActive: true };
            }),
        }));
        action([id])
            .then(() => {
                this.setState((old) => ({
                    ...old,
                    items: old.items.map((e) => {
                        if (e.id !== id) {
                            return e;
                        }

                        return {
                            ...e,
                            requestActive: false,
                            read: e.read === 0 ? 1 : 0,
                            updated_at: new Date(),
                        };
                    }),
                }));
            })
            .catch((errMsg) => {
                this.setState((old) => ({
                    ...old,
                    items: old.items.map((e) => {
                        if (e.id !== id) {
                            return e;
                        }

                        return { ...e, requestActive: false, errMsg };
                    }),
                }));
            });
    };
    private toggleExpended = (id: number) => {
        this.setState((old) => ({
            ...old,
            items: old.items.map((e) => {
                if (e.id !== id) {
                    return e;
                }

                return { ...e, isExpanded: !e.isExpanded };
            }),
        }));
    };
    private renderPagination() {
        const { page, hasNextPage, read } = this.state;
        if (page === 1 && !hasNextPage) {
            return null;
        }

        return (
            <Pagination>
                <Pagination.Prev
                    onClick={() => this.loadItems(page - 1, read)}
                    disabled={page === 1}
                />
                <Pagination.Item active>{page}</Pagination.Item>
                <Pagination.Next
                    onClick={() => this.loadItems(page + 1, read)}
                    disabled={!hasNextPage}
                />
            </Pagination>
        );
    }

    render():
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        if (this.actions === null) {
            this.createActions();
        }
        const { requestStatus, errorMsg, read } = this.state;
        const { intl } = this.props;
        let title = intl.formatMessage({ id: "notifications" }) + ": ";
        title += intl.formatMessage({
            id: read ? "notifications.read" : "notifications.unread",
        });

        if (intl.locale !== this.locale) {
            this.createActions();
        }

        return (
            <div className="page">
                <PageHeading title={title} actions={this.actions!} />
                <PageBody
                    requestStatus={requestStatus}
                    errorMsg={errorMsg}
                    onRetry={this.reload}
                >
                    <div className="ob-notificationspage">
                        {this.state.items.map((e) => (
                            <Notification
                                key={e.id}
                                item={e}
                                toggleRead={this.toggleReadItem}
                                intl={this.props.intl}
                                toggleExpanded={this.toggleExpended}
                            />
                        ))}
                        {this.renderPagination()}
                    </div>
                </PageBody>
            </div>
        );
    }
}

export default injectIntl<"intl", Props>(NotificationsPage);
