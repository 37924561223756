import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { BalmerWebEntity } from "@ozibooks/server/dist/modules/balmer/entities/balmerweb.entity";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";

type Props = {
    items: BalmerWebEntity[];
};

const ListingViewBalmerWeb: FunctionComponent<Props> = ({ items }) => {
    const rows: ReactNode[][] = useMemo(() => {
        return [
            ["balmerid", ...items.map((e) => e.balmerid)],
            ["product_id", ...items.map((e) => e.product_id)],
            ["title", ...items.map((e) => e.title)],
            [
                "publish_date",
                ...items.map((e) => (
                    <DateTimeField dateTime={e.publish_date} />
                )),
            ],
            ["price", ...items.map((e) => e.price)],
            ["publisher", ...items.map((e) => e.publisher)],
            ["authors", ...items.map((e) => e.authors)],
            ["product_form", ...items.map((e) => e.product_form)],
            ["publishing_status", ...items.map((e) => e.publishing_status)],
            ["edition", ...items.map((e) => e.edition)],
            ["quantity", ...items.map((e) => e.quantity ?? "")],
            [
                "link",
                ...items.map((e) =>
                    e.link ? (
                        <a
                            referrerPolicy="no-referrer"
                            target="_blank"
                            rel="noreferrer"
                            href={e.link}
                        >
                            Click me
                        </a>
                    ) : (
                        ""
                    )
                ),
            ],

            [
                "created_at",
                ...items.map((e) => <DateTimeField dateTime={e.created_at} />),
            ],
            [
                "updated_at",
                ...items.map((e) => <DateTimeField dateTime={e.updated_at} />),
            ],
        ];
    }, [items]);
    return (
        <div className={"ob-listingviewbalmerweb"}>
            <InfoTable items={rows} />
        </div>
    );
};

export default ListingViewBalmerWeb;
