import React, { FunctionComponent, useCallback, useContext } from "react";
import { AppContext } from "../../utils/appcontext";
import { useParams } from "react-router";
import dtoToQuantityItems from "./utils/toitem";
import QuantityTable, { AmazonshopQuantityItem } from "./table";
import { FormattedMessage } from "react-intl";
import { MAX_QUANTITY_WITHOUT_STOCKS } from "../../interfaces/constants";
import { ActivationTable } from "./activation/activation";
import { ActivationTable2 } from "./activation/activation2";

type Props = {};

export const QuantityPage: FunctionComponent<Props> = () => {
    const context = useContext(AppContext);
    let { slug } = useParams<{ slug: string }>();

    if (!context) {
        throw new Error("Missing context");
    }
    const { client } = context;

    const loadZeroQuantity = useCallback(() => {
        return client.amazonshop.quantity.zero().then(dtoToQuantityItems);
    }, [client]);
    const loadHighQuantity = useCallback(() => {
        return client.amazonshop.quantity.high().then(dtoToQuantityItems);
    }, [client]);
    const loadLowQuantity = useCallback(() => {
        return client.amazonshop.quantity.low().then(dtoToQuantityItems);
    }, [client]);
    const loadCriticalQuantity = useCallback(() => {
        return client.amazonshop.quantity.critical().then(dtoToQuantityItems);
    }, [client]);
    const addToUpload = useCallback(
        async (data: AmazonshopQuantityItem[]): Promise<void> => {
            await client.upload.quantity.create(
                data.map((e) => ({
                    sku: e.az_sku,
                    quantity: e.new_quantity,
                }))
            );
        },
        [client]
    );

    const loadToDeactivate = useCallback(() => {
        return client.amazonshop.quantity.deactivable();
    }, [client]);
    const loadToActivate = useCallback(() => {
        return client.amazonshop.quantity.activable();
    }, [client]);
    const addToUploadDeactivate = useCallback(
        (skus: string[]) => {
            return client.upload.quantity.create(
                skus.map((e) => ({ sku: e, quantity: 0 }))
            );
        },
        [client]
    );

    const addToUploadActivate = useCallback(
        (skus: string[]) => {
            return client.upload.quantity.create(
                skus.map((e) => ({
                    sku: e,
                    quantity: MAX_QUANTITY_WITHOUT_STOCKS,
                }))
            );
        },
        [client]
    );

    let loadItems: () => Promise<AmazonshopQuantityItem[]>;
    let title: string;

    switch (slug) {
        case "zero":
            loadItems = loadZeroQuantity;
            title = "quantity.zero";
            break;
        case "high":
            loadItems = loadHighQuantity;
            title = "quantity.high";
            break;
        case "low":
            loadItems = loadLowQuantity;
            title = "quantity.low";
            break;
        case "critical":
            loadItems = loadCriticalQuantity;
            title = "quantity.critical";
            break;
        case "deactivate":
            return (
                <div className={"page"}>
                    <h3>
                        <FormattedMessage id={"quantity.todeactivate"} />
                    </h3>
                    <ActivationTable
                        key={"deactivate"}
                        loadItems={loadToDeactivate}
                        changeActivation={addToUploadDeactivate}
                        actionButtonLabel={"quantity.deactivate"}
                    />
                </div>
            );
        case "activate":
            return (
                <div className={"page"}>
                    <h3>
                        <FormattedMessage id={"quantity.toactivate"} />
                    </h3>
                    <ActivationTable2
                        key={"activate"}
                        loadItems={loadToActivate}
                        changeActivation={addToUploadActivate}
                        actionButtonLabel={"quantity.activate"}
                    />
                </div>
            );
        default:
            loadItems = loadHighQuantity;
            title = "quantity.high";
    }

    return (
        <div className={"page"}>
            <h3>
                <FormattedMessage id={title} />
            </h3>
            <QuantityTable
                key={slug}
                loadItems={loadItems}
                addToUpload={addToUpload}
            />
        </div>
    );
};
