import React, {FunctionComponent} from 'react';
import Spinner from "react-bootstrap/Spinner";
import "./uiblocker.scss";

type Props = {

};

const UIBlocker: FunctionComponent<Props> = () => {

    return (
        <div className={"ob-uiblocker"}>
            <div className="ob-uiblocker__inner">
                <Spinner animation={"border"} />
            </div>
        </div>
    );
};

export default UIBlocker;
