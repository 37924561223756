import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";

export type OrderableInfoType = {
    status: string | null;
    updated_at: string | Date | null;
    orderable: boolean | null;
    orderable_starred: boolean | null;
    nodata?: true;
    notOnWeb?: true;
    notChecked?: true;
};

export type BzOrderableInfoType = OrderableInfoType & {
    publishing_status: string | null;
};

const toOrderable = (info: null | number | undefined): boolean | null => {
    if (info === undefined || info === null) {
        return null;
    }

    return !!info;
};

export const bzToOrderableInfo = (ent: Amazonshop): BzOrderableInfoType[] => {
    if (!Array.isArray(ent.bz) || !ent.bz.length) {
        return [
            {
                publishing_status: null,
                status: null,
                updated_at: null,
                orderable: false,
                orderable_starred: false,
                nodata: true,
            },
        ];
    }

    return ent.bz.map((e) => ({
        publishing_status: e.publishing_status,
        status: e.publishing_status_note,
        updated_at: e.updated_at,
        orderable: toOrderable(
            e.mappedStatus ? e.mappedStatus.orderable : null
        ),
        orderable_starred: toOrderable(
            e.mappedStatus ? e.mappedStatus.orderable_starred : null
        ),
    }));
};

export const olfToOrderableInfo = (ent: Amazonshop): OrderableInfoType => {
    if (!ent.olf) {
        return {
            status: null,
            updated_at: null,
            orderable: false,
            orderable_starred: false,
            nodata: true,
        };
    }

    return {
        status: ent.olf.status,
        updated_at: ent.olf.updated_at,
        orderable: toOrderable(
            ent.olf.mappedStatus ? ent.olf.mappedStatus.orderable : null
        ),
        orderable_starred: toOrderable(
            ent.olf.mappedStatus ? ent.olf.mappedStatus.orderable_starred : null
        ),
    };
};

export const avaWebToOrderableInfo = (ent: Amazonshop): OrderableInfoType[] => {
    if (ent.webcheck && ent.webcheck.ava) {
        return [
            {
                status: null,
                updated_at: ent.webcheck.ava,
                orderable: false,
                orderable_starred: false,
                notOnWeb: true,
            },
        ];
    }

    if (!Array.isArray(ent.avaWeb) || !ent.avaWeb.length) {
        return [
            {
                status: null,
                updated_at: null,
                orderable: null,
                orderable_starred: null,
                notChecked: true,
            },
        ];
    }

    return ent.avaWeb.map((e) => ({
        status: e.publishing_status,
        updated_at: e.updated_at,
        orderable: toOrderable(
            e.mappedStatus ? e.mappedStatus.orderable : null
        ),
        orderable_starred: toOrderable(
            e.mappedStatus ? e.mappedStatus.orderable_starred : null
        ),
    }));
};

export const balmerWebToOrderableInfo = (
    ent: Amazonshop
): OrderableInfoType[] => {
    if (ent.webcheck && ent.webcheck.balmer) {
        return [
            {
                status: null,
                updated_at: ent.webcheck.balmer,
                orderable: false,
                orderable_starred: false,
                notOnWeb: true,
            },
        ];
    }

    if (!Array.isArray(ent.balmerWeb) || !ent.balmerWeb.length) {
        return [
            {
                status: null,
                updated_at: null,
                orderable: null,
                orderable_starred: null,
                notChecked: true,
            },
        ];
    }

    return ent.balmerWeb.map((e) => ({
        status: e.publishing_status,
        updated_at: e.updated_at,
        orderable: toOrderable(
            e.mappedStatus ? e.mappedStatus.orderable : null
        ),
        orderable_starred: toOrderable(
            e.mappedStatus ? e.mappedStatus.orderable_starred : null
        ),
    }));
};
