import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { BzEntity } from "@ozibooks/server/dist/modules/bz/entities/bz.entity";
import { IntlShape } from "react-intl";
import InfoTable from "../../../infotable/infotable";
import YesNoIcon from "../../../yesno/yesno";
import { DateTimeField } from "./DateTimeField";

type Props = {
    items: BzEntity[];
    intl: IntlShape;
};

const formatRabat = (n: unknown): string => {
    return typeof n === "number" ? n + "%" : "";
};

export const ListingViewBz: FunctionComponent<Props> = ({ items }) => {
    const rows: ReactNode[][] = useMemo(() => {
        return [
            ["bzid", ...items.map((e) => e.bzid)],
            ["title", ...items.map((e) => e.title)],
            ["product_id", ...items.map((e) => e.product_id)],
            ["product_id_type", ...items.map((e) => e.product_id_type)],
            ["wanted", ...items.map((e) => e.wanted)],
            ["price", ...items.map((e) => e.price)],
            ["discount", ...items.map((e) => formatRabat(e.rabat))],
            ["Beso", ...items.map((e) => (e.beso ? "Yes" : "No"))],
            ["availability", ...items.map((e) => e.availability)],
            ["publishing_status", ...items.map((e) => e.publishing_status)],
            [
                "publishing_status_note",
                ...items.map((e) => e.publishing_status_note),
            ],
            [
                "condition",
                ...items.map((e) =>
                    e.condition ? e.condition.split(";").join("; ") : ""
                ),
            ],
            [
                "replaced_by",
                ...items.map((e) => (e.replaced_by ? e.replaced_by : "")),
            ],
            [
                "restricted",
                ...items.map((e) => <YesNoIcon yesno={e.restricted} />),
            ],
            [
                "expected_ship_date",
                ...items.map((e) =>
                    e.expected_ship_date ? (
                        <DateTimeField dateTime={e.expected_ship_date} />
                    ) : (
                        ""
                    )
                ),
            ],
            ["min_order_quantity", ...items.map((e) => e.min_order_quantity)],
            ["product_form", ...items.map((e) => e.product_form)],
            ["publisher", ...items.map((e) => e.publisher)],
            ["warengruppe", ...items.map((e) => e.warengruppe)],
            ["Sync availability", ...items.map((e) => (e.sync ? "Yes" : "No"))],
            [
                "created_at",
                ...items.map((e) => <DateTimeField dateTime={e.created_at} />),
            ],
            [
                "updated_at",
                ...items.map((e) => <DateTimeField dateTime={e.updated_at} />),
            ],
        ];
    }, [items]);

    return (
        <div className={"ob-listingviewbz"}>
            <InfoTable items={rows} />
        </div>
    );
};
