import React, {FunctionComponent, useCallback} from 'react';
import useFocusRef from "../hooks/usefocusref";

type TextSlectCellProps = {
    isCellSelected: boolean,
    text: string,
};

const TextSlectCell: FunctionComponent<TextSlectCellProps> = ({isCellSelected, text}) => {
    const ref = useFocusRef<HTMLSpanElement>(isCellSelected);
    const onClick = useCallback((ev: React.MouseEvent<HTMLSpanElement>) => {
        if(isCellSelected) {
            ev.stopPropagation();
        }
    }, [isCellSelected]);

    return <span tabIndex={-1} ref={ref} onClick={onClick}>{text}</span>;
};

export default TextSlectCell;
