import {AppException} from "../exceptions";

export class ApiClientException extends AppException {}

export class UnknownClientException extends ApiClientException {
    constructor(message: string) {
        super(message, "UnknownClientException");
    }
}

export class NetworkException extends ApiClientException {
    readonly code?: string;
    constructor(code?: string) {
        super("Network error", "NetworkException", true);
    }
}

export class TimeOutException extends ApiClientException {

    constructor() {
        super("Timeout", "TimeOutException", true);
    }
}

// 401 Unauthorized
export class UnauthorizedException extends ApiClientException {
    constructor(message: string =  "Unauthorized") {
        super(message, "UnauthorizedException");
    }
}

// 403 Forbidden
export class ForbiddenException extends ApiClientException {
    constructor(message: string = "Forbidden") {
        super(message, "ForbiddenException");
    }
}

// 404
export class NotFoundException extends ApiClientException {
    constructor(message: string = "Not Found") {
        super(message, "NotFoundException");
    }
}

// 400 Bad Request
export class BadRequestException extends ApiClientException {
    constructor(message: string = "Bad Request") {
        super(message, "BadRequestException");
    }
}

// 500
export class ServerErrorException extends ApiClientException {
    constructor(message: string = "Server Error") {
        super(message, "SeverErrorException", true);
    }
}

export class HttpException extends ApiClientException {
    readonly statusCode?: number;

    constructor(message: string = "", statusCode?: number) {
        super(message, "HttpException");
        this.statusCode = statusCode;
    }
}

export class GatewayTimeoutException extends ApiClientException {
    constructor(message: string = "Gateway Timeout") {
        super(message, "GatewayTimeoutException", true);
    }
}
