import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { IntlShape } from "react-intl";
import { Olf } from "@ozibooks/server/dist/modules/olf/entities/olf.entity";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";

type Props = {
    item: Olf;
    intl: IntlShape;
};

const ListingViewOlf: FunctionComponent<Props> = ({ item }) => {
    const rows: ReactNode[][] = useMemo(() => {
        return [
            ["product_id", item.product_id],
            ["product_id_type", item.product_id_type],
            ["wanted", item.wanted],
            ["price", item.price],
            ["quantity", item.quantity],
            ["status", item.status],
            ["created_at", <DateTimeField dateTime={item.created_at} />],
            ["updated_at", <DateTimeField dateTime={item.updated_at} />],
        ];
    }, [item]);
    return (
        <div className={"ob-listingviewolf"}>
            <InfoTable items={rows} />
        </div>
    );
};

export default ListingViewOlf;
