import {LangMessagesType} from "../../../interfaces/lang";

const messages: LangMessagesType = {
    sk: {
        "server.address" : "Adresa serveru",
        "server.invalidFormat": "Nesprávny formát",
        "server.apikey": "API kľúč"
    },
    en: {
        "server.address" : "Server address",
        "server.invalidFormat": "Invalid format",
        "server.apikey": "API key",
    }
};

export default messages;
