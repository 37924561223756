import {AppException} from "../utils/exceptions";

export type RequestStaus = {
    requestActive: boolean;
    errorMsg: string | null;
}

export type RequestStatus2 = {
    requestStatus: null | RequestStatusEnum,
    errorMsg: AppException | null,
}

export enum RequestStatusEnum {
    ACTIVE = "ACTIVE",
    SUCCESS = "SUCCESS",
    FAIL = "FAIL",
}
