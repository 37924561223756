import React, {FunctionComponent, useMemo} from 'react';
import {PublishingStatusFiltersType} from "../../types";
import {useIntl} from "react-intl";
import {PublishingStatusFilterSelect} from "../select/filterselect";

type Props = {
    filters: PublishingStatusFiltersType,
    changeFilters: (filters: PublishingStatusFiltersType) => void,

};

const PublishingStatusTableHeader: FunctionComponent<Props> = ({filters, changeFilters}) => {
    const intl = useIntl();
    const sourceOptions = useMemo(() => {
        return [
            ["", intl.formatMessage({id: "all"})],
            ["balmer_web", "balmer_web"],
            ["ava_web", "ava_web"],
            ["olf", "olf"],
            ["bz", "bz"],
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, intl.locale]);
    const orderableOptions = useMemo(() => {
        return [
            ["", intl.formatMessage({id: "all"})],
            ["NULL", intl.formatMessage({id: "publishingstatus.notset"})],
            ["0", intl.formatMessage({id: "no"})],
            ["1", intl.formatMessage({id: "yes"})],
        ];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [intl, intl.locale]);
    return (
        <thead>
        <tr>
            <th colSpan={2} />
            <th colSpan={2}>{intl.formatMessage({id:"publishingstatus.orderable"})}?</th>
        </tr>
        <tr>
            <th>{intl.formatMessage({id:"publishingstatus.source"})}</th>
            <th>{intl.formatMessage({id:"publishingstatus.status"})}</th>
            <th>{intl.formatMessage({id:"publishingstatus.normalprefix"})}</th>
            <th>{intl.formatMessage({id:"publishingstatus.starredprefix"})}</th>
        </tr>
        <tr>
            <th><PublishingStatusFilterSelect
                value={filters.source}
                options={sourceOptions}
                onChange={ev => {
                    const val = ev.target.value;
                    changeFilters({...filters, source: val});
                }}/>
            </th>
            <th>
                <input
                    type={"search"}
                    className={"form-control form-control-sm"}
                    value={filters.search}
                    onChange={ev => {
                        const {value} = ev.target;
                        changeFilters({...filters, search: value})
                    }}
                />
            </th>
            <th>
                <PublishingStatusFilterSelect
                    value={filters.orderable}
                    options={orderableOptions}
                    onChange={ev => {
                        const {value} = ev.target;
                        changeFilters({...filters, orderable: value});
                    }}
                />
            </th>
            <th>
                <PublishingStatusFilterSelect
                    value={filters.orderableStarred}
                    options={orderableOptions}
                    onChange={ev => {
                        const {value} = ev.target;
                        changeFilters({...filters, orderableStarred: value});
                    }}
                />
            </th>
        </tr>
        </thead>
    );
};

export default PublishingStatusTableHeader;
