import React from 'react';
import ReactDOM from 'react-dom';
import {App} from './layout/app';
import * as serviceWorker from './serviceWorker';
import "./scss/styles.scss";
import {AppStorage} from "./utils/storage/appstorage";
import {LocalStorageAdapter} from "./utils/storage/localstorage";
import axios from "axios";
import {ApiClient} from "./utils/apiclient/apiclient";

(async () => {
    const ax = axios.create();
    const apiclient = new ApiClient(ax);
    const appStorage = new AppStorage(new LocalStorageAdapter());

    const auth = await appStorage.getAuth();
    if(auth) {
        apiclient.setAuth(auth);
    }

    let initalLang: string = "en";
    const storedLocale = await appStorage.getLang();
    if(storedLocale !== null) {
        initalLang = storedLocale;
    }

    // @ts-ignore
    window.OBCient = apiclient;

    ReactDOM.render(
        <React.StrictMode>
                    <App client={apiclient} appStorage={appStorage} inialLang={initalLang}/>
        </React.StrictMode>,
        document.getElementById('root')
    );
})();


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
