import React, {FunctionComponent, useMemo, useState} from 'react';
import {ISBNData} from "isbn3";
import {parseIsbn} from "../../../utils/product/isbn";
import InfoTable from "../../../components/infotable/infotable";

import "./singleisbn.scss";
import {IntlShape} from "react-intl";

type Props = {
    intl: IntlShape,
};

const SingleIsbnForm: FunctionComponent<Props> = ({intl}) => {
    const [text, setText] = useState<string>("");
    const data: ISBNData | null | false = useMemo(() => {
        if(text === "") {
            return null;
        }

        const data = parseIsbn(text);
        if(data === null) {
            return false;
        }

        return data;
    }, [text]);

    return (
        <div className={"ob-singleisbnform"}>
            <div className="form-group">
                <input
                    className={"form-control"}
                    type={"search"}
                    value={text}
                    onChange={ev => {
                        const {value} = ev.target;
                        setText(value);
                    }}
                    placeholder={intl.formatMessage({id: "isbntools.insertisbn"})}
                />
            </div>
            <div className="ob-singleisbnform__result">
                {data === false && (
                    <div className="alert alert-danger">
                        {intl.formatMessage({id: "isbn.invalid"})}
                    </div>
                )}
                {!!data && (
                    <InfoTable items={[
                        ["ISBN10", data.isbn10],
                        ["ISBN-10", data.isbn10h],
                        ["ISBN13", data.isbn13],
                        ["ISBN-13", data.isbn13h],
                        ["Group name", data.groupname],
                        ["Group", data.group],
                        ["Publisher", data.publisher],
                        ["Article", data.article],
                        ["Check", data.check],
                    ]}/>
                )}
            </div>

        </div>
    );
};

export default SingleIsbnForm;
