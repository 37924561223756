
export class AppException extends Error {
    readonly name: string;
    readonly recoverable: boolean;
    debugData?: string;

    constructor(message: string, name: string, recoverable: boolean = false) {
        super(message);

        this.recoverable = recoverable;
        this.name = name;
    }
}
