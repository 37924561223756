import React, {
    FunctionComponent,
    useCallback,
    useMemo,
    useState,
} from "react";
import { TagEntity } from "@ozibooks/server/dist/modules/tags/entities/tag.entity";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons/faPlus";
import Dropdown from "react-bootstrap/cjs/Dropdown";
import { RequestStatusEnum } from "../../interfaces/request";

import "./tagscontainer.scss";
import UIBlocker from "../uiblocker";
import { TagDeletable } from "../tag/tagdeletable";
import { useIntl } from "react-intl";

type Props = {
    selectedTags: TagEntity[];
    allTags: TagEntity[];
    tagsRequest: RequestStatusEnum | null;
    addTag: (id: number) => Promise<void>;
    deleteTag: (id: number) => Promise<void>;
};

type State = {
    isRequestActive: boolean;
};

export const TagsContainer: FunctionComponent<Props> = ({
    deleteTag,
    tagsRequest,
    selectedTags,
    allTags,
    addTag,
}) => {
    const intl = useIntl();
    const [state, setState] = useState<State>({
        isRequestActive: false,
    });
    const addTagLocal = useCallback(
        (id: number) => {
            setState((old) => ({ ...old, isRequestActive: true }));
            addTag(id)
                .then(() =>
                    setState((old) => ({ ...old, isRequestActive: false }))
                )
                .catch(() =>
                    setState((old) => ({ ...old, isRequestActive: false }))
                );
        },
        [addTag]
    );
    const tagsToAdd = useMemo(() => {
        return allTags.filter(
            (e) => !selectedTags.some((se) => se.id === e.id)
        );
    }, [selectedTags, allTags]);
    const deleteTagLocal = useCallback(
        (id: number) => {
            setState((old) => ({ ...old, isRequestActive: true }));
            deleteTag(id)
                .then(() =>
                    setState((old) => ({ ...old, isRequestActive: false }))
                )
                .catch(() =>
                    setState((old) => ({ ...old, isRequestActive: false }))
                );
        },
        [deleteTag]
    );
    let showBlocker = false;

    if (
        tagsRequest === null ||
        tagsRequest === RequestStatusEnum.ACTIVE ||
        state.isRequestActive
    ) {
        showBlocker = true;
    }

    return (
        <div className="ob-tagcontainer">
            {showBlocker && <UIBlocker />}
            {selectedTags.map((e) => (
                <TagDeletable
                    key={e.id}
                    color={e.color}
                    text={e.name}
                    onDelete={() => deleteTagLocal(e.id)}
                />
            ))}
            {tagsRequest === RequestStatusEnum.SUCCESS && tagsToAdd.length > 0 && (
                <Dropdown>
                    <Dropdown.Toggle
                        variant={"secondary"}
                        size={"sm"}
                        as={"span"}
                    >
                        <FontAwesomeIcon
                            icon={faPlus}
                            title={intl.formatMessage({ id: "tags.add" })}
                        />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        {tagsToAdd.map((e) => (
                            <Dropdown.Item
                                key={e.id}
                                eventKey={String(e.id)}
                                onClick={() => addTagLocal(e.id)}
                            >
                                {e.name}
                            </Dropdown.Item>
                        ))}
                    </Dropdown.Menu>
                </Dropdown>
            )}
        </div>
    );
};
