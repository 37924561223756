import {LangMessagesType} from "../../../interfaces/lang";


const messages: LangMessagesType = {
    sk: {
        "publishingstatus.source": "Zdroj",
        "publishingstatus.status": "Stav",
        "publishingstatus.orderable": "Dá sa objednať",
        "publishingstatus.notset": "Nenastavené",
        "publishingstatus.normalprefix": "Normálny prefix",
        "publishingstatus.starredprefix": "Hviezdičkovský prefix",
    },
    en: {
        "publishingstatus.source": "Source",
        "publishingstatus.status": "Status",
        "publishingstatus.orderable": "Orderable",
        "publishingstatus.notset": "Not set",
        "publishingstatus.normalprefix": "Normal prefix",
        "publishingstatus.starredprefix": "Starred prefix",
    },
};


export default messages;
