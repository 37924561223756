import errMessages from "./errormessage/errormessage.lang";
import {LangMessagesType} from "../interfaces/lang";
import listingViewMessages from "./listingview/listingview.lang";
import skuSearchMessages from "./skusearch/skusearch.lang";
import fileboxMessages from "./filebox/filebox.lang";

export default {
    sk: {
        ...errMessages.sk,
        ...listingViewMessages.sk,
        ...skuSearchMessages.sk,
        ...fileboxMessages.sk,
    },
    en: {
        ...errMessages.en,
        ...listingViewMessages.en,
        ...skuSearchMessages.en,
        ...fileboxMessages.en,
    }
} as LangMessagesType;
