import {LangMessagesType} from "../../interfaces/lang";
import {addLangMessages} from "../../lang/messages";

const messages: LangMessagesType = {
    sk: {
        "isbntools.insertisbn": "Vlož ISBN",
        "isbntools": "ISBN nástroje",
    },
    en: {
        "isbntools.insertisbn": "Insert ISBN",
    }
};

addLangMessages(messages);
