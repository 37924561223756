import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { IntlShape } from "react-intl";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";

type Props = {
    entity: Amazonshop;
    intl: IntlShape;
};

export const ListingViewListing: FunctionComponent<Props> = ({ entity }) => {
    const productIds: ReactNode[][] = useMemo(() => {
        return [
            [
                "ASIN",
                <a
                    rel="noopener noreferrer"
                    target={"_blank"}
                    href={"https://amazon.de/dp/" + entity.asin1}
                >
                    {entity.asin1}
                </a>,
            ],
            ["real_product_id", entity.real_product_id],
            ["product-id", entity["product-id"]],
            ["product-id-type", entity["product-id-type"]],
            ["listing-id", entity["listing-id"]],
        ];
    }, [entity]);
    const importantData: ReactNode[][] = useMemo(() => {
        const ret: ReactNode[][] = [
            ["item-note", entity["item-note"]],
            ["merchant-shipping-group", entity["merchant-shipping-group"]],
            ["Created at", <DateTimeField dateTime={entity.created_at} />],
            ["Updated at", <DateTimeField dateTime={entity.updated_at} />],
        ];
        if (entity["item-description"]) {
            ret.unshift(["item-description", entity["item-description"]]);
        }

        return [
            ["price", entity.price],
            ["quantity", entity.quantity],
            ["status", entity.status],
            ["item-condition", entity["item-condition"]],
            ["open-date", <DateTimeField dateTime={entity["open-date"]} />],
            ...ret,
        ];
    }, [entity]);

    return (
        <div className={"ob-listingview__listing"}>
            <h4>{entity["item-name"]}</h4>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <InfoTable items={importantData} />
                    </div>
                    <div className="col">
                        <InfoTable items={productIds} />
                    </div>
                </div>
            </div>
        </div>
    );
};
