import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import React, {FunctionComponent} from 'react';
import {faCheck} from "@fortawesome/free-solid-svg-icons/faCheck";
import {faTimes} from "@fortawesome/free-solid-svg-icons/faTimes";
import "./yesno.scss";

type Props = {
    yesno: any,
};

const YesNoIcon: FunctionComponent<Props> = ({yesno}) => {
    if (yesno) {
        return (
            <span className="yesno yes"><FontAwesomeIcon icon={faCheck}/></span>
        );
    }

    return (
        <span className="yesno no"><FontAwesomeIcon icon={faTimes}/></span>
    );

};

export default YesNoIcon;
