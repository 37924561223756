import React, {FunctionComponent, useCallback} from 'react';

import "./isbnpublisherfilters.scss";
import {IsbnPublisherFiltersType} from "../types";
import {useIntl} from "react-intl";

type Props = {
    onFilterChange: (key: keyof IsbnPublisherFiltersType, val: string) => void,
    filters: IsbnPublisherFiltersType,
    onSubmit: (ev: React.FormEvent) => void,
};

export const IsbnPublisherFilters: FunctionComponent<Props> = ({onFilterChange, onSubmit, filters}) => {
    const intl = useIntl();
    const onChange = useCallback((ev: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {
        if (!ev.currentTarget.dataset.statekey) {
            return;
        }

        const key = ev.currentTarget.dataset.statekey as keyof IsbnPublisherFiltersType;
        const val = ev.target.value;
        onFilterChange(key, val);

    }, [onFilterChange]);

    return (
        <form
            onSubmit={onSubmit}
            className={"ob-isbnpublisherfilters__form"}
        >
            <div className="form-row">
                <div className="form-group col">
                    <label>{intl.formatMessage({id: "name"})}/Prefix</label>
                    <input onChange={onChange} data-statekey={"name"} value={filters.name} type="text"
                           className={"form-control"}/>
                </div>
                <div className="form-group col">
                    <label>{intl.formatMessage({id: "isbnprefix.ignored"})}</label>
                    <select onChange={onChange} data-statekey={"ignored"} className={"form-control"}
                            value={filters.ignored}>
                        <option value="">{intl.formatMessage({id: "publishingstatus.notset"})}</option>
                        <option value="0">{intl.formatMessage({id: "no"})}</option>
                        <option value="1">{intl.formatMessage({id: "yes"})}</option>
                    </select>
                </div>
                <div className="form-group col">
                    <label>{intl.formatMessage({id: "isbnprefix.usedonly"})}</label>
                    <select onChange={onChange} data-statekey={"used_only"} className={"form-control"}
                            value={filters.used_only}>
                        <option value="">{intl.formatMessage({id: "publishingstatus.notset"})}</option>
                        <option value="0">{intl.formatMessage({id: "no"})}</option>
                        <option value="1">{intl.formatMessage({id: "yes"})}</option>
                    </select>
                </div>
                <div className="form-group col">
                    <label>{intl.formatMessage({id:"category"})}</label>
                    <select onChange={onChange} data-statekey={"category"} className={"form-control"}
                            value={filters.category}>
                        <option value={""}>{intl.formatMessage({id: "publishingstatus.notset"})}</option>
                        <option value={"*"}>{intl.formatMessage({id: "isbnprefix.anycategory"})}</option>
                        <option value={"SL"}>SL</option>
                        <option value={"S"}>S</option>
                        <option value={"N"}>N</option>
                        <option value={"NN"}>NN</option>
                        <option value={"CH"}>CH</option>
                        <option value={"CH+"}>CH+</option>
                    </select>
                </div>
                <div className="form-group col submit">
                    <button className={"btn btn-secondary"} type={"submit"}>{intl.formatMessage({id: "search"})}</button>
                </div>
            </div>
        </form>
    );
};
