import React, { FunctionComponent, useState } from "react";
import { IsbnPublisherItem } from "./types";
import UpdateIsbnPublisherDto from "@ozibooks/server/dist/modules/isbnprefix/dto/updateisbnpublisher.dto";
import FormControl from "react-bootstrap/FormControl";
import ButtonGroup from "react-bootstrap/ButtonGroup";
import Button from "react-bootstrap/Button";
import { isIsbnPUblisherCategory } from "@ozibooks/server/dist/modules/isbnprefix/types/category";
import { RequestStatusEnum } from "../../interfaces/request";
import UIBlocker from "../uiblocker";
import ErrorMessage from "../errormessage/errormessage";
import { FormattedMessage, useIntl } from "react-intl";

export type IsbnPublisherListEditProps = {
    item: IsbnPublisherItem;
    toggleEdit: (ev: React.MouseEvent<HTMLButtonElement>) => void;
    submitChange: (data: UpdateIsbnPublisherDto) => void;
    resetRequstStatus: (id: number) => void;
};

type Comp = FunctionComponent<IsbnPublisherListEditProps>;

export const IsbnPublisherListEdit: Comp = ({
    item,
    resetRequstStatus,
    toggleEdit,
    submitChange,
}) => {
    const intl = useIntl();
    const [state, setState] = useState<UpdateIsbnPublisherDto>({
        category: item.entity.category,
        ignored: item.entity.ignored,
        used_only: item.entity.used_only,
        id: item.entity.id,
    });

    const disabled =
        item.entity.category === state.category &&
        item.entity.ignored === state.ignored &&
        item.entity.used_only === state.used_only;

    return (
        <div className={"ob-isbnpublisheritem edit"}>
            {item.requestStatus === RequestStatusEnum.ACTIVE && <UIBlocker />}
            <h4>{item.entity.name}</h4>
            {item.errorMsg && (
                <ErrorMessage
                    err={item.errorMsg}
                    onClose={() => resetRequstStatus(item.entity.id)}
                />
            )}
            <div className="ob-isbnpublisheritem__infoblock edit">
                <div>
                    <table className="table table-borderless">
                        <tbody>
                            <tr key={"category"}>
                                <th scope={"row"}>
                                    <FormattedMessage id={"category"} />
                                </th>
                                <td>
                                    <FormControl
                                        value={
                                            state.category === null
                                                ? ""
                                                : state.category
                                        }
                                        as={"select"}
                                        size={"sm"}
                                        onChange={(ev) => {
                                            const val = ev.target.value;
                                            if (val === "") {
                                                setState((old) => ({
                                                    ...old,
                                                    category: null,
                                                }));
                                            } else if (
                                                isIsbnPUblisherCategory(val)
                                            ) {
                                                setState((old) => ({
                                                    ...old,
                                                    category: val,
                                                }));
                                            }
                                        }}
                                    >
                                        <option value={""}>
                                            {intl.formatMessage({
                                                id: "isbnprefix.nocategory",
                                            })}
                                        </option>
                                        <option value={"SL"}>SL</option>
                                        <option value={"S"}>S</option>
                                        <option value={"N"}>N</option>
                                        <option value={"NN"}>NN</option>
                                        <option value={"CH"}>CH</option>
                                        <option value={"CH+"}>CH+</option>
                                    </FormControl>
                                </td>
                            </tr>
                            <tr key={"ignored"}>
                                <th scope={"row"}>
                                    <FormattedMessage
                                        id={"isbnprefix.ignored"}
                                    />
                                    ?
                                </th>
                                <td>
                                    <ButtonGroup>
                                        <Button
                                            variant={
                                                state.ignored === 1
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            onClick={() =>
                                                setState((old) => ({
                                                    ...old,
                                                    ignored: 1,
                                                }))
                                            }
                                            size={"sm"}
                                        >
                                            <FormattedMessage id={"yes"} />
                                        </Button>
                                        <Button
                                            variant={
                                                state.ignored === 0
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            onClick={() =>
                                                setState((old) => ({
                                                    ...old,
                                                    ignored: 0,
                                                }))
                                            }
                                            size={"sm"}
                                        >
                                            <FormattedMessage id={"no"} />
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                            <tr key={"usedonly"}>
                                <th scope={"row"}>
                                    <FormattedMessage
                                        id={"isbnprefix.usedonly"}
                                    />
                                    ?
                                </th>
                                <td>
                                    <ButtonGroup>
                                        <Button
                                            variant={
                                                state.used_only === 1
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            onClick={() =>
                                                setState((old) => ({
                                                    ...old,
                                                    used_only: 1,
                                                }))
                                            }
                                            size={"sm"}
                                        >
                                            <FormattedMessage id={"yes"} />
                                        </Button>
                                        <Button
                                            variant={
                                                state.used_only === 0
                                                    ? "primary"
                                                    : "secondary"
                                            }
                                            onClick={() =>
                                                setState((old) => ({
                                                    ...old,
                                                    used_only: 0,
                                                }))
                                            }
                                            size={"sm"}
                                        >
                                            <FormattedMessage id={"no"} />
                                        </Button>
                                    </ButtonGroup>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            <div className="ob-isbnpublisheritem__buttonbar--edit">
                <Button
                    disabled={disabled}
                    variant={"success"}
                    onClick={() => submitChange(state)}
                >
                    <FormattedMessage id={"save"} />
                </Button>
                <Button
                    data-id={item.entity.id}
                    variant={"secondary"}
                    onClick={toggleEdit}
                >
                    <FormattedMessage id={"cancel"} />
                </Button>
            </div>
        </div>
    );
};
