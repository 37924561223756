import React, { FunctionComponent, ReactNode, useMemo } from "react";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { IntlShape } from "react-intl";
import YesNoIcon from "../../../yesno/yesno";
import { DateTimeField } from "./DateTimeField";
import InfoTable from "../../../infotable/infotable";
import { RequestStatusEnum } from "../../../../interfaces/request";
import { propTypes } from "react-bootstrap/esm/Image";
import IconUpdate from "../../../icons/update";

type Props = {
    item: Amazonshop;
    intl: IntlShape;
    competitiveRequest: RequestStatusEnum | null;
    lowestRequest: RequestStatusEnum | null;
    updateCompetitive: (asin: string) => void;
    updateLowest: (asin: string) => void;
};

const ListingViewAmazondata: FunctionComponent<Props> = ({
    intl,
    item,
    competitiveRequest,
    updateCompetitive,
    lowestRequest,
    updateLowest,
}) => {
    const buyboxRows: ReactNode[][] = useMemo(() => {
        const { amazonCompetitive } = item;
        if (!amazonCompetitive) {
            return [];
        }

        return [
            ["asin", amazonCompetitive.asin],
            ["offers_new", amazonCompetitive.offers_new],
            ["offers_used", amazonCompetitive.offers_used],
            ["offers_total", amazonCompetitive.offers_total],
            ["price", amazonCompetitive.price],
            ["shipping", amazonCompetitive.shipping],
            ["salesrank", amazonCompetitive.salesrank],
            ["has_buybox", <YesNoIcon yesno={amazonCompetitive.has_buybox} />],
            [
                "created_at",
                <DateTimeField dateTime={amazonCompetitive.created_at} />,
            ],
            [
                "updated_at",
                <DateTimeField dateTime={amazonCompetitive.updated_at} />,
            ],
        ];
    }, [item]);
    const lowestRows: ReactNode[][] = useMemo(() => {
        const { amazonLowest } = item;
        if (!amazonLowest) {
            return [];
        }

        return [
            ["asin", amazonLowest.asin],
            ["new_price", amazonLowest.new_price],
            ["new_shipping", amazonLowest.new_shipping],
            ["new_fba", <YesNoIcon yesno={amazonLowest.new_fba} />],
            ["used_price", amazonLowest.used_price],
            ["used_shipping", amazonLowest.used_shipping],
            ["used_fba", <YesNoIcon yesno={amazonLowest.used_fba} />],
            [
                "created_at",
                <DateTimeField dateTime={amazonLowest.created_at} />,
            ],
            [
                "updated_at",
                <DateTimeField dateTime={amazonLowest.updated_at} />,
            ],
        ];
    }, [item]);
    if (buyboxRows.length + lowestRows.length === 0) {
        return (
            <div className={"ob-listingviewamazondata"}>
                {intl.formatMessage({ id: "nodata" })}
            </div>
        );
    }
    const competitiveIcon: () => ReactNode = () => {
        if (competitiveRequest === RequestStatusEnum.ACTIVE) {
            return <IconUpdate spin={true} title="Updating..." />;
        }

        return (
            <IconUpdate
                title="Update"
                style={{ cursor: "pointer" }}
                onClick={() => updateCompetitive(item.asin1)}
            />
        );
    };

    const lowestIcon: () => ReactNode = () => {
        if (lowestRequest === RequestStatusEnum.ACTIVE) {
            return <IconUpdate spin={true} title="Updating..." />;
        }

        return (
            <IconUpdate
                title="Update"
                style={{ cursor: "pointer" }}
                onClick={() => updateLowest(item.asin1)}
            />
        );
    };

    return (
        <div className={"ob-listingviewamazondata"}>
            <div className="container-fluid">
                <div className="row">
                    <div className="col">
                        <h5>Competitive pricing {competitiveIcon()}</h5>
                        {!!buyboxRows.length ? (
                            <InfoTable items={buyboxRows} />
                        ) : (
                            intl.formatMessage({ id: "nodata" })
                        )}
                    </div>
                    <div className="col">
                        <h5>Lowest offers {lowestIcon()}</h5>
                        {!!lowestRows.length ? (
                            <InfoTable items={lowestRows} />
                        ) : (
                            intl.formatMessage({ id: "nodata" })
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ListingViewAmazondata;
