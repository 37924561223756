import React, { FunctionComponent } from "react";
import SearchBox from "../../../components/productwithtextlist/components/searchbox";
import "./searchbar.css";

type Props = {
    search: string;
    trashVisible: boolean;
    setSearch: (str: string) => void;
    setCreating: (val: boolean) => void;
    loadItems: () => void;
    setTrashVisible: (val: boolean) => void;
};

export const NoteSearchBar: FunctionComponent<Props> = ({
    search,
    setSearch,
    setCreating,
    loadItems,
    trashVisible,
    setTrashVisible,
}) => {
    return (
        <div className="ob_note__searchbar">
            <div className="input-group">
                <SearchBox
                    key={search}
                    initialText={search}
                    onSubmit={setSearch}
                ></SearchBox>
            </div>
            <button
                className="btn btn-success"
                type="button"
                onClick={() => setCreating(true)}
            >
                Nová poznámka
            </button>
            <button
                className="btn btn-secondary"
                type="button"
                onClick={() => loadItems()}
            >
                Obnoviť
            </button>
            <button
                className="btn btn-primary"
                type="button"
                onClick={() => setTrashVisible(!trashVisible)}
            >
                {trashVisible ? "Poznámky" : "Kôš"}
            </button>
        </div>
    );
};
