import React, {FunctionComponent} from 'react';
import {OrderableInfoType} from "../../../../utils/helpers/orderable";
import {OrderableField} from "../../../../components/listingview/detail/components/orderable";
import {DateTimeField} from "../../../../components/listingview/detail/components/DateTimeField";
import {FormattedMessage} from "react-intl";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";

type Props = {
    info: OrderableInfoType[],
    columnKey: keyof OrderableInfoType,
    prefix: string,
    starred: boolean,
};

export const AvaColumnsFormatter: FunctionComponent<Props> = ({prefix,columnKey, info, starred}) => {
    if(columnKey === "orderable") {
        return <OrderableField orderable={starred ? info[0].orderable_starred : info[0].orderable}/>
    }

    if(columnKey === "updated_at") {
        return <DateTimeField dateTime={info[0].updated_at}/>;
    }

    if(info.length === 1) {
        if(info[0].status !== null) {
            return <>{info[0][columnKey]}</>;
        }

        return info[0].updated_at === null ?
            <FormattedMessage id={"listingview.notchecked"} /> :
            <FormattedMessage id={"listingview.notonweb"} />
    }

    return (
        <div className="ob-rgb-cellwithpopup">
            <span>{info[0][columnKey]}</span>
            <OverlayTrigger
                trigger="hover"
                placement="auto"
                overlay={(
                    <Popover id={"ob-rdg__popover"}>
                        <table className="table table-bordered">
                            <thead>
                            <tr>
                                <th>{prefix} <FormattedMessage id={"publishingstatus.status"} /></th>
                                <th>{prefix} <FormattedMessage id={"updated_at"} /></th>
                                <th>{prefix} <FormattedMessage id={"publishingstatus.orderable"} />?</th>
                            </tr>
                            </thead>
                            <tbody>
                            {info.map(e => (
                                <tr>
                                    <td>{e.status}</td>
                                    <td><DateTimeField dateTime={e.updated_at}/></td>
                                    <td><OrderableField orderable={e.orderable}/></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </Popover>
                )}
            >
                <span>⚠</span>
            </OverlayTrigger>
        </div>
    );
};
