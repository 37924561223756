import React, { FunctionComponent } from "react";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IsbnPublisherEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnpublisher.entity";
import Popover from "react-bootstrap/Popover";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons/faInfoCircle";
import YesNoIcon from "../../yesno/yesno";
import { FormattedMessage } from "react-intl";
import IconFullStar from "../../icons/fullstar";

type WithIsbnPrefix = any & {
    isbnPrefix: IsbnPrefixEntity | null | undefined;
};

type Props = {
    row: WithIsbnPrefix;
};

const PrefixFormatter: FunctionComponent<Props> = ({ row }) => {
    if (row.isbnPrefix === null || row.isbnPrefix === undefined) {
        return <></>;
    }

    const cats = new Set<string>();
    row.isbnPrefix.publishers?.forEach((e: IsbnPublisherEntity) => {
        if (e.category) {
            cats.add(e.category as string);
        }
    });

    return (
        <div className={"ob-rgb-cellwithpopup"}>
            <span>
                {row.isbnPrefix.starred === 1 && (
                    <IconFullStar isActive={true} />
                )}
            </span>
            {Array.isArray(row.isbnPrefix.publishers) &&
                !!row.isbnPrefix.publishers.length && (
                    <OverlayTrigger
                        trigger={"hover"}
                        placement={"auto"}
                        overlay={
                            <Popover id={"ob-rdg__popover"}>
                                <Popover.Title as={"h3"}>
                                    <FormattedMessage
                                        id={"isbnprefix.publishersforprefix"}
                                    />{" "}
                                    {row.isbnPrefix.prefix13}
                                </Popover.Title>
                                <table className="table table-bordered">
                                    <thead>
                                        <tr>
                                            <th key={"1"}>
                                                <FormattedMessage
                                                    id={"category"}
                                                />
                                            </th>
                                            <th key={"2"}>
                                                <FormattedMessage
                                                    id={"publisher"}
                                                />
                                            </th>
                                            <th key={"3"}>
                                                <FormattedMessage
                                                    id={"isbnprefix.ignored"}
                                                />
                                                ?
                                            </th>
                                            <th key={"4"}>
                                                <FormattedMessage
                                                    id={"isbnprefix.usedonly"}
                                                />
                                                ?
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {row.isbnPrefix.publishers.map(
                                            (e: IsbnPublisherEntity) => (
                                                <tr key={e.uuid}>
                                                    <td key={"1"}>
                                                        {e.category}
                                                    </td>
                                                    <td key={"2"}>{e.name}</td>
                                                    <td key={"3"}>
                                                        <YesNoIcon
                                                            yesno={e.ignored}
                                                        />
                                                    </td>
                                                    <td key={"4"}>
                                                        <YesNoIcon
                                                            yesno={e.used_only}
                                                        />
                                                    </td>
                                                </tr>
                                            )
                                        )}
                                    </tbody>
                                </table>
                            </Popover>
                        }
                    >
                        <span style={{ color: "var(--gray)" }}>
                            <FontAwesomeIcon icon={faInfoCircle} />
                        </span>
                    </OverlayTrigger>
                )}
        </div>
    );
};

export default PrefixFormatter;
