import React, {FunctionComponent, useCallback, useContext} from 'react';
import {AppContext} from "../../utils/appcontext";
import {FormattedMessage} from "react-intl";
import UploadQuantityTable from "./table/table";

type UploadQuantityPageProps = {

};

export const UploadQuantityPage: FunctionComponent<UploadQuantityPageProps> = () => {
    const context = useContext(AppContext);

    if(!context) {
        throw new Error("Missing context");
    }
    const {client} = context;
    const loadItems = useCallback(async () => {
        return await client.upload.quantity.getAll();
    }, [client]);
    const deleteItems = useCallback((skus: string[]) => {
        return client.upload.quantity.delete(skus);
    }, [client]);
    const generateUpload = useCallback(async () => {
        return await client.upload.quantity.generate();
    }, [client]);

    return (
        <div className={"page"}>
            <h3><FormattedMessage id={"upload.quantity"} /></h3>
            <UploadQuantityTable
                loadItems={loadItems}
                deleteItems={deleteItems}
                generateUpload={generateUpload}
            />
        </div>
    );
};
