import React, { FunctionComponent } from "react";
import { NoteEntity } from "@ozibooks/server/dist/modules/notes/note.entity";
import { NoteComponent } from "./note";

type NotesProps = {
    notes: NoteEntity[];
    setEditing: (val: NoteEntity) => void;
    deleteNote: (val: NoteEntity) => void;
    trashVisible: boolean;
    undeleteNote: (val: NoteEntity) => void;
};

export const NotesComponent: FunctionComponent<NotesProps> = ({
    notes,
    setEditing,
    deleteNote,
    trashVisible,
    undeleteNote,
}) => {
    if (!notes.length) {
        return <div>Žiadne poznámky</div>;
    }

    return (
        <div className="ob_notes">
            {notes.map((e) => (
                <NoteComponent
                    key={e.id}
                    entity={e}
                    setEditing={setEditing}
                    deleteNote={deleteNote}
                    trashVisible={trashVisible}
                    undeleteNote={undeleteNote}
                />
            ))}
        </div>
    );
};
