import React, { Component } from "react";
import { IsbnPublisherEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnpublisher.entity";
import { ApiClient } from "../../utils/apiclient/apiclient";
import { IsbnPublisherItem } from "./types";
import { toIsbnPublisherItem } from "./helpers";
import { IsbnPublisherListItem } from "./item";
import UpdateIsbnPublisherDto from "@ozibooks/server/dist/modules/isbnprefix/dto/updateisbnpublisher.dto";
import { RequestStatusEnum } from "../../interfaces/request";
import { IsbnPublisherListEdit } from "./edit";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";

type IsbnPublisherContainerProps = {
    publishers: IsbnPublisherEntity[];
    client: ApiClient;
    OnEntityUpdate?: (entity: IsbnPublisherEntity) => void;
};

type IsbnPublisherContainerState = {
    publishers: IsbnPublisherItem[];
};

class IsbnPublisherContainer extends Component<
    IsbnPublisherContainerProps,
    IsbnPublisherContainerState
> {
    constructor(props: IsbnPublisherContainerProps) {
        super(props);
        this.state = {
            publishers: props.publishers.map(toIsbnPublisherItem),
        };
    }

    toggleEdit = (ev: React.MouseEvent<HTMLButtonElement>) => {
        if (!ev.currentTarget.dataset.id) {
            return;
        }
        const id = parseInt(ev.currentTarget.dataset.id);
        this.setState((old) => ({
            ...old,
            publishers: old.publishers.map((e) =>
                e.entity.id !== id ? e : { ...e, isEdited: !e.isEdited }
            ),
        }));
    };

    resetRequstStatus = (id: number) => {
        this.setState((old) => ({
            ...old,
            publishers: old.publishers.map((e) =>
                e.entity.id !== id
                    ? e
                    : {
                          ...e,
                          requestStatus: null,
                          errorMsg: null,
                      }
            ),
        }));
    };

    toggleExpand = (ev: React.MouseEvent<HTMLButtonElement>) => {
        if (!ev.currentTarget.dataset.id) {
            return;
        }
        const id = parseInt(ev.currentTarget.dataset.id);

        this.setState((old) => ({
            ...old,
            publishers: old.publishers.map((e) =>
                e.entity.id !== id
                    ? e
                    : {
                          ...e,
                          isExpanded: !e.isExpanded,
                      }
            ),
        }));
    };

    updateEntity = async (data: UpdateIsbnPublisherDto) => {
        const id = data.id;
        this.setState((old) => ({
            ...old,
            publishers: old.publishers.map((e) => {
                if (e.entity.id !== id) {
                    return e;
                }

                return {
                    ...e,
                    requestStatus: RequestStatusEnum.ACTIVE,
                    errorMsg: null,
                };
            }),
        }));

        try {
            const newEnt = await this.props.client.isbnPrefix.updatePublisher(
                data
            );
            this.setState((old) => ({
                ...old,
                publishers: old.publishers.map((e) => {
                    if (e.entity.id !== id) {
                        return e;
                    }

                    if (this.props.OnEntityUpdate) {
                        setTimeout(() => {
                            this.props.OnEntityUpdate &&
                                this.props.OnEntityUpdate({
                                    ...e.entity,
                                    ...data,
                                });
                        }, 0);
                    }

                    return {
                        ...e,
                        requestStatus: RequestStatusEnum.SUCCESS,
                        isEdited: false,
                        entity: { ...e.entity, ...data },
                    };
                }),
            }));
            this.props.OnEntityUpdate && this.props.OnEntityUpdate(newEnt);
        } catch (err: any) {
            this.setState((old) => ({
                ...old,
                publishers: old.publishers.map((e) => {
                    if (e.entity.id !== id) {
                        return e;
                    }

                    return {
                        ...e,
                        requestStatus: RequestStatusEnum.FAIL,
                        errorMsg: err,
                    };
                }),
            }));
        }
    };

    setChangedPrefix(prefix: IsbnPrefixEntity) {
        this.setState((old) => ({
            ...old,
            publishers: old.publishers.map((pub) => ({
                ...pub,
                entity: {
                    ...pub.entity,
                    prefixes: pub.entity.prefixes?.map((pubpref) => {
                        if (pubpref.prefix13 !== prefix.prefix13) {
                            return pubpref;
                        }

                        return prefix;
                    }),
                },
            })),
        }));
    }

    render() {
        return (
            <div className="ob-isbnpublisherlist">
                {this.state.publishers.map((e) =>
                    e.isEdited ? (
                        <IsbnPublisherListEdit
                            key={"list" + e.entity.id}
                            item={e}
                            toggleEdit={this.toggleEdit}
                            submitChange={this.updateEntity}
                            resetRequstStatus={this.resetRequstStatus}
                        />
                    ) : (
                        <IsbnPublisherListItem
                            key={"edit" + e.entity.id}
                            item={e}
                            toggleEdit={this.toggleEdit}
                            resetRequstStatus={this.resetRequstStatus}
                            toggleExpand={this.toggleExpand}
                        />
                    )
                )}
            </div>
        );
    }
}

export default IsbnPublisherContainer;
