import {LangMessagesType} from "../../interfaces/lang";

export default {
    sk: {
        "upload.quantity": "Upload skladu",
        "upload.quantitygenerate": "Vytvoriť upload",

    },
    en: {
        "upload.quantity": "Quantity upload",
        "upload.quantitygenerate": "Generate upload",
    }
} as LangMessagesType;
