import React, { PureComponent, ReactNode } from "react";
import { NotificationItem } from "../notifications.page";
import { IntlShape } from "react-intl";
import NotificationType from "@ozibooks/server/dist/modules/notification/types/notificationtypes";
import {
    isNotificationLostQuantity,
    isNotificationBlacklistedListing,
} from "@ozibooks/server/dist/modules/notification/types/notificationpayload";
import { Link } from "react-router-dom";
import { DateTimeField } from "../../../components/listingview/detail/components/DateTimeField";
import IconInvisible from "../../../components/icons/invisible";
import IconVisible from "../../../components/icons/visible";

import "./notification.scss";
import UIBlocker from "../../../components/uiblocker";
import ErrorMessage from "../../../components/errormessage/errormessage";
import SlideDown from "react-slidedown";

type Props = {
    item: NotificationItem;
    toggleRead: (id: number) => void;
    toggleExpanded: (id: number) => void;
    intl: IntlShape;
};

class Notification extends PureComponent<Props, any> {
    private renderBlacklisted() {
        const { intl, item } = this.props;
        if (!isNotificationBlacklistedListing(item.payload)) {
            return (
                <div className="alert alert-danger">
                    <p>Invalid payload for notification #{item.id}</p>
                    <code>{JSON.stringify(item.payload, null, 4)}</code>
                </div>
            );
        }

        const title = intl.formatMessage(
            { id: "notifications.blacklistedtitle" },
            { count: item.payload.skus.length }
        );
        const clx = "ob-notification__linklist";
        return this.renderItem(
            title,
            <>
                {item.payload.skus.map((e) => (
                    <Link className={clx} to={"/amazonshop/sku/" + e}>
                        {e}
                    </Link>
                ))}
            </>
        );
    }

    private renderItem(title: string, body: ReactNode) {
        const { intl, item } = this.props;

        return (
            <>
                <div className={"ob-notification__titleblock"}>
                    <div
                        className="ob-notification__readicon"
                        title={intl.formatMessage({
                            id:
                                item.read === 0
                                    ? "notifications.markread"
                                    : "notifications.markunread",
                        })}
                    >
                        {item.read === 0 ? (
                            <IconInvisible
                                onClick={() => this.props.toggleRead(item.id)}
                                title={intl.formatMessage({
                                    id: "notifications.markread",
                                })}
                            />
                        ) : (
                            <IconVisible
                                onClick={() => this.props.toggleRead(item.id)}
                                title={intl.formatMessage({
                                    id: "notifications.markunread",
                                })}
                            />
                        )}
                    </div>
                    <div className="ob-notification__date">
                        <DateTimeField dateTime={item.created_at} />
                    </div>
                    <div
                        className="ob-notification__title"
                        onClick={() => this.props.toggleExpanded(item.id)}
                    >
                        {title}
                    </div>
                </div>
                <SlideDown closed={!item.isExpanded}>
                    <div className={"ob-notification__body"}>{body}</div>
                </SlideDown>
            </>
        );
    }

    private renderLostQuantityNotification() {
        const { intl, item } = this.props;
        if (!isNotificationLostQuantity(item.payload)) {
            return (
                <div className="alert alert-danger">
                    <p>Invalid payload for notification #{item.id}</p>
                    <code>{JSON.stringify(item.payload, null, 4)}</code>
                </div>
            );
        }
        const title = intl.formatMessage(
            { id: "notifications.lostquantitytitle" },
            { count: item.payload.skus.length }
        );
        const bodyStr = intl.formatMessage(
            {
                id: "notifications.lostquantitybody",
            },
            {
                source: item.payload.source,
                count: item.payload.skus.length,
            }
        );

        return this.renderItem(
            title,
            <>
                <p>{bodyStr}</p>
                <Link
                    to={"/lostquantity/" + item.payload.skus.join(",")}
                    className={"btn btn-primary btn-sm"}
                >
                    {intl.formatMessage({ id: "show" })}
                </Link>
            </>
        );
    }

    private renderBody() {
        const { item } = this.props;
        switch (item.notification_type) {
            case NotificationType.LOST_QUANTITY:
                return this.renderLostQuantityNotification();
            case NotificationType.LISTING_BLACKLISTED:
                return this.renderBlacklisted();
            default:
                return (
                    <div className="alert alert-danger">
                        Invalid notification type {item.notification_type} for
                        notification #{item.id}
                    </div>
                );
        }
    }

    render():
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        let clx = "ob-notification";
        if (this.props.item.read) {
            clx += "--read";
        }

        return (
            <div className={clx}>
                {this.props.item.requestActive && <UIBlocker />}
                {this.props.item.errMsg && (
                    <ErrorMessage err={this.props.item.errMsg} />
                )}
                {this.renderBody()}
            </div>
        );
    }
}

export default Notification;
