import React, {FunctionComponent} from 'react';
import Icon, {IconProps} from "./icon";
import {faEye} from "@fortawesome/free-regular-svg-icons/faEye";

export const IconVisible: FunctionComponent<IconProps> = (props) => {
    return <Icon icon={faEye} {...props} />;

};

export default IconVisible;
