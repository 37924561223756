import React, {FunctionComponent} from 'react';
import {FontAwesomeIcon, FontAwesomeIconProps} from '@fortawesome/react-fontawesome';

export type IconProps = Omit<FontAwesomeIconProps, "icon">

const Icon: FunctionComponent<FontAwesomeIconProps> = (props) => {
    return <FontAwesomeIcon {...props} />
};

export default Icon;
