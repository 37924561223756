import React, {FunctionComponent} from 'react';
import Spinner from "react-bootstrap/Spinner";
import "./loadindicator.scss";

type Props = {

};

const LoadIndicator: FunctionComponent<Props> = () => {
    return (
        <div className="ob-loadindicator">
            <Spinner animation={"border"} />
        </div>
    );
};

export default LoadIndicator;
