import React, {FunctionComponent} from 'react';
import {IsbnPublisherItem} from "./types";
import YesNoIcon from "../yesno/yesno";
import "./item.scss";
import {RequestStatusEnum} from "../../interfaces/request";
import Alert from "react-bootstrap/Alert";
import {FormattedMessage} from "react-intl";
import IsbnPrefixLink from "./link/isbnprefixlink";

export type IsbnPublisherListItemProps = {
    item: IsbnPublisherItem,
    toggleExpand: (ev: React.MouseEvent<HTMLButtonElement>) => void,
    toggleEdit: (ev: React.MouseEvent<HTMLButtonElement>) => void,
    resetRequstStatus: (id: number) => void,
};

type Comp = FunctionComponent<IsbnPublisherListItemProps>;
export const IsbnPublisherListItem: Comp  = ({item,toggleEdit, toggleExpand, resetRequstStatus}) => {
    return (
        <div className={"ob-isbnpublisheritem"}>
            <h4>{item.entity.name}</h4>
            <div className="ob-isbnpublisheritem__buttonbar">
                <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-id={item.entity.id}
                    onClick={toggleEdit}
                ><FormattedMessage id={"edit"} /></button>
                <button
                    type="button"
                    className="btn btn-secondary btn-sm"
                    data-id={item.entity.id}
                    onClick={toggleExpand}
                ><FormattedMessage id={item.isExpanded ? "showless" : "showmore"} /></button>
            </div>
            {item.requestStatus === RequestStatusEnum.SUCCESS && (
                <div className="ob-isbnpublisheritem__alertbox">
                    <Alert variant={"success"} dismissible={true} onClose={() => resetRequstStatus(item.entity.id)}>
                        <FormattedMessage id={"isbnprefix.publishersaved"} />
                    </Alert>
                </div>
            )}
            <div className="ob-isbnpublisheritem__infoblock">
                <table className="table table-borderless">
                        <tbody>
                        <tr key={"category"}>
                            <th scope={"row"}><FormattedMessage id={"category"}/></th>
                            <td>{item.entity.category}</td>
                        </tr>
                        <tr key={"ignored"}>
                            <th scope={"row"}><FormattedMessage id={"isbnprefix.ignored"}/>?</th>
                            <td><YesNoIcon yesno={item.entity.ignored}/></td>
                        </tr>
                        <tr key={"usedonly"}>
                            <th scope={"row"}><FormattedMessage id={"isbnprefix.usedonly"}/>?</th>
                            <td><YesNoIcon yesno={item.entity.used_only}/></td>
                        </tr>
                        {!item.isExpanded && (
                            <tr key={"country+agency"}>
                                <th scope={"row"}>Agency/Country</th>
                                <td>{item.entity.agency}/{item.entity.country}</td>
                            </tr>
                        )}
                        {item.isExpanded && (
                            <tr key={"agency"}>
                                <th scope={"row"}>Agency</th>
                                <td>{item.entity.agency}</td>
                            </tr>
                        )}
                        {item.isExpanded && (
                            <tr key={"country"}>
                                <th scope={"row"}>Country</th>
                                <td>{item.entity.country}</td>
                            </tr>
                        )}
                        {item.isExpanded && (
                            <tr key={"status"}>
                                <th scope={"row"}>Status</th>
                                <td>{item.entity.status}</td>
                            </tr>
                        )}
                        {item.isExpanded && (
                            <tr key={"url"}>
                                <th scope={"row"}>URL</th>
                                <td>{item.entity.url}</td>
                            </tr>
                        )}
                        {item.isExpanded && (
                            <tr key={"Address"}>
                                <th scope={"row"}>Address</th>
                                <td>{item.entity.address}</td>
                            </tr>
                        )}
                        {item.isExpanded && (
                            <tr key={"UUID"}>
                                <th scope={"row"}>UUID</th>
                                <td>{item.entity.uuid}</td>
                            </tr>
                        )}
                        {item.isExpanded && (
                            <tr key={"ID"}>
                                <th scope={"row"}>ID</th>
                                <td>{item.entity.id}</td>
                            </tr>
                        )}
                        <tr key={"isbn_prefixed"}>
                            <th scope={"row"}>ISBN Prefix</th>
                            <td>{item.entity.prefixes?.map(e => (
                                <IsbnPrefixLink item={e} key={e.prefix13}/>
                            ))}</td>
                        </tr>
                        </tbody>
                </table>
            </div>
        </div>
    );
};
