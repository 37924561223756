import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
} from "react";
import { TagEntity } from "@ozibooks/server/dist/modules/tags/entities/tag.entity";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import { AppContext } from "../../utils/appcontext";
import {
    PageBody,
    PageHeading,
    PageHeadingActionType,
} from "../../components/page/page";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons/faEdit";
import Modal from "react-bootstrap/Modal";
import { EditTagForm } from "./components/edittagform";
import "./tags.page.scss";
import { useIntl } from "react-intl";
import Tag from "../../components/tag/tag";
import { faServer } from "@fortawesome/free-solid-svg-icons/faServer";

type Props = {};

type State = RequestStatus2 & {
    items: TagEntity[];
    isCreating: boolean;
    isEditing: TagEntity | null;
};

export const TagsPage: FunctionComponent<Props> = () => {
    const intl = useIntl();
    const context = useContext(AppContext);
    const [state, setState] = useState<State>({
        requestStatus: null,
        errorMsg: null,
        items: [],
        isCreating: false,
        isEditing: null,
    });
    const { client } = context!;
    const { requestStatus } = state;
    const loadItems = useCallback(() => {
        setState({
            requestStatus: RequestStatusEnum.ACTIVE,
            items: [],
            errorMsg: null,
            isEditing: null,
            isCreating: false,
        });
        client.tags
            .getAll()
            .then((all) => {
                setState({
                    requestStatus: RequestStatusEnum.SUCCESS,
                    items: all,
                    errorMsg: null,
                    isCreating: false,
                    isEditing: null,
                });
            })
            .catch((err) => {
                setState((old) => ({
                    ...old,
                    requestStatus: RequestStatusEnum.FAIL,
                    errorMsg: err,
                }));
            });
    }, [client]);
    useEffect(() => {
        if (requestStatus === null) {
            loadItems();
        }
    }, [requestStatus, loadItems]);
    const actions = useMemo(() => {
        const ret: PageHeadingActionType[] = [
            {
                label: intl.formatMessage({ id: "reload" }),
                action: loadItems,
                disabled: false,
            },
            {
                label: intl.formatMessage({ id: "tags.create" }),
                action: () => setState((old) => ({ ...old, isCreating: true })),
                disabled: false,
            },
        ];
        return ret;
    }, [intl, loadItems]);

    return (
        <div className={"page"}>
            <PageHeading
                title={intl.formatMessage({ id: "tags" })}
                actions={actions}
            />
            {state.isEditing !== null && (
                <Modal
                    key={"editing " + state.isEditing.id}
                    show={!!state.isEditing}
                    animation={true}
                    size={"sm"}
                    onHide={() =>
                        setState((old) => ({ ...old, isEditing: null }))
                    }
                >
                    <Modal.Header closeButton={true}>
                        {intl.formatMessage({ id: "tags.edit" })}
                    </Modal.Header>
                    <Modal.Body>
                        <EditTagForm
                            entity={state.isEditing}
                            onEntityUpdated={(ent) =>
                                setState((old) => ({
                                    ...old,
                                    isEditing: null,
                                    items: old.items.map((e) =>
                                        e.id === ent.id ? ent : e
                                    ),
                                }))
                            }
                            onClose={() =>
                                setState((old) => ({ ...old, isEditing: null }))
                            }
                        />
                    </Modal.Body>
                </Modal>
            )}
            {state.isCreating && (
                <Modal
                    key={"createtagmodal"}
                    show={state.isCreating}
                    animation={true}
                    size={"sm"}
                    onHide={() =>
                        setState((old) => ({ ...old, isCreating: false }))
                    }
                >
                    <Modal.Header closeButton={true}>
                        {intl.formatMessage({ id: "tags.create" })}
                    </Modal.Header>
                    <Modal.Body>
                        <EditTagForm
                            onEntityCreated={(ent) =>
                                setState((old) => ({
                                    ...old,
                                    isCreating: false,
                                    items: [...old.items, ent],
                                }))
                            }
                            onClose={() =>
                                setState((old) => ({
                                    ...old,
                                    isCreating: false,
                                }))
                            }
                        />
                    </Modal.Body>
                </Modal>
            )}
            <PageBody
                requestStatus={state.requestStatus}
                errorMsg={state.errorMsg}
                onRetry={loadItems}
            >
                <table
                    className={"ob-tagstable table table-borderless infotable"}
                >
                    <tbody>
                        {state.items.map((e) => (
                            <tr key={e.id}>
                                <td>
                                    <Tag text={e.name} color={e.color} />
                                </td>
                                <td>
                                    {e.system_tag === 0 ? (
                                        <FontAwesomeIcon
                                            icon={faEdit}
                                            onClick={() =>
                                                setState((old) => ({
                                                    ...old,
                                                    isEditing: e,
                                                }))
                                            }
                                            title={intl.formatMessage({
                                                id: "tags.edit",
                                            })}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={faServer}
                                            title={intl.formatMessage({
                                                id: "tags.systemtag",
                                            })}
                                        />
                                    )}
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </PageBody>
        </div>
    );
};
