import React, {
    FunctionComponent,
    MouseEvent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import {
    avaWebToOrderableInfo,
    balmerWebToOrderableInfo,
    BzOrderableInfoType,
    bzToOrderableInfo,
    olfToOrderableInfo,
    OrderableInfoType,
} from "../../../utils/helpers/orderable";
import { RequestStatus2, RequestStatusEnum } from "../../../interfaces/request";
import { AppException } from "../../../utils/exceptions";
import LoadIndicator from "../../../components/loadindicator";
import ErrorMessage from "../../../components/errormessage/errormessage";
import { UnknownClientException } from "../../../utils/apiclient/exceptions";
import useReactDataGrid, {
    ColumnSortType,
    SortableColumn,
} from "../../../utils/hooks/reactdatagrid";
import { HeaderRendererProps } from "react-data-grid/lib/types";
import { FormattedDate, FormattedMessage } from "react-intl";
import BzColumnsFormatter from "./formatters/bzcolumnsformatter";
import UIBlocker from "../../../components/uiblocker";
import Dropdown from "react-bootstrap/Dropdown";
import DataGrid, { SelectColumn } from "react-data-grid";
import Modal from "react-bootstrap/Modal";
import { OrderableField } from "../../../components/listingview/detail/components/orderable";
import { AvaColumnsFormatter } from "./formatters/avacolumnsformatter";
import ListingView from "../../../components/listingview/listingview";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import PrefixFormatter from "../../../components/react-data-grid/formatters/prefixformatter";

import "../../../scss/loaders/reactdatagrid";
import "./activation.scss";
import TextSlectCell from "../../../components/react-data-grid/components/textselectcell";
import { TagEntity } from "@ozibooks/server/dist/modules/tags/entities/tag.entity";

type ActivationTableProps = {
    loadItems: () => Promise<Amazonshop[]>;
    changeActivation: (skus: string[]) => Promise<void>;
    actionButtonLabel: string;
};

type ActivationTableState = RequestStatus2 & {
    items: ActivationTableItem[];
    isChangingActivation: boolean;
    changingActivationError: AppException | null;
    skuToShow: string | null;
};

export type ActivationTableItem = {
    sku: string;
    asin: string;
    isbn: string | null;
    status: string;
    quantity: number;
    bz: BzOrderableInfoType[];
    olf: OrderableInfoType;
    ava: OrderableInfoType[];
    balmer: OrderableInfoType[];
    isbnPrefix: IsbnPrefixEntity | null;
    tags: TagEntity[];
    starred: boolean;
};

export type ActivationTableSummaryRow = {
    totalRows: number;
};

type ActivationColumn = SortableColumn<
    ActivationTableItem,
    ActivationTableSummaryRow
>;

const HeaderRenderer: FunctionComponent<
    HeaderRendererProps<ActivationTableItem, ActivationTableSummaryRow>
> = ({ column }) => <FormattedMessage id={column.name as string} />;

export const ActivationTable: FunctionComponent<ActivationTableProps> = ({
    loadItems,
    actionButtonLabel,
    changeActivation,
}) => {
    const [state, setState] = useState<ActivationTableState>({
        items: [],
        isChangingActivation: false,
        changingActivationError: null,
        requestStatus: null,
        errorMsg: null,
        skuToShow: null,
    });
    const showModal = useCallback(
        (ev: MouseEvent<HTMLSpanElement>) => {
            if (!ev.currentTarget) {
                return;
            }

            const toShow = ev.currentTarget.dataset["sku"];
            if (toShow) {
                setState((old) => ({ ...old, skuToShow: toShow }));
            }
        },
        [setState]
    );
    const closeModal = useCallback(
        () => setState((old) => ({ ...old, skuToShow: null })),
        [setState]
    );
    const columns = useMemo<ActivationColumn[]>(() => {
        return [
            SelectColumn,
            {
                name: "SKU",
                key: "sku",
                width: 210,
                frozen: true,
                sortType: ColumnSortType.STRING,
                sortable: true,
                resizable: true,
                formatter: ({ row, isCellSelected }) => (
                    <div className="ob-rgb-cellwithpopup">
                        <TextSlectCell
                            text={row.sku}
                            isCellSelected={isCellSelected}
                        />
                        <span role="img" data-sku={row.sku} onClick={showModal}>
                            🔎
                        </span>
                    </div>
                ),
                summaryFormatter: ({ row }) => (
                    <strong>Total: {row.totalRows}</strong>
                ),
            },
            {
                key: "asin",
                name: "ASIN",
                frozen: false,
                resizable: true,
                sortable: true,
                width: 110,
                sortType: ColumnSortType.STRING,
            },
            {
                key: "isbn",
                name: "ISBN/EAN",
                frozen: false,
                resizable: true,
                sortable: false,
                width: 110,
            },
            {
                key: "status",
                name: "quantitytable.status",
                resizable: true,
                sortable: true,
                sortType: ColumnSortType.STRING,
                headerRenderer: HeaderRenderer,
                width: 110,
            },
            {
                key: "quantity",
                name: "quantitytable.current",
                resizable: true,
                sortable: true,
                sortType: ColumnSortType.NUMBER,
                width: 150,
                headerRenderer: HeaderRenderer,
            },
            {
                key: "isbnprefix",
                name: "ISBN Prefix",
                resizable: true,
                sortable: false,
                width: 150,
                formatter: PrefixFormatter,
            },
            {
                key: "tags",
                name: "Tags",
                resizable: true,
                sortable: false,
                width: 150,
                formatter: ({ row }) => (
                    <>{row.tags.map((e) => e.name).join(", ")}</>
                ),
            },
            {
                key: "bz.publishing_status",
                name: "activation.bzstatustype",
                resizable: true,
                sortable: false,
                width: 110,
                headerRenderer: HeaderRenderer,
                formatter: ({ row }) => (
                    <BzColumnsFormatter
                        columnKey={"publishing_status"}
                        info={row.bz}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "bz.status",
                name: "activation.bzstatus",
                resizable: true,
                sortable: false,
                width: 330,
                headerRenderer: () => (
                    <>
                        BZ <FormattedMessage id={"quantitytable.status"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <BzColumnsFormatter
                        columnKey={"status"}
                        info={row.bz}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "bz.updated_at",
                name: "bz.updated_at",
                resizable: true,
                sortable: false,
                width: 150,
                headerRenderer: () => (
                    <>
                        BZ <FormattedMessage id={"updated_at"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <BzColumnsFormatter
                        columnKey={"updated_at"}
                        info={row.bz}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "bz.orderable",
                name: "bz.orderable",
                resizable: true,
                sortable: false,
                width: 160,
                headerRenderer: () => (
                    <>
                        BZ{" "}
                        <FormattedMessage id={"publishingstatus.orderable"} />?
                    </>
                ),
                formatter: ({ row }) => (
                    <BzColumnsFormatter
                        columnKey={"orderable"}
                        info={row.bz}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "olf.status",
                name: "olf.status",
                resizable: true,
                sortable: false,
                width: 200,
                headerRenderer: () => (
                    <>
                        Olf <FormattedMessage id={"quantitytable.status"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <>
                        {row.olf.status !== null ? (
                            row.olf.status
                        ) : (
                            <FormattedMessage id={"nodata"} />
                        )}
                    </>
                ),
            },
            {
                key: "olf.updated_at",
                name: "olf.updated_at",
                resizable: true,
                sortable: false,
                width: 150,
                headerRenderer: () => (
                    <>
                        Olf <FormattedMessage id={"updated_at"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <>
                        {row.olf.updated_at === null ? (
                            ""
                        ) : (
                            <FormattedDate
                                dateStyle={"medium"}
                                timeStyle={"medium"}
                                value={row.olf.updated_at}
                            />
                        )}
                    </>
                ),
            },
            {
                key: "olf.orderable",
                name: "olf.orderable",
                resizable: true,
                sortable: false,
                width: 160,
                headerRenderer: () => (
                    <>
                        Olf{" "}
                        <FormattedMessage id={"publishingstatus.orderable"} />?
                    </>
                ),
                formatter: ({ row }) => (
                    <OrderableField
                        orderable={
                            row.starred
                                ? row.olf.orderable_starred
                                : row.olf.orderable
                        }
                    />
                ),
            },
            {
                key: "ava.status",
                name: "ava.status",
                resizable: true,
                sortable: false,
                width: 220,
                headerRenderer: () => (
                    <>
                        Ava <FormattedMessage id={"quantitytable.status"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <AvaColumnsFormatter
                        info={row.ava}
                        columnKey={"status"}
                        prefix={"Ava"}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "ava.updated_at",
                name: "ava.updated_at",
                resizable: true,
                sortable: false,
                width: 150,
                headerRenderer: () => (
                    <>
                        Ava <FormattedMessage id={"updated_at"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <AvaColumnsFormatter
                        info={row.ava}
                        columnKey={"updated_at"}
                        prefix={"Ava"}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "ava.orderable",
                name: "ava.orderable",
                resizable: true,
                sortable: false,
                width: 160,
                headerRenderer: () => (
                    <>
                        Ava{" "}
                        <FormattedMessage id={"publishingstatus.orderable"} />?
                    </>
                ),
                formatter: ({ row }) => (
                    <AvaColumnsFormatter
                        info={row.ava}
                        columnKey={"orderable"}
                        prefix={"Ava"}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "balmer.status",
                name: "balmer.status",
                resizable: true,
                sortable: false,
                width: 220,
                headerRenderer: () => (
                    <>
                        Balmer <FormattedMessage id={"quantitytable.status"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <AvaColumnsFormatter
                        info={row.balmer}
                        columnKey={"status"}
                        prefix={"Balmer"}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "balmer.updated_at",
                name: "balmer.updated_at",
                resizable: true,
                sortable: false,
                width: 164,
                headerRenderer: () => (
                    <>
                        Bamer <FormattedMessage id={"updated_at"} />
                    </>
                ),
                formatter: ({ row }) => (
                    <AvaColumnsFormatter
                        info={row.balmer}
                        columnKey={"updated_at"}
                        prefix={"Bamer"}
                        starred={row.starred}
                    />
                ),
            },
            {
                key: "balmer.orderable",
                name: "balmer.orderable",
                resizable: true,
                sortable: false,
                width: 180,
                headerRenderer: () => (
                    <>
                        Balmer{" "}
                        <FormattedMessage id={"publishingstatus.orderable"} />?
                    </>
                ),
                formatter: ({ row }) => (
                    <AvaColumnsFormatter
                        info={row.balmer}
                        columnKey={"orderable"}
                        prefix={"Balmer"}
                        starred={row.starred}
                    />
                ),
            },
        ];
    }, [showModal]);
    const { setRows, gridRows, props } = useReactDataGrid<
        ActivationTableItem,
        ActivationTableSummaryRow
    >({
        columns,
        rows: [],
        rowKey: "sku",
        defaultSort: "sku",
        defaultSortDirection: "NONE",
    });
    const { requestStatus } = state;
    const loadItemsToState = useCallback(async () => {
        setState((old) => ({
            ...old,
            requestStatus: RequestStatusEnum.ACTIVE,
            items: [],
            errorMsg: null,
        }));

        try {
            const res = await loadItems();
            setRows(
                res.map((e) => {
                    return {
                        sku: e["seller-sku"],
                        asin: e.asin1,
                        isbn: e.real_product_id,
                        quantity: e.quantity,
                        status: e.status,
                        bz: bzToOrderableInfo(e),
                        olf: olfToOrderableInfo(e),
                        ava: avaWebToOrderableInfo(e),
                        balmer: balmerWebToOrderableInfo(e),
                        isbnPrefix: e.isbnPrefix ? e.isbnPrefix : null,
                        tags: Array.isArray(e.tags) ? [...e.tags] : [],
                        starred: e.isbnPrefix ? !!e.isbnPrefix.starred : false,
                    };
                })
            );
            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.SUCCESS,
            }));
        } catch (e: any) {
            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.FAIL,
                errorMsg: e,
            }));
        }
    }, [loadItems, setState, setRows]);
    useEffect(() => {
        if (requestStatus !== null) {
            return;
        }

        loadItemsToState().catch((e) =>
            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.FAIL,
                errorMsg: e,
            }))
        );
    }, [requestStatus, setState, loadItemsToState]);

    const { selectedRows, onSelectedRowsChange, rows } = props;
    const summary: ActivationTableSummaryRow[] = useMemo(() => {
        return [{ totalRows: rows.length }];
    }, [rows]);
    const runChangeActivation = useCallback(async () => {
        if (!selectedRows || !selectedRows.size) {
            return;
        }

        const toChange: string[] = Array.from(selectedRows) as string[];
        if (!toChange.length) {
            return;
        }

        setState((old) => ({
            ...old,
            isChangingActivation: true,
            changingActivationError: null,
        }));

        try {
            await changeActivation(toChange);

            onSelectedRowsChange && onSelectedRowsChange(new Set<React.Key>());
            setRows(gridRows.filter((e) => !toChange.includes(e.sku)));
            setState((old) => ({ ...old, isChangingActivation: false }));
        } catch (e: any) {
            setState((old) => ({
                ...old,
                isChangingActivation: false,
                changingActivationError: e,
            }));
        }
    }, [
        setRows,
        gridRows,
        selectedRows,
        setState,
        onSelectedRowsChange,
        changeActivation,
    ]);

    const clearError = useCallback(() => {
        setState((old) => ({ ...old, changingActivationError: null }));
    }, [setState]);

    if (requestStatus === RequestStatusEnum.ACTIVE || requestStatus === null) {
        return <LoadIndicator />;
    }

    if (requestStatus === RequestStatusEnum.FAIL && state.errorMsg) {
        return (
            <ErrorMessage
                err={
                    state.errorMsg instanceof AppException
                        ? state.errorMsg
                        : new UnknownClientException("")
                }
                onRetry={loadItemsToState}
            />
        );
    }

    return (
        <div className={"ob-rdg__wrapper"}>
            {state.skuToShow && (
                <Modal
                    show={!!state.skuToShow}
                    animation={true}
                    size={"xl"}
                    onHide={closeModal}
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <ListingView sku={state.skuToShow} />
                    </Modal.Body>
                </Modal>
            )}
            {state.isChangingActivation && <UIBlocker />}
            {state.changingActivationError && (
                <ErrorMessage
                    err={state.changingActivationError}
                    onClose={clearError}
                    onRetry={runChangeActivation}
                />
            )}
            <div className="ob-rdg__buttonbar">
                <Dropdown>
                    <Dropdown.Toggle variant={"primary"}>
                        <FormattedMessage id={"actions"} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item
                            eventKey={"1"}
                            onClick={loadItemsToState}
                        >
                            <FormattedMessage id={"reload"} />
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"2"}
                            onClick={runChangeActivation}
                            disabled={!selectedRows?.size}
                        >
                            <FormattedMessage id={actionButtonLabel} />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="ob-rgd">
                <DataGrid {...props} summaryRows={summary} />
            </div>
        </div>
    );
};
