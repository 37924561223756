
const saveAs = (name: string, blob: Blob): void => {
    const link = document.createElement("a");
    link.style.display = "none";
    link.download = name;
    link.href = URL.createObjectURL(blob);

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

export default saveAs;
