import React, {FunctionComponent} from 'react';
import {faStar} from "@fortawesome/free-solid-svg-icons/faStar";
import Icon, {IconProps} from "./icon";
import classNames from 'classnames';
import "./fullstar.scss";

type Props = IconProps & {
    isActive?:boolean,
};

const IconFullStar: FunctionComponent<Props> = (props) => {
    const {isActive, ...rest} = props;
    const clx = classNames(rest.className, {"ob-fullstaricon--active": isActive});
    return <Icon className={clx} {...rest} icon={faStar} />
};

export default IconFullStar;
