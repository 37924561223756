import React, {FunctionComponent, useCallback, useState} from 'react';
import {useIntl} from "react-intl";
import {RequestStatus2, RequestStatusEnum} from "../../../../interfaces/request";
import "./blacklistform.scss";
import UIBlocker from "../../../uiblocker";
import ErrorMessage from "../../../errormessage/errormessage";

type Props = {
    product_id: string,
    initialText: string,
    isNew: boolean,
    onCreate: (product_id: string, text: string) => Promise<void>,
    onUpdate: (product_id: string, text: string) => Promise<void>,
    onDelete: (product_id: string) => Promise<void>,
};

type State = RequestStatus2 & {
    text: string,
    isDeleting: boolean,
};

export const BlacklistForm: FunctionComponent<Props> = ({product_id, initialText, isNew, onCreate, onDelete, onUpdate}) => {
    const intl = useIntl();
    const [state, setState] = useState<State>({
        requestStatus: null,
        errorMsg: null,
        text: initialText,
        isDeleting: false,
    });
    const handleSubmit = useCallback((text: string) => {
        if(!text) {
            return;
        }
        setState(old => ({...old, requestStatus: RequestStatusEnum.ACTIVE, errorMsg: null}));

        if(isNew) {
            onCreate(product_id, text.trim()).then(() => {
                setState(old => ({...old, requestStatus: RequestStatusEnum.SUCCESS}));
            }).catch(err => {
                setState(old => ({...old, requestStatus: RequestStatusEnum.FAIL, errorMsg: err}));
            });
        } else {
            onUpdate(product_id, text.trim()).then(() => {
                setState(old => ({...old, requestStatus: RequestStatusEnum.SUCCESS}));
            }).catch(err => {
                setState(old => ({...old, requestStatus: RequestStatusEnum.FAIL, errorMsg: err}));
            });
        }

    }, [product_id, onUpdate, onCreate, isNew]);
    const handleDelete = useCallback(() => {
        setState(old => ({
            ...old,
            isDeleting: false,
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
        }));
        onDelete(product_id).catch(err => {
            setState(old => ({...old, requestStatus: RequestStatusEnum.FAIL, errorMsg: err}));
        });
    }, [product_id, onDelete]);

    let saveDisabled = !state.text || state.text === initialText;
    if(state.isDeleting) {
        return (
            <div className={"ob-listingview__blacklistform"}>
                <div className="modal">
                    <div className="modal-dialog">
                        <div className="modal-content">
                            <div className="modal-header">
                                <h5 className="modal-title">{intl.formatMessage({id: "blacklist.deleteheader"}, {id: product_id})}</h5>
                            </div>
                            <div className="modal-body">
                                <p>{intl.formatMessage({id: "blacklist.confirmdelete"}, {id: product_id})}</p>
                            </div>
                            <div className="modal-footer">
                                <button
                                    type="button"
                                    className="btn btn-danger"
                                    onClick={handleDelete}
                                >{intl.formatMessage({id: "delete"})}</button>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => setState(old => ({...old, isDeleting: false}))}
                                >{intl.formatMessage({id: "cancel"})}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }

    return (
        <div className={"ob-listingview__blacklistform"}>
            {state.requestStatus === RequestStatusEnum.ACTIVE && <UIBlocker/>}
            {state.errorMsg && (
                <ErrorMessage
                    err={state.errorMsg}
                    onClose={() => setState(old => ({...old, errorMsg: null}))}
                />
            )}
            {!isNew && (
                <div className="alert alert-danger" role="alert">
                    {intl.formatMessage({id: "blacklist.blacklisted"})}
                </div>
            )}
            <form
                onSubmit={ev => {
                    ev.preventDefault();
                    handleSubmit(state.text);
                }}
            >
                <div className="form-group">
                    <label>{intl.formatMessage({id: "blacklist.reason"})}</label>
                    <textarea
                        className={"form-control"}
                        value={state.text}
                        rows={5}
                        onChange={ev => {
                            const val = ev.target.value;
                            setState(old => ({...old, text: val}));
                        }}
                    />
                </div>
                <div className="ob-formbuttonbar">
                    <button
                        disabled={saveDisabled}
                        className={"btn btn-success"}
                        type={"submit"}
                    >{intl.formatMessage({id: isNew ? "blacklist.addtoblacklist" : "save"})}</button>
                    <button
                        className={"btn btn-secondary"}
                        onClick={() => setState(old => ({...old, text: initialText}))}
                    >{intl.formatMessage({id: "reload"})}</button>
                    {!isNew && (
                        <button
                            className={"btn btn-danger"}
                            onClick={e => {
                                e.preventDefault();
                                setState(old => ({...old, isDeleting: true}))
                            }}
                        >{intl.formatMessage({id: "delete"})}</button>
                    )}
                </div>
            </form>

        </div>
    );
};


