import { IsbnPublisherEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnpublisher.entity";
import { IsbnPublisherItem } from "./types";

export const toIsbnPublisherItem = (
    item: IsbnPublisherEntity
): IsbnPublisherItem => {
    return {
        entity: item,
        isEdited: false,
        isExpanded: false,
        requestStatus: null,
        errorMsg: null,
    };
};
