import React, {FunctionComponent} from 'react';
import "./tag.scss";

type Props = {
    text: string,
    color?: string | null;
};

const Tag: FunctionComponent<Props> = ({text, color}) => {
    let clx = "ob-tag ";
    if(color) {
        clx += "ob-tag--" + color;
    }

    return (<span className={clx}>{text}</span>);
};

export default Tag;
