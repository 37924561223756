import React, { Component, ContextType } from "react";
import { IsbnPrefixEntity } from "@ozibooks/server/dist/modules/isbnprefix/entities/isbnprefix.entity";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import { RouteComponentProps } from "react-router";
import { AppContext } from "../../utils/appcontext";
import { injectIntl } from "react-intl";
import { WrappedComponentProps } from "react-intl/lib";
import "./isbnprefixpage.scss";
import { PageBody } from "../../components/page/page";
import IsbnPrefixContainer from "../../components/isbnprefix/isbnprefixcontainer";

type State = {
    isbnPrefix: null | IsbnPrefixEntity;
} & RequestStatus2;

type Props = RouteComponentProps<{ prefix: string }> & WrappedComponentProps;

class IsbnPrefixPageBase extends Component<Props, State> {
    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;
    constructor(props: any) {
        super(props);
        this.state = {
            isbnPrefix: null,
            requestStatus: null,
            errorMsg: null,
        };

        this.loadPrefix = this.loadPrefix.bind(this);
        this.forceLoadPrefix = this.forceLoadPrefix.bind(this);
    }

    componentDidMount() {
        this.loadPrefix().catch((err) => this.setState({ errorMsg: err }));
    }

    private async loadPrefix(force: boolean = false) {
        const prefix = this.props.match.params.prefix;
        this.setState({
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
            isbnPrefix: null,
        });

        try {
            const ret = await this.context!.client.isbnPrefix.getPrefix(
                prefix,
                force
            );
            if (ret && ret.scraped_at && typeof ret.scraped_at === "string") {
                ret.scraped_at = new Date(ret.scraped_at);
            }
            this.setState({
                requestStatus: RequestStatusEnum.SUCCESS,
                isbnPrefix: ret ? ret : null,
            });
        } catch (e: any) {
            this.setState({
                requestStatus: RequestStatusEnum.FAIL,
                errorMsg: e,
            });
        }
    }

    private forceLoadPrefix() {
        this.loadPrefix(true).catch((err) => this.setState({ errorMsg: err }));
    }

    componentDidUpdate(
        prevProps: Readonly<RouteComponentProps<{ prefix: string }>>,
        prevState: Readonly<State>,
        snapshot?: any
    ): void {
        if (prevProps.match.params.prefix !== this.props.match.params.prefix) {
            this.loadPrefix().catch((err) => this.setState({ errorMsg: err }));
        }
    }

    render():
        | React.ReactElement<any, string | React.JSXElementConstructor<any>>
        | string
        | number
        | {}
        | React.ReactNodeArray
        | React.ReactPortal
        | boolean
        | null
        | undefined {
        const { requestStatus, isbnPrefix, errorMsg } = this.state;
        const prefix13 = this.props.match.params.prefix;

        return (
            <div className="page">
                {requestStatus !== RequestStatusEnum.SUCCESS && (
                    <div className="ob-isbnprefixpage__title">
                        <h2>ISBN Prefix {this.props.match.params.prefix}</h2>
                    </div>
                )}
                <PageBody
                    requestStatus={requestStatus}
                    errorMsg={errorMsg}
                    onRetry={this.loadPrefix}
                >
                    <IsbnPrefixContainer
                        prefix13={prefix13}
                        key={prefix13}
                        entity={isbnPrefix || undefined}
                    />
                </PageBody>
            </div>
        );
    }
}

const IsbnPrefixPage = injectIntl<"intl", Props>(IsbnPrefixPageBase);

export default IsbnPrefixPage;
