import {LangMessagesType} from "../interfaces/lang";

const messages: LangMessagesType = {
    sk: {
        required: "Povinné",
        save: "Uložiť",
        yes: "Áno",
        no: "Nie",
        all: "Všetko",
        actions: "Akcie",
        reload: "Obnoviť",
        created_at: "Vytvorené",
        updated_at: "Aktualizované",
        notfound: "sa nenašlo",
        nodata: "Žiadne data",
        quantityinventory: "Sklad",
        deleteselected: "Zmazať označené",
        category: "Kategória",
        publisher: "Vydavateľ",
        publishers: "Vydavatelia",
        cancel: "Zrušiť",
        edit: "Upraviť",
        showless: "Zobraziť menej",
        showmore: "Zobraziť viac",
        name: "Názov",
        search: "Hľadať",
        noresult: "Nič sa nenašlo",
        updatedat: "Aktualizované",
        updating: "Aktualizujem",
        never: "Nikdy",
        tags: "Tagy",
        blacklist: "Blacklist",
        delete: "Zmazať",
        show: "Zobraziť",
        notifications: "Notifikácie",
        isbntools: "ISBN nástroje",
        tools: "Nástroje",
    },
    en: {
        required: "Required",
        save: "Save",
        yes: "Yes",
        no: "No",
        all: "All",
        actions: "Actions",
        reload: "Reload",
        created_at: "Created",
        updated_at: "Updated",
        notfound: "not found",
        nodata: "No data",
        quantityinventory: "Quantity",
        deleteselected: "Delete selected",
        category: "Category",
        publisher: "Publisher",
        publishers: "Publishers",
        cancel: "Cancel",
        edit: "Edit",
        showless: "Show less",
        showmore: "Show more",
        name: "Name",
        search: "Search",
        noresult: "No result",
        updatedat: "Updated at",
        updating: "Aktualizujem",
        never: "Never",
        tags: "Tags",
        blacklist: "Blacklist",
        delete: "Delete",
        show: "Show",
        notifications: "Notifications",
        tools: "Tools",
        isbntools: "ISBN tools",
    },
};


export default messages;
