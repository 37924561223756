import React, {ChangeEvent, FunctionComponent} from 'react';

type Props = {
    value: string,
    options: string[][],
    onChange: (ev: ChangeEvent<HTMLSelectElement>) => void,
};

export const PublishingStatusFilterSelect: FunctionComponent<Props> = (props) => {
    return (
        <select
            className={"form-control form-control-sm"}
            value={props.value}
            onChange={props.onChange}
        >
            {props.options.map(e => (
                <option key={e[1]} value={e[0]}>{e[1]}</option>
            ))}
        </select>
    );
};
