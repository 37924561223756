import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useState,
} from "react";
import { SettingsType } from "../types";
import { useForm } from "react-hook-form";
import Button from "react-bootstrap/Button";
import classnames from "classnames";
import { AppContext } from "../../../utils/appcontext";
import { AuthType } from "../../../interfaces/auth";
import { FormattedMessage } from "react-intl";

export type ServeSettingsData = Pick<SettingsType, "host" | "port" | "apikey">;

export type ServerSettingsSubmitData = {
    host: string;
    port: string;
    apikey: string;
};

type Props = {
    onSave: (data: ServerSettingsSubmitData) => void;
    initialData: ServeSettingsData;
};

export const ServerSettingsForm: FunctionComponent<Props> = ({
    initialData,
    onSave,
}) => {
    const [formError, setFormError] = useState<string>("");
    const { register, errors, handleSubmit } = useForm();
    const context = useContext(AppContext);
    const saveForm = useCallback(
        async (data: ServerSettingsSubmitData) => {
            console.log("Saving form");
            if (!context) {
                setFormError("Missing AppContext");
                return;
            }

            const newAuth: AuthType = {
                host: data.host,
                apikey: data.apikey,
                port: data.port ? parseInt(data.port) : null,
            };

            if (newAuth.port && isNaN(newAuth.port)) {
                setFormError("Invalid port " + data.port);
                return;
            }

            try {
                context.client.setAuth(newAuth);
                await context.storage.setAuth(newAuth);
                setFormError("");
            } catch (e: any) {
                setFormError(e.message ? e.message : "Something went wrong");
            }
        },
        [context, setFormError]
    );

    if (!context) {
        throw new Error("Missing AppContext");
    }

    const auth = context.client.getAuth();

    return (
        <form
            className="ob-settings-serverform"
            onSubmit={handleSubmit(saveForm)}
        >
            {formError && <div style={{ background: "red" }}>{formError}</div>}
            <div className="form-group">
                <label htmlFor="hostfield">
                    <FormattedMessage id="server.address" />
                </label>
                <input
                    type="text"
                    name="host"
                    id="hostfield"
                    className={classnames("form-control", {
                        "is-invalid": errors.host,
                    })}
                    defaultValue={!auth ? "" : auth.host}
                    ref={register({ required: true })}
                />
                {errors.host && (
                    <div className="invalid-feedback">
                        {errors.host.type === "required" && (
                            <FormattedMessage id={"required"} />
                        )}
                        {errors.host.type === "pattern" && (
                            <FormattedMessage id={"server.invalidFormat"} />
                        )}
                    </div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="portfield">Port</label>
                <input
                    type="text"
                    name="port"
                    id="portfield"
                    className={classnames("form-control", {
                        "is-invalid": errors.port,
                    })}
                    defaultValue={!auth ? "" : auth.port || ""}
                    ref={register()}
                />
                {errors.port && (
                    <div className="invalid-feedback">
                        {errors.port.type === "required" && (
                            <FormattedMessage id={"required"} />
                        )}
                        {errors.port.type === "pattern" && (
                            <FormattedMessage id={"server.invalidFormat"} />
                        )}
                    </div>
                )}
            </div>
            <div className="form-group">
                <label htmlFor="apikeyfield">
                    <FormattedMessage id={"server.apikey"} />
                </label>
                <input
                    type="text"
                    name="apikey"
                    id="apikeyfield"
                    className={classnames("form-control", {
                        "is-invalid": errors.apikey,
                    })}
                    defaultValue={!auth ? "" : auth.apikey}
                    ref={register({ required: true })}
                />
                {errors.apikey && (
                    <div className="invalid-feedback">
                        {errors.apikey.type === "required" && (
                            <FormattedMessage id={"required"} />
                        )}
                        {errors.apikey.type === "pattern" && (
                            <FormattedMessage id={"server.invalidFormat"} />
                        )}
                    </div>
                )}
            </div>
            <Button variant={"success"} type={"submit"}>
                <FormattedMessage id={"save"} />
            </Button>
        </form>
    );
};
