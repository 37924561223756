import React, { FunctionComponent, memo } from "react";

import "../../scss/loaders/react-slidedown";
import "./filebox.scss";
import { UploadFileItem } from "./usefilebox";
import { FormattedMessage, useIntl } from "react-intl";
import clx from "classnames";
import SlideDown from "react-slidedown";
import { RequestStatusEnum } from "../../interfaces/request";
import { faSpinner } from "@fortawesome/free-solid-svg-icons/faSpinner";
import { faDownload } from "@fortawesome/free-solid-svg-icons/faDownload";
import { faCheckSquare } from "@fortawesome/free-regular-svg-icons/faCheckSquare";
import { DateTimeField } from "../listingview/detail/components/DateTimeField";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons/faCaretDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { UploadFileDestination } from "@ozibooks/server/dist/modules/upload/types";

export type FileboxProps = {
    isOpen: boolean;
    toggleOpen: () => void;
    items: UploadFileItem[];
    markAsUploaded: (id: number) => void;
    downloadFile: (id: number) => void;
};

const Filebox: FunctionComponent<FileboxProps> = memo((props) => {
    return (
        <div className={"ob-filebox"}>
            <FileBoxHeader
                filesNum={props.items.length}
                isOpen={props.isOpen}
                toggleOpen={props.toggleOpen}
            />
            {props.items.length === 0 ? null : (
                <SlideDown closed={!props.isOpen}>
                    {!props.isOpen ? null : (
                        <FileboxBody
                            items={props.items}
                            downloadFile={props.downloadFile}
                            markAsUploaded={props.markAsUploaded}
                        />
                    )}
                </SlideDown>
            )}
        </div>
    );
});

type FileboxBodyProps = Omit<FileboxProps, "isOpen" | "toggleOpen">;
const FileboxBody: FunctionComponent<FileboxBodyProps> = ({
    items,
    markAsUploaded,
    downloadFile,
}) => {
    const intl = useIntl();

    return (
        <div className="ob-filebox__body">
            {items.map((e) => {
                const disabled = e.requestStatus === RequestStatusEnum.ACTIVE;
                const cls = clx("ob-filebox__bodyrow", {
                    "is-disabled": disabled,
                    "has-error": e.errorMsg,
                });
                return (
                    <div className={cls} key={e.id}>
                        <div className={"ob-filebox__filename"}>
                            {!disabled ? (
                                e.uploadfile
                            ) : (
                                <span className={"ob-filebox__spinner"}>
                                    <FontAwesomeIcon icon={faSpinner} spin />
                                </span>
                            )}
                        </div>
                        <div className={"ob-filebox__destination"}>
                            {UploadFileDestination[e.destination]}
                        </div>
                        <div className={"ob-filebox_filedate"}>
                            <DateTimeField dateTime={e.created_at} />
                        </div>
                        <div
                            className={"ob-filebox__icon"}
                            onClick={
                                disabled ? undefined : () => downloadFile(e.id)
                            }
                            title={intl.formatMessage({
                                id: "filebox.downloadfile",
                            })}
                        >
                            <FontAwesomeIcon icon={faDownload} />
                        </div>
                        <div
                            className={"ob-filebox__icon"}
                            onClick={
                                disabled
                                    ? undefined
                                    : () => markAsUploaded(e.id)
                            }
                            title={intl.formatMessage({
                                id: "filebox.markasdone",
                            })}
                        >
                            <FontAwesomeIcon icon={faCheckSquare} />
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const FileBoxHeader: FunctionComponent<{
    toggleOpen: () => void;
    filesNum: number;
    isOpen: boolean;
}> = ({ filesNum, isOpen, toggleOpen }) => {
    return (
        <div
            className={clx("ob-filebox__header", {
                "has-files": filesNum,
                "is-open": isOpen,
            })}
            onClick={filesNum ? toggleOpen : undefined}
        >
            <div className="ob-filebox__headertext">
                {filesNum ? (
                    <>
                        <FormattedMessage id={"filebox.files"} /> {filesNum}
                    </>
                ) : (
                    <>
                        <FormattedMessage id={"filebox.nofiles"} />
                    </>
                )}
            </div>
            {filesNum > 0 && (
                <div className="ob-filebox__headercaret">
                    <FontAwesomeIcon icon={faCaretDown} />
                </div>
            )}
        </div>
    );
};

export default Filebox;
