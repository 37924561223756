import React, {FunctionComponent, useCallback, useContext, useEffect, useState} from 'react';
import {PubloshingStatusItem} from "./types";
import {AppContext} from "../../utils/appcontext";
import {PublishingStatusTable} from "./components/table";
import {RequestStatus2, RequestStatusEnum} from "../../interfaces/request";
import {PageBody, PageHeading} from "../../components/page/page";
import {useIntl} from "react-intl";

type PageState = RequestStatus2 & {
    items: PubloshingStatusItem[],
};

const defaultState: PageState = {
    requestStatus: null,
    errorMsg: null,
    items: [],
};


export const PublishingStatusPage: FunctionComponent = () => {
    const intl = useIntl();
    const [state, setState] = useState<PageState>({...defaultState, items: []});
    const context = useContext(AppContext);
    const loadItems = useCallback(() => {
        setState({...defaultState, requestStatus: RequestStatusEnum.ACTIVE});
        context!.client.publishingStatus.getAll().then(items => {
            setState(old => ({
                ...old,
                requestStatus: RequestStatusEnum.SUCCESS,
                items: items.map(e => ({...e, requestActive: false, errorMsg: null})),
            }));
        }).catch(errorMsg => setState(old => ({
            ...old,
            requestStatus: RequestStatusEnum.FAIL,
            errorMsg,
        })));
    }, [context, setState]);
    const changeOrderable = useCallback((id: number, orderable: boolean) => {
        console.log("change orderable " + id);
        console.log(orderable);

        setState(old => ({...old, items: old.items.map(e => {
            if(e.id !== id) {
                return e;
            }

            return {...e, requestActive: true, errorMsg: null};
            })}));

        const p = orderable ?
            context!.client!.publishingStatus.setOrderable(id) :
            context!.client!.publishingStatus.setNotOrderable(id);

        p.then(data => {
            console.log(data);
            setState(old => ({
                ...old,
                items: old.items.map(e => {
                    if(e.id !== id) {
                        return e;
                    }

                    return {
                        ...e,
                        requestActive: false,
                        ...data,
                    };
                }),
            }));
        })
            .catch(errMsg => {
            setState(old => ({
                ...old,
                items: old.items.map(e => {
                    if(e.id !== id) {
                        return e;
                    }

                    return {...e, requestActive: false, errorMsg: errMsg.message};
                }),
            }));
        });
    }, [context]);
    const changeOrderableStarred = useCallback((id: number, orderable: boolean) => {

        setState(old => ({...old, items: old.items.map(e => {
                if(e.id !== id) {
                    return e;
                }

                return {...e, requestActive: true, errorMsg: null};
            })}));

        const p = orderable ?
            context!.client!.publishingStatus.setOrderableStarred(id) :
            context!.client!.publishingStatus.setNotOrderableStarred(id);

        p.then(data => {
            setState(old => ({
                ...old,
                items: old.items.map(e => {
                    if(e.id !== id) {
                        return e;
                    }

                    return {
                        ...e,
                        requestActive: false,
                        ...data,
                    };
                }),
            }));
        })
            .catch(errMsg => {
                setState(old => ({
                    ...old,
                    items: old.items.map(e => {
                        if(e.id !== id) {
                            return e;
                        }

                        return {...e, requestActive: false, errorMsg: errMsg.message};
                    }),
                }));
            });
    }, [context]);

    const {requestStatus} = state;
    useEffect(() => {
        if(requestStatus !== null) {
            return;
        }

        loadItems();
    }, [requestStatus, loadItems]);

    return (
        <div className="page">
            <PageHeading
                title={"Publishing status"}
                actions={[{
                    label: intl.formatMessage({id: "reload"}),
                    action: loadItems,
                    disabled: requestStatus === RequestStatusEnum.ACTIVE,
                }]}
            />
            <PageBody requestStatus={requestStatus} errorMsg={state.errorMsg}>
                <PublishingStatusTable
                    items={state.items}
                    changeOrderable={changeOrderable}
                    changeOrderableStarred={changeOrderableStarred}
                />
            </PageBody>
        </div>
    );
};
