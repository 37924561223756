import React, {Component, ContextType} from "react";
import {AppContext} from "../../utils/appcontext";
import ProductWithTextList, {ProductWithTextItem} from "../../components/productwithtextlist/productwithtextlist";

class BlacklistPage extends Component<any, any> {
    static contextType = AppContext;
    context!: ContextType<typeof AppContext>;
    private dictionary = {
        title: "Blacklist",
        createAction: "blacklist.addtoblacklist",
        createModalTitle: "blacklist.addtoblacklist",
        textLabel: "blacklist.reason",
        editModalTitle: "blacklist.edittitle",
        deleteHeader: "blacklist.deleteheader",
        confirmDelete: "blacklist.confirmdelete",
    };

    private loadItems = (page: number, search?: string): Promise<ProductWithTextItem[]> => {
        return this.context!
            .client
            .blacklist
            .getAll(page, search)
            .then(items => {
                return items.map(e => ({
                    product_id: e.product_id,
                    text: e.reason,
                    created_at: new Date(e.created_at),
                }));
            });
    };

    private createItem = (product_id: string, text: string): Promise<ProductWithTextItem> => {
        return this.context!
            .client
            .blacklist
            .create(product_id, text)
            .then(item => ({
                product_id: item.product_id,
                text: item.reason,
                created_at: new Date(item.created_at),
            }));
    };

    private updateItem = (product_id: string, text: string): Promise<void> => {
        return this.context!.client.blacklist.update(product_id, text);
    };

    private deleteItem = (product_id: string) => {
        return this.context!.client.blacklist.delete(product_id);
    };

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        return (
            <ProductWithTextList
                loadItems={this.loadItems}
                deleteItem={this.deleteItem}
                createItem={this.createItem}
                updateItem={this.updateItem}
                dictionary={this.dictionary}
                />
        );
    }
}

export default BlacklistPage;
