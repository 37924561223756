import React, { FunctionComponent } from "react";
import { NoteEntity } from "@ozibooks/server/dist/modules/notes/note.entity";
import "./note.css";
import { IconEdit } from "../../../components/icons/edit";
import { IconTrash } from "../../../components/icons/trash";
import { IconRecycle } from "../../../components/icons/recycle";
type NoteProps = {
    entity: NoteEntity;
    setEditing: (val: NoteEntity) => void;
    deleteNote: (val: NoteEntity) => void;
    undeleteNote: (val: NoteEntity) => void;
    trashVisible: boolean;
};

export const NoteComponent: FunctionComponent<NoteProps> = ({
    entity: item,
    setEditing,
    deleteNote,
    undeleteNote,
    trashVisible,
}) => {
    return (
        <div className="ob_note">
            {item.title ? (
                <div className="ob_note__title">{item.title}</div>
            ) : null}
            <div className="ob_note__body">{item.text}</div>
            {trashVisible ? (
                <div className="ob_note__iconbar">
                    <IconRecycle onClick={() => undeleteNote(item)} />
                </div>
            ) : (
                <div className="ob_note__iconbar">
                    <IconEdit onClick={() => setEditing(item)} />
                    <IconTrash onClick={() => deleteNote(item)} />
                </div>
            )}
        </div>
    );
};
