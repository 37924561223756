import {LangMessagesType} from "../../interfaces/lang";

export default {
    sk: {
        "errors.retry": "Skúsiť znovu",
        "errors.debuginfo": "Informácie o chybe",
        "errors.unknowclienterror.title": "Neznáma chyba",
        "errors.networkerror.title": "Chyba pripojenia",
        "errors.networkerror.desc": "Nepodarilo sa spojiť so serverom, lebo:",
        "errors.networkerror.desc1": "Server nie spustený",
        "errors.networkerror.desc2": "Adresa serveru sa zmenila",
        "errors.networkerror.desc3": "Nie ste pripojený na internet",
        "errors.timeout.title": "Operácia vypršala",
        "errors.unauthorized": "Neplatný Api kľúč",
        "errors.forbidden": "Na túto operáciu nemáte oprávnenie",
        "errors.notfound": "Záznam neexistuje",
        "errors.servererror": "Počas operácie sa na serveri vyskytla chyba",
        "errors.gatewaytimeout": "Externý server neodpovedal v časovom limite",
    },
    en: {
        "errors.retry": "Retry",
        "errors.debuginfo": "Debug info",
        "errors.unknowclienterror.title": "Unknown error",
        "errors.networkerror.title": "Connection Error",
        "errors.networkerror.desc": "Unable to connect to server, because:",
        "errors.networkerror.desc1": "Server is not running",
        "errors.networkerror.desc2": "Server address changed",
        "errors.networkerror.desc3": "You are not connected to internet",
        "errors.timeout.title": "Operation timed out",
        "errors.unauthorized": "Invalid Api key",
        "errors.forbidden": "Operation not permitted",
        "errors.notfound": "Record not found",
        "errors.servererror": "Server experienced error during operation",
        "errors.gatewaytimeout":
            "External server didn't respond within the time limit",
    },
} as LangMessagesType;
