import tableMessages from "./table/index.lang";
import {LangMessagesType} from "../../interfaces/lang";
import activationMessages from "./activation/activation.lang"

const messages: LangMessagesType =  {
    sk: {
        ...tableMessages.sk,
        ...activationMessages.sk,
        "quantity.low": "Nízky sklad",
        "quantity.high": "Vyskoký sklad",
        "quantity.zero": "Nulový sklad",
        "quantity.critical": "Kritický sklad",
    },
    en: {
        ...tableMessages.en,
        ...activationMessages.en,
        "quantity.low": "Low quantity",
        "quantity.high": "High quantity",
        "quantity.zero": "Zero quantity",
        "quantity.critical": "Critical quantity",
    },
};

export default messages;
