import React, {ChangeEvent, FormEvent, FunctionComponent, memo, useCallback, useState} from 'react';
import {useIntl} from "react-intl";
import {useHistory} from "react-router";

type Props = {

};

const SkuSearchBox: FunctionComponent<Props> = memo(() => {
    const [value, setValue] = useState("");
    const intl = useIntl();
    const history = useHistory();
    const onChange = useCallback((ev: ChangeEvent<HTMLInputElement>) => {
        const val = ev.target.value;
        setValue(() => val);
    },[setValue]);
    const onSubmit = useCallback((ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        setValue(old => {
            if(!old) {
                return old;
            }

            setTimeout(() => history.push("/amazonshop/sku/" + old), 0);
            return "";
        });
    }, [setValue, history]);

    return (
        <form className={"ob-skusearchbox"} onSubmit={onSubmit}>
            <div className="input-group">
                <input
                    type="text"
                    className="form-control"
                    placeholder={intl.formatMessage({id: "skusearch"})}
                    value={value}
                    onChange={onChange}
                        />
                    <div className="input-group-append" id="button-addon4">
                        <button className="btn btn-outline-secondary" type="submit"><span role={"img"}>🔎</span></button>
                    </div>
            </div>
        </form>
    );
});

export default SkuSearchBox;
