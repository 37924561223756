import {LangMessagesType} from "../../interfaces/lang";
import {addLangMessages} from "../../lang/messages";

const messages: LangMessagesType = {
    sk: {
        "notifications.read": "Prečítané",
        "notifications.unread": "Neprečítané",
        "notifications.markread": "Označiť ako prečítané",
        "notifications.markunread": "Označiť ako neprečítané",
        "notifications.lostquantitybody": "Po importe z {source} {count} {count, plural, one {ponuka stratila} few {ponuky stratili} other {ponúk statilo}} sklad",
        "notifications.lostquantitytitle": `{count} {count, plural, one {ponuka stratila} few {ponuky statili} other {ponúk stratilo}} sklad`,
        "notifications.blacklistedtitle": "{count} {count, plural, one {ponuka} few {ponuky} other {ponúk}} je na blackliste",
    },
    en: {
        "notifications.read": "Read",
        "notifications.unread": "Not read",
        "notifications.markread": "Mark as read",
        "notifications.markunread": "Mark as not read",
        "notifications.lostquantitybody": "After import from {source} {count} {count, plural, one {listing has} other {listings have} } lost available quantity",
        "notifications.lostquantitytitle": "{count} {count, plural, one {listing has} other {listings have} } lost available quantity",
        "notifications.blacklistedtitle": "{count} {count, plural, one {offer is} other {offers are}} on blacklist",
    }
};

addLangMessages(messages);
