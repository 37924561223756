import React, {
    FunctionComponent,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import { UploadQuantityEntity } from "@ozibooks/server/dist/modules/upload/entities/uploadquantity.entity";
import { RequestStatus2, RequestStatusEnum } from "../../../interfaces/request";
import useReactDataGrid, {
    ColumnSortType,
    SortableColumn,
} from "../../../utils/hooks/reactdatagrid";
import { HeaderRendererProps } from "react-data-grid/lib/types";
import { FormattedMessage } from "react-intl";
import ErrorMessage from "../../../components/errormessage/errormessage";
import LoadIndicator from "../../../components/loadindicator";

import "../../../scss/loaders/reactdatagrid";
import Dropdown from "react-bootstrap/Dropdown";
import DataGrid, { SelectColumn } from "react-data-grid";
import { AppException } from "../../../utils/exceptions";
import UIBlocker from "../../../components/uiblocker";
import useFileBox from "../../../components/filebox/usefilebox";
import Filebox from "../../../components/filebox/filebox";
import { UploadFileEntity } from "@ozibooks/server/dist/modules/upload/entities/uploadfile.entity";
import { UploadFileUploadType } from "@ozibooks/server/dist/modules/upload/types";

type UploadQuantityTableProps = {
    loadItems: () => Promise<UploadQuantityEntity[]>;
    deleteItems: (skus: string[]) => Promise<void>;
    generateUpload: () => Promise<UploadFileEntity[]>;
};

type UploadQuantityItem = {
    sku: string;
    quantity: number;
    currentQuantity: number;
    totalQuantity: number;
    ava: number | null;
    balmer: number | null;
    olf: number | null;
    bz: number | null;
    ziegel: number | null;
};

type UploadQuantityState = RequestStatus2 & {
    isDeletitionActive: boolean;
    deletitionErr: AppException | null;
    isGenerationActive: boolean;
    generationErr: AppException | null;
};

type SummaryRow = {
    totalRows: number;
};

const HeaderRenderer: FunctionComponent<
    HeaderRendererProps<UploadQuantityItem, SummaryRow>
> = ({ column }) => <FormattedMessage id={column.name as string} />;

const UploadQuantityTable: FunctionComponent<UploadQuantityTableProps> = ({
    loadItems,
    deleteItems,
    generateUpload,
}) => {
    const [state, setState] = useState<UploadQuantityState>({
        requestStatus: null,
        errorMsg: null,
        isDeletitionActive: false,
        deletitionErr: null,
        isGenerationActive: false,
        generationErr: null,
    });
    const clearDeletitionError = useCallback(() => {
        setState((old) => ({
            ...old,
            deletitionErr: null,
            generationErr: null,
        }));
    }, [setState]);
    const columns: SortableColumn<UploadQuantityItem, SummaryRow>[] =
        useMemo(() => {
            return [
                SelectColumn,
                {
                    name: "SKU",
                    key: "sku",
                    width: 210,
                    frozen: true,
                    sortType: ColumnSortType.STRING,
                    sortable: true,
                    resizable: true,
                    summaryFormatter: ({ row }) => (
                        <strong>Total: {row.totalRows}</strong>
                    ),
                },
                {
                    key: "quantity",
                    name: "quantitytable.newquantity",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                    headerRenderer: HeaderRenderer,
                },
                {
                    key: "currentQuantity",
                    name: "quantitytable.current",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                    headerRenderer: HeaderRenderer,
                },
                {
                    key: "totalQuantity",
                    name: "quantitytable.totalquantity",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                    headerRenderer: HeaderRenderer,
                },
                {
                    key: "ava",
                    name: "Ava",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                },
                {
                    key: "balmer",
                    name: "Balmer",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                },
                {
                    key: "olf",
                    name: "Olf",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                },
                {
                    key: "bz",
                    name: "BZ",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                },
                {
                    key: "ziegel",
                    name: "Ziegel",
                    width: 150,
                    sortType: ColumnSortType.NUMBER,
                    sortable: true,
                    resizable: true,
                },
            ];
        }, []);
    const { setRows, gridRows, props } = useReactDataGrid<
        UploadQuantityItem,
        SummaryRow
    >({
        columns,
        rows: [],
        rowKey: "sku",
        defaultSort: "totalQuantity",
        defaultSortDirection: "DESC",
    });
    const loadItemsToState = useCallback(async () => {
        setState((old) => ({
            ...old,
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
        }));

        try {
            const res = await loadItems();
            setRows(
                res.map((e) => {
                    let ava: number | null = e.ava ? e.ava.quantity : null;
                    let balmer: number | null = e.balmer
                        ? e.balmer.quantity
                        : null;
                    let olf: number | null = e.olf ? e.olf.quantity : null;
                    let bz =
                        Array.isArray(e.bzInvent) && e.bzInvent.length
                            ? e.bzInvent[0].quantity
                            : null;
                    let ziegel: number | null = e.ziegel
                        ? e.ziegel.quantity
                        : null;
                    let totalQuantity: number = [
                        ava || 0,
                        balmer || 0,
                        olf || 0,
                        bz || 0,
                        ziegel || 0,
                    ].reduce((a, b) => (a as number) + (b as number), 0);
                    return {
                        sku: e.sku,
                        quantity: e.quantity,
                        currentQuantity: e.amazonshop?.quantity || 0,
                        totalQuantity,
                        ava,
                        balmer,
                        olf,
                        bz,
                        ziegel,
                    };
                })
            );
            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.SUCCESS,
            }));
        } catch (e: any) {
            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.FAIL,
                errorMsg: e,
            }));
        }
    }, [loadItems, setRows, setState]);

    const { requestStatus } = state;
    useEffect(() => {
        if (requestStatus !== null) {
            return;
        }

        loadItemsToState().catch((err) =>
            setState((old) => ({ ...old, errorMsg: err }))
        );
    }, [requestStatus, loadItemsToState, setState]);

    const { selectedRows, onSelectedRowsChange } = props;
    const deleteSkus = useCallback(async () => {
        if (!selectedRows?.size) {
            return;
        }

        try {
            setState((old) => ({
                ...old,
                isDeletitionActive: true,
                deletitionErr: null,
            }));
            const skus = Array.from(selectedRows) as string[];

            await deleteItems(skus);
            onSelectedRowsChange && onSelectedRowsChange(new Set<React.Key>());
            setRows(gridRows.filter((e) => !skus.includes(e.sku)));
            setState((old) => ({ ...old, isDeletitionActive: false }));
        } catch (e: any) {
            setState((old) => ({
                ...old,
                isDeletitionActive: false,
                deletitionErr: e,
            }));
        }
    }, [gridRows, selectedRows, onSelectedRowsChange, setRows, deleteItems]);
    const summary: SummaryRow[] = useMemo(() => {
        return [{ totalRows: gridRows.length }];
    }, [gridRows]);

    const fbox = useFileBox();
    const fboxLoadItems = fbox.loadItems;
    const fboxStatus = fbox.requestStatus;
    const fboxSetItems = fbox.setItems;
    useEffect(() => {
        if (fboxStatus !== null) {
            return;
        }

        fboxLoadItems(UploadFileUploadType.QUANTITY, undefined, 0).catch(
            console.log
        );
    }, [fboxLoadItems, fboxStatus]);
    const runGenerateUpload = useCallback(async () => {
        setState((old) => ({
            ...old,
            isGenerationActive: true,
            generationErr: null,
        }));
        try {
            const items = await generateUpload();
            if (items.length) {
                fboxSetItems(items);
            }

            setRows([]);
            setState((old) => ({ ...old, isGenerationActive: false }));
        } catch (e: any) {
            setState((old) => ({
                ...old,
                isGenerationActive: false,
                deletitionErr: e,
            }));
        }
    }, [fboxSetItems, generateUpload, setRows]);

    if (state.errorMsg) {
        return <ErrorMessage err={state.errorMsg} onRetry={loadItemsToState} />;
    }

    if (requestStatus === RequestStatusEnum.ACTIVE || requestStatus === null) {
        return <LoadIndicator />;
    }

    return (
        <div className={"ob-rdg__wrapperouter"}>
            {!fbox.fileboxProps.items.length ? null : (
                <Filebox {...fbox.fileboxProps} />
            )}
            <div className={"ob-rdg__wrapper"}>
                {fbox.errorMsg == null ? null : (
                    <ErrorMessage err={fbox.errorMsg} />
                )}
                {(state.isDeletitionActive || state.isGenerationActive) && (
                    <UIBlocker />
                )}
                {state.deletitionErr && (
                    <ErrorMessage
                        err={state.deletitionErr}
                        onRetry={deleteSkus}
                        onClose={clearDeletitionError}
                    />
                )}
                {state.generationErr && (
                    <ErrorMessage
                        err={state.generationErr}
                        onRetry={runGenerateUpload}
                        onClose={clearDeletitionError}
                    />
                )}
                <div className="ob-rdg__buttonbar">
                    <Dropdown>
                        <Dropdown.Toggle variant={"primary"}>
                            <FormattedMessage id={"actions"} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey={"1"}
                                onClick={loadItemsToState}
                            >
                                <FormattedMessage id={"reload"} />
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"2"}
                                onClick={deleteSkus}
                                disabled={!selectedRows?.size}
                            >
                                <FormattedMessage id={"deleteselected"} />
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey={"3"}
                                onClick={runGenerateUpload}
                                disabled={!gridRows.length}
                            >
                                <FormattedMessage
                                    id={"upload.quantitygenerate"}
                                />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
                <div className="ob-rgd">
                    <DataGrid {...props} summaryRows={summary} />
                </div>
            </div>
        </div>
    );
};

export default UploadQuantityTable;
