import React, {FunctionComponent, memo} from 'react';
import classNames from "classnames";
import {FormattedMessage} from "react-intl";

type Props = {
    value: boolean | null,
    changeValue: (value: boolean) => void
};

export const YesNoButton: FunctionComponent<Props> = memo(({value, changeValue}) => {
    const clx1 = classNames("btn", {
        "btn-secondary": value === null || !value,
        "btn-success": value
    });

    const clx2 = classNames("btn", {
        "btn-secondary": value === null || value,
        "btn-danger": value === false,
    });

    return (
        <div className="btn-group">
            <button
                className={clx1}
                onClick={() => value !== true && changeValue(true)}
            ><FormattedMessage id={"yes"} /></button>
            <button
                className={clx2}
                onClick={() => value !== false && changeValue(false)}
            ><FormattedMessage id={"no"} /></button>
        </div>
    );
});
