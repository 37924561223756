import ListingView from "../../components/listingview/listingview";
import UIBlocker from "../../components/uiblocker";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import React, {
    FunctionComponent,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useState,
    MouseEvent,
} from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { SelectColumn } from "react-data-grid";
import ErrorMessage from "../../components/errormessage/errormessage";
import LoadIndicator from "../../components/loadindicator";
import TextSlectCell from "../../components/react-data-grid/components/textselectcell";
import { RequestStatus2, RequestStatusEnum } from "../../interfaces/request";
import { UnknownClientException } from "../../utils/apiclient/exceptions";
import { AppContext } from "../../utils/appcontext";
import { AppException } from "../../utils/exceptions";
import useReactDataGrid, {
    ColumnSortType,
    SortableColumn,
} from "../../utils/hooks/reactdatagrid";
import { FormattedMessage } from "react-intl";
import DataGrid from "react-data-grid";

type TableItem = {
    sku: string;
    asin: string;
    quantity: number;
    price: string | null;
    status: string;
    offersNew: number | null;
    offersTotal: number | null;
    buybox: string;
};

type TableSummary = {
    totalRows: number;
};

const mapper = (data: Amazonshop): TableItem => {
    return {
        sku: data["seller-sku"],
        asin: data.asin1,
        quantity: data.quantity,
        price: data.price,
        status: data.status,
        offersNew: data?.amazonCompetitive?.offers_new || null,
        offersTotal: data?.amazonCompetitive?.offers_total || null,
        buybox: data?.amazonCompetitive?.price || "",
    };
};

type State = RequestStatus2 & {
    items: TableItem[];
    isChangingActivation: boolean;
    changingActivationError: AppException | null;
    skuToShow: string | null;
};

type TableColumn = SortableColumn<TableItem, TableSummary>;

export const TooManyOffersTable: FunctionComponent = () => {
    const context = useContext(AppContext);
    const [state, setState] = useState<State>({
        items: [],
        changingActivationError: null,
        isChangingActivation: false,
        skuToShow: null,
        errorMsg: null,
        requestStatus: null,
    });

    const showModal = useCallback(
        (ev: MouseEvent<HTMLSpanElement>) => {
            if (!ev.currentTarget) {
                return;
            }

            const toShow = ev.currentTarget.dataset["sku"];
            if (toShow) {
                setState((old) => ({ ...old, skuToShow: toShow }));
            }
        },
        [setState]
    );

    const columns = useMemo<TableColumn[]>(() => {
        return [
            SelectColumn,
            {
                name: "SKU",
                key: "sku",
                width: 210,
                frozen: true,
                sortType: ColumnSortType.STRING,
                sortable: true,
                resizable: true,
                formatter: ({ row, isCellSelected }) => (
                    <div className="ob-rgb-cellwithpopup">
                        <TextSlectCell
                            text={row.sku}
                            isCellSelected={isCellSelected}
                        />
                        <span role="img" data-sku={row.sku} onClick={showModal}>
                            🔎
                        </span>
                    </div>
                ),
                summaryFormatter: ({ row }) => (
                    <strong>Total: {row.totalRows}</strong>
                ),
            },
            {
                key: "asin",
                name: "ASIN",
                frozen: false,
                resizable: true,
                sortable: true,
                width: 110,
                sortType: ColumnSortType.STRING,
            },
            {
                key: "status",
                name: "Stav",
                frozen: false,
                resizable: true,
                sortable: true,
                width: 110,
                sortType: ColumnSortType.STRING,
            },
            {
                key: "quantity",
                name: "Sklad",
                resizable: true,
                sortable: true,
                sortType: ColumnSortType.NUMBER,
                width: 110,
            },
            {
                key: "price",
                name: "Cena",
                frozen: false,
                resizable: true,
                sortable: true,
                width: 110,
                sortType: ColumnSortType.STRING,
            },
            {
                key: "buybox",
                name: "Buybox",
                frozen: false,
                resizable: true,
                sortable: true,
                width: 110,
                sortType: ColumnSortType.STRING,
            },

            {
                key: "offersNew",
                name: "Nové ponuky",
                frozen: false,
                resizable: true,
                sortable: true,
                width: 110,
                sortType: ColumnSortType.NUMBER,
            },
            {
                key: "offersTotal",
                name: "Celkovo ponúk",
                frozen: false,
                resizable: true,
                sortable: true,
                width: 150,
                sortType: ColumnSortType.NUMBER,
            },
        ];
    }, [showModal]);

    const { setRows, gridRows, props } = useReactDataGrid<
        TableItem,
        TableSummary
    >({
        columns,
        rows: [],
        rowKey: "sku",
        defaultSort: "sku",
        defaultSortDirection: "NONE",
    });

    const loadItems = useCallback(async () => {
        if (!context?.client) {
            return;
        }

        setState((old) => ({
            ...old,
            requestStatus: RequestStatusEnum.ACTIVE,
            items: [],
            errorMsg: null,
        }));

        try {
            const res = await context.client.amazonshop.tooManyOffers();
            setRows(res.map(mapper));
            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.SUCCESS,
            }));
        } catch (err: any) {
            setState((old) => ({
                ...old,
                requestStatus: RequestStatusEnum.FAIL,
                errorMsg: err,
            }));
        }
    }, [context, setRows]);

    useEffect(() => {
        if (state.requestStatus !== null) {
            return;
        }

        console.log("Loading items from effect");
        loadItems().catch(console.log);
    }, [state.requestStatus, loadItems]);

    const { selectedRows, onSelectedRowsChange, rows } = props;
    const summary: TableSummary[] = useMemo(() => {
        return [{ totalRows: rows.length }];
    }, [rows]);

    const runChangeActivation = async () => {
        if (!selectedRows || !selectedRows.size) {
            return;
        }

        if (!context?.client) {
            return;
        }

        const toChange = Array.from(selectedRows) as string[];

        if (!toChange.length) {
            return;
        }

        setState((old) => ({
            ...old,
            isChangingActivation: true,
            changingActivationError: null,
        }));

        try {
            await context.client.upload.quantity.create(
                toChange.map((e) => ({
                    sku: e,
                    quantity: 0,
                }))
            );
            onSelectedRowsChange && onSelectedRowsChange(new Set<React.Key>());
            setRows(gridRows.filter((e) => !toChange.includes(e.sku)));
            setState((old) => ({ ...old, isChangingActivation: false }));
        } catch (err: any) {
            setState((old) => ({
                ...old,
                isChangingActivation: false,
                changingActivationError: err,
            }));
        }
    };

    const { requestStatus } = state;

    if (requestStatus === RequestStatusEnum.ACTIVE || requestStatus === null) {
        return <LoadIndicator />;
    }

    if (requestStatus === RequestStatusEnum.FAIL && state.errorMsg) {
        return (
            <ErrorMessage
                err={
                    state.errorMsg instanceof AppException
                        ? state.errorMsg
                        : new UnknownClientException("")
                }
                onRetry={loadItems}
            />
        );
    }

    return (
        <div className={"ob-rdg__wrapper"}>
            {state.skuToShow && (
                <Modal
                    show={!!state.skuToShow}
                    animation={true}
                    size={"xl"}
                    onHide={() =>
                        setState((old) => ({ ...old, skuToShow: null }))
                    }
                >
                    <Modal.Header closeButton />
                    <Modal.Body>
                        <ListingView sku={state.skuToShow} />
                    </Modal.Body>
                </Modal>
            )}
            {state.isChangingActivation && <UIBlocker />}
            {state.changingActivationError && (
                <ErrorMessage
                    err={state.changingActivationError}
                    onClose={() =>
                        setState((old) => ({
                            ...old,
                            changingActivationError: null,
                        }))
                    }
                    onRetry={runChangeActivation}
                />
            )}
            <div className="ob-rdg__buttonbar">
                <Dropdown>
                    <Dropdown.Toggle variant={"primary"}>
                        <FormattedMessage id={"actions"} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item eventKey={"1"} onClick={loadItems}>
                            <FormattedMessage id={"reload"} />
                        </Dropdown.Item>
                        <Dropdown.Item
                            eventKey={"2"}
                            onClick={runChangeActivation}
                            disabled={!selectedRows?.size}
                        >
                            <FormattedMessage id={"quantity.deactivate"} />
                        </Dropdown.Item>
                    </Dropdown.Menu>
                </Dropdown>
            </div>
            <div className="ob-rgd">
                <DataGrid {...props} summaryRows={summary} />
            </div>
        </div>
    );
};

export const TooManyOffersPage = () => {
    return (
        <div className={"page"}>
            <h3>Viac ako 5 ponúk</h3>
            <TooManyOffersTable />
        </div>
    );
};
