import {createContext} from "react";
import {ApiClient} from "./apiclient/apiclient";
import {AppStorage} from "./storage/appstorage";

export type AppContextType = {
    client: ApiClient,
    storage: AppStorage,
    currentLang: string,
    changeLang: (newLang: string) => void;
}

export const AppContext = createContext<AppContextType | null>(null);
