import React, {Component, FunctionComponent, useCallback, useMemo, useState} from 'react';
import {injectIntl, IntlShape, WrappedComponentProps} from "react-intl";
import {RequestStatus2, RequestStatusEnum} from "../../interfaces/request";
import {PageBody, PageHeading} from "../page/page";
import "./productwithtextlist.scss";
import SearchBox from "./components/searchbox";
import Pagination from "react-bootstrap/Pagination";
import ActionMenu, {ActionMenuActionType} from "../actionmenu/actionmenu";
import Modal from "react-bootstrap/Modal";
import {ProductWithTextForm} from "./components/form";
import ErrorMessage from "../errormessage/errormessage";

const PAGE_LIMIT = 100;

export type ProductWithTextItem = {
    product_id: string,
    text: string,
    created_at: Date,
    updated_at?: Date,
};

type BaseProps = {
    loadItems: (page: number, search?: string) => Promise<ProductWithTextItem[]>,
    deleteItem: (product_id: string) => Promise<void>,
    createItem: (product_id: string, text: string) => Promise<ProductWithTextItem>,
    updateItem: (product_id: string, text: string) => Promise<void>,
    dictionary: Record<string, string>,
};

type Props = BaseProps & WrappedComponentProps;

type State = RequestStatus2 & {
    items: ProductWithTextItem[],
    page: number,
    search: string,
    hasNextPage: boolean,
    isCreating: boolean,
    isEditing: ProductWithTextItem | null,
    isDeleting: ProductWithTextItem | null,
}

class ProductWithTextList extends Component<Props, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            requestStatus: null,
            errorMsg: null,
            items: [],
            page: 1,
            search: "",
            hasNextPage: false,
            isCreating: false,
            isEditing: null,
            isDeleting: null,
        }
    }

    private loadItems = (page: number, search?: string) => {
        this.setState({
            requestStatus: RequestStatusEnum.ACTIVE,
            errorMsg: null,
            page,
            search: search || "",
            hasNextPage: false,
        });

        this.props
            .loadItems(page, search)
            .then(items => {
                this.setState({
                    requestStatus: RequestStatusEnum.SUCCESS,
                    items,
                    hasNextPage: items.length === PAGE_LIMIT,
                });
            }).catch(err => {
                this.setState({
                    requestStatus: RequestStatusEnum.FAIL,
                    errorMsg: err,
                });
        });
    };
    private reload = () => {
        this.loadItems(this.state.page, this.state.search);
    };
    private startSearch = (search: string) => {
        this.loadItems(1, search);
    };
    private showCreateModal = () => {
        this.setState({isCreating: true});
    };
    private hideModals = () => {
        this.setState({
            isCreating: false,
            isDeleting: null,
            isEditing: null,
        });
    };
    private createItem = (product_id: string, text: string) => {
        return this.props.createItem(product_id, text).then(item => {
            this.setState(old => ({...old, items: [...old.items, item]}));
        });
    };
    private updateItem = (product_id: string, text: string) => {
        const item = this.state.items.filter(e => e.product_id === product_id)[0];
        if(!item) {
            return Promise.resolve();
        }

        return this.props.updateItem(product_id, text).then(() => {
            this.setState(old => ({
                ...old,
                items: old.items.map(e => {
                    if(e.product_id !== product_id) {
                        return e;
                    }
                    return {...e, text};
                }),
            }));
        });
    };
    private setEditing = (product_id: string): void => {
        const item = this.state.items.filter(e => e.product_id === product_id)[0];
        if(!item) {
            return;
        }

        this.setState({isEditing: {...item}});
    };
    private setDeleting = (product_id: string): void => {
        const item = this.state.items.filter(e => e.product_id === product_id)[0];
        if(!item) {
            return;
        }

        this.setState({isDeleting: {...item}});
    };
    private deleteItem = (product_id: string): Promise<void> => {
        const item = this.state.items.filter(e => e.product_id === product_id)[0];
        if(!item) {
            return Promise.resolve();
        }

        return this.props.deleteItem(product_id).then(() => {
            this.setState(old => ({
                isDeleting: null,
                items: old.items.filter(e => e.product_id !== product_id),
            }));
        });
    };

    componentDidMount(): void {
        if(this.state.requestStatus === null) {
            this.loadItems(1);
        }
    }

    private renderPagination() {
        const {page, hasNextPage, search} = this.state;
        if(page === 1 && !hasNextPage) {
            return null;
        }

        return (
            <Pagination>
                <Pagination.First
                    onClick={() => this.loadItems(1, search)}
                    disabled={page === 1}
                />
                <Pagination.Prev
                    onClick={() => this.loadItems(page - 1, search)}
                    disabled={page === 1}
                />
                <Pagination.Item active>{page}</Pagination.Item>
                <Pagination.Next
                    disabled={!hasNextPage}
                    onClick={() => this.loadItems(page + 1, search)}
                />
            </Pagination>
        );
    }

    render(): React.ReactElement<any, string | React.JSXElementConstructor<any>> | string | number | {} | React.ReactNodeArray | React.ReactPortal | boolean | null | undefined {
        const {dictionary, intl} = this.props;
        const actions: ActionMenuActionType[] = [
            {
                label: intl.formatMessage({id: "reload"}),
                action: this.reload,
                disabled: false,
            },
            {
                label: intl.formatMessage({id: dictionary.createAction}),
                action: this.showCreateModal,
                disabled: false,
            }
        ];
        return (
            <div className="page">
                <PageHeading title={intl.formatMessage({id: dictionary.title})} actions={actions} />
                <PageBody
                    requestStatus={this.state.requestStatus}
                    errorMsg={this.state.errorMsg}
                    onRetry={this.reload}
                >
                    <div className="ob-productwithtext">
                        <div className="ob-productwithtext__searchbar">
                            <SearchBox key={this.state.search} initialText={this.state.search} onSubmit={this.startSearch}/>
                        </div>
                        {this.state.items.length === 0 ? (
                            <div>{this.props.intl.formatMessage({id: "nodata"})}</div>
                        ) : (
                            this.state.items.map(e => (
                                <Item
                                    key={e.product_id}
                                    item={e}
                                    intl={intl}
                                    setEditing={this.setEditing}
                                    setDeleting={this.setDeleting}
                                />
                            ))
                        )}
                        {this.renderPagination()}
                    </div>
                </PageBody>
                {this.state.isCreating && (
                    <Modal
                        key={"createmodal"}
                        show={this.state.isCreating}
                        animation={true}
                        size={"sm"}
                        onHide={this.hideModals}
                    >
                        <Modal.Header closeButton={true}>{intl.formatMessage({id: dictionary.createModalTitle})}</Modal.Header>
                        <Modal.Body>
                            <ProductWithTextForm
                                onClose={this.hideModals}
                                textLabel={intl.formatMessage({id: dictionary.textLabel})}
                                onCreate={this.createItem}
                                onUpdate={this.updateItem}
                                />
                        </Modal.Body>
                    </Modal>
                )}
                {!!this.state.isEditing && (
                    <Modal
                        key={"editmodal-" + this.state.isEditing.product_id}
                        show={!!this.state.isEditing}
                        animation={true}
                        size={"sm"}
                        onHide={this.hideModals}
                    >
                        <Modal.Header closeButton={true}>
                            {intl.formatMessage({id: dictionary.editModalTitle}) + " " + this.state.isEditing.product_id}
                        </Modal.Header>
                        <Modal.Body>
                            <ProductWithTextForm
                                onClose={this.hideModals}
                                textLabel={intl.formatMessage({id: dictionary.textLabel})}
                                onCreate={this.createItem}
                                onUpdate={this.updateItem}
                                product_id={this.state.isEditing?.product_id}
                                initalText={this.state.isEditing?.text}
                            />
                        </Modal.Body>
                    </Modal>
                )}
                {!!this.state.isDeleting && (
                    <DeleteModal
                        key={"deletemodal-" + this.state.isDeleting?.product_id}
                        onClose={this.hideModals}
                        deleteHeader={dictionary.deleteHeader}
                        confirmDelete={dictionary.confirmDelete}
                        intl={intl}
                        onDelete={this.deleteItem}
                        product_id={this.state.isDeleting.product_id}
                    />
                )}
            </div>
        );
    }
}

type DeleteModalProps = {
    product_id: string,
    deleteHeader: string,
    confirmDelete: string,
    onClose: () => void,
    onDelete: (product_id: string) => Promise<void>,
    intl: IntlShape,
};

type DeleteModalState = RequestStatus2;

const DeleteModal: FunctionComponent<DeleteModalProps> = ({onClose, onDelete, intl, product_id, deleteHeader, confirmDelete}) => {
    const [state, setState] = useState<DeleteModalState>({requestStatus: null, errorMsg: null});
    const handleDelete = useCallback(() => {
        setState(old => ({...old, requestStatus: RequestStatusEnum.ACTIVE, errorMsg: null}));
        onDelete(product_id)
            .then(onClose)
            .catch(err => setState(old => ({...old, requestStatus: RequestStatusEnum.FAIL, errorMsg: err})));
    }, [onClose, onDelete, product_id]);

    return (
        <Modal
            show={true}
            animation={true}
            onHide={onClose}
        >
            <Modal.Header closeButton={true}>
                <Modal.Title>{intl.formatMessage({id: deleteHeader}, {id: product_id})}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {state.errorMsg && <ErrorMessage err={state.errorMsg} />}
                <p>{intl.formatMessage({id: confirmDelete}, {id: product_id})}</p>
            </Modal.Body>
            <Modal.Footer>
                <button
                    className={"btn btn-danger"}
                    disabled={state.requestStatus === RequestStatusEnum.ACTIVE}
                    onClick={handleDelete}
                >{intl.formatMessage({id: "delete"})}</button>
                <button
                    className={"btn btn-secondary"}
                    disabled={state.requestStatus === RequestStatusEnum.ACTIVE}
                    onClick={onClose}
                >{intl.formatMessage({id: "cancel"})}</button>
            </Modal.Footer>
        </Modal>
    );
};

type ItemProps = {
    item: ProductWithTextItem,
    intl: IntlShape,
    setEditing: (product_id: string) => void,
    setDeleting: (product_id: string) => void,
}

const Item: FunctionComponent<ItemProps> = ({item, intl, setDeleting, setEditing}) => {
    const {product_id} = item;
    const actions: ActionMenuActionType[] = useMemo(() => {
        return [
            {
                label: intl.formatMessage({id: "edit"}),
                action: () => setEditing(product_id),
                disabled: false,
            },
            {
                label: intl.formatMessage({id: "delete"}),
                action: () => setDeleting(product_id),
                disabled: false,
            }
        ];
    }, [product_id, setDeleting, setEditing, intl]);
    return (
        <div className="ob-productwithtext__item">
            <div className="ob-productwithtext__itemtitle">
                <h5>{product_id}</h5>
                <ActionMenu actions={actions}/>
            </div>
            <p>{item.text}</p>
            <div className="ob-productwithtext__datebar">
                <em className={"text-muted small"}
                >{intl.formatMessage({id: "created_at"})}: {intl.formatDate(item.created_at, {timeStyle: "medium", dateStyle: "short"})}</em>
                {!!item.updated_at && (
                    <em className={"text-muted small"}
                    >{intl.formatMessage({id: "updated_at"})}: {intl.formatDate(item.updated_at, {timeStyle: "medium", dateStyle: "short"})}</em>
                )}
            </div>
        </div>
    );
};

export default injectIntl<"intl", Props>(ProductWithTextList);



