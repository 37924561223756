import React, { FunctionComponent, useMemo } from "react";
import { Amazonshop } from "@ozibooks/server/dist/modules/amazon/entities/amazonshop.entity";
import { IntlShape } from "react-intl";

type Props = {
    entity: Amazonshop;
    intl: IntlShape;
};

type PriceData = {
    source: string;
    price: string;
};

const ListingViewPrices: FunctionComponent<Props> = ({ entity, intl }) => {
    const priceData: { source: string; price: string }[] = useMemo(() => {
        const ret: PriceData[] = [
            {
                source: intl.formatMessage({ id: "listingview.listingprice" }),
                price: entity.price || "",
            },
        ];
        if (entity.bz && entity.bz.length) {
            entity.bz.forEach((e) => {
                let price = e.price ?? "";
                if (typeof e.rabat === "number") {
                    price += ` (${e.rabat}%)`;
                }
                ret.push({ source: "BZ", price });
            });
        }

        if (entity.olf) {
            ret.push({
                source: "Olf",
                price: entity.olf.price,
            });
        }

        if (entity.ava) {
            ret.push({
                source: "Ava",
                price: entity.ava.price || "",
            });
        }

        if (entity.balmer) {
            ret.push({
                source: "Balmer",
                price: entity.balmer.price,
            });
        }

        if (entity.ziegel) {
            ret.push({
                source: "Ziegel",
                price: entity.ziegel.price,
            });
        }

        if (entity.avaWeb && entity.avaWeb.length) {
            entity.avaWeb.forEach((e) => {
                let price = e.price ?? "";
                if (typeof e.rabat === "number") {
                    price += ` (${e.rabat}%)`;
                }
                ret.push({
                    source: "Ava web",
                    price,
                });
            });
        }

        if (entity.balmerWeb && entity.balmerWeb.length) {
            entity.balmerWeb.forEach((e) => {
                let price = e.price ?? "";
                ret.push({
                    source: "Balmer web",
                    price,
                });
            });
        }

        if (entity.amazonCompetitive) {
            let price = "";
            if (entity.amazonCompetitive.price) {
                price = entity.amazonCompetitive.price;
            }

            if (price && entity.amazonCompetitive.shipping) {
                price += " +" + entity.amazonCompetitive.shipping;
            }
            ret.push({
                source: "Buybox",
                price,
            });
        }

        if (entity.amazonLowest) {
            let newPrice = "";
            let usedPrice = "";
            if (entity.amazonLowest.new_price) {
                newPrice += entity.amazonLowest.new_price;
            }

            if (newPrice && entity.amazonLowest.new_shipping) {
                newPrice += " +" + entity.amazonLowest.new_shipping;
            }

            if (entity.amazonLowest.new_fba) {
                newPrice += "(A)";
            }

            if (newPrice) {
                ret.push({
                    source: "Amazon lowest new",
                    price: newPrice,
                });
            }

            if (entity.amazonLowest.used_price) {
                usedPrice += entity.amazonLowest.used_price;
                if (entity.amazonLowest.used_shipping) {
                    usedPrice += " +" + entity.amazonLowest.used_shipping;
                }
            }

            if (entity.amazonLowest.used_fba) {
                usedPrice += "(A)";
            }

            if (usedPrice) {
                ret.push({
                    source: "Amazon lowest used",
                    price: usedPrice,
                });
            }
        }

        return ret;
    }, [entity, intl]);

    return (
        <div className={"ob-listingview__prices"}>
            <table className="table table-bordered">
                <thead>
                    <tr>
                        {priceData.map((e, i) => (
                            <th key={e.source + i}>{e.source}</th>
                        ))}
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        {priceData.map((e, i) => (
                            <td key={e.source + i}>{e.price}</td>
                        ))}
                    </tr>
                </tbody>
            </table>
        </div>
    );
};

export default ListingViewPrices;
