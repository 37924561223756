import React, { FunctionComponent } from "react";
import { PageBody } from "../../components/page/page";
import { useNotes } from "./hooks/use-notes";
import { NotesComponent } from "./components/notes";
import { NoteSearchBar } from "./components/searchbar";
import { NoteForm } from "./components/noteform";

export const NotesPage: FunctionComponent = () => {
    const {
        state,
        loadItems,
        setSearch,
        setCreating,
        setEditing,
        addNote,
        replaceNote,
        deleteNote,
        setTrashVisible,
        undeleteNote,
    } = useNotes();

    if (state.requestStatus === null) {
        loadItems();
    }

    let formKey = "default";
    if (state.isCreating) {
        formKey = "creating";
    }

    if (state.isEditing) {
        formKey = "editing" + state.isEditing.id;
    }

    return (
        <div className="page">
            <div className="ob-pageheading">
                <h1>Poznámky</h1>
            </div>
            <NoteSearchBar
                search={state.search}
                setSearch={setSearch}
                setCreating={setCreating}
                loadItems={loadItems}
                trashVisible={state.trashVisible}
                setTrashVisible={setTrashVisible}
            />
            <PageBody
                errorMsg={state.errorMsg}
                requestStatus={state.requestStatus}
            >
                <NotesComponent
                    deleteNote={deleteNote}
                    setEditing={setEditing}
                    notes={state.notes}
                    trashVisible={state.trashVisible}
                    undeleteNote={undeleteNote}
                />
                <NoteForm
                    key={formKey}
                    addNote={addNote}
                    isCreating={state.isCreating}
                    isEditing={state.isEditing}
                    replaceNote={replaceNote}
                    setCreating={setCreating}
                    setEditing={setEditing}
                />
            </PageBody>
        </div>
    );
};
