import React, { FunctionComponent, useContext, useState } from "react";
import { TagEntity } from "@ozibooks/server/dist/modules/tags/entities/tag.entity";
import { AppContext } from "../../../utils/appcontext";
import { RequestStatus2, RequestStatusEnum } from "../../../interfaces/request";
import UIBlocker from "../../../components/uiblocker";
import ErrorMessage from "../../../components/errormessage/errormessage";
import { useIntl } from "react-intl";

type Props = {
    entity?: TagEntity;
    onEntityUpdated?: (e: TagEntity) => void;
    onEntityCreated?: (e: TagEntity) => void;
    onClose: () => void;
};

type State = RequestStatus2 & {
    name: string;
    color: string;
    isChanged: boolean;
};

export const EditTagForm: FunctionComponent<Props> = ({
    entity,
    onClose,
    onEntityCreated,
    onEntityUpdated,
}) => {
    const [state, setState] = useState<State>({
        name: entity ? entity.name : "",
        color: entity && entity.color ? entity.color : "",
        isChanged: false,
        requestStatus: null,
        errorMsg: null,
    });
    const context = useContext(AppContext);
    const intl = useIntl();

    return (
        <div className={"obedittagform"}>
            {state.requestStatus === RequestStatusEnum.ACTIVE && <UIBlocker />}
            {state.errorMsg && <ErrorMessage err={state.errorMsg} />}
            <form
                onSubmit={(ev) => {
                    ev.preventDefault();
                    const { color, name } = state;
                    setState((old) => ({
                        ...old,
                        errorMsg: null,
                        requestStatus: RequestStatusEnum.ACTIVE,
                    }));
                    if (entity && entity.id) {
                        context!.client.tags
                            .updateTag(
                                entity.id,
                                name.trim(),
                                color ? color : undefined
                            )
                            .then(() => {
                                setState((old) => ({
                                    ...old,
                                    requestStatus: RequestStatusEnum.SUCCESS,
                                }));
                                onEntityUpdated &&
                                    onEntityUpdated({
                                        ...entity,
                                        name: name.trim(),
                                        color: color ? color : null,
                                    });
                            })
                            .catch((err) => {
                                setState((old) => ({
                                    ...old,
                                    requestStatus: RequestStatusEnum.FAIL,
                                    errorMsg: err,
                                }));
                            });
                    } else {
                        context!.client.tags
                            .createTag(name.trim(), color ? color : undefined)
                            .then((ent) => {
                                setState((old) => ({
                                    ...old,
                                    requestStatus: RequestStatusEnum.SUCCESS,
                                }));
                                onEntityCreated && onEntityCreated(ent);
                            })
                            .catch((err) =>
                                setState((old) => ({
                                    ...old,
                                    requestStatus: RequestStatusEnum.FAIL,
                                    errorMsg: err,
                                }))
                            );
                    }
                }}
            >
                <div className="form-group">
                    <label>{intl.formatMessage({ id: "tags.tagname" })}</label>
                    <input
                        className="form-control"
                        value={state.name}
                        onChange={(ev) => {
                            const newVal = ev.target.value;
                            setState((old) => ({
                                ...old,
                                name: newVal,
                                isChanged: true,
                            }));
                        }}
                    />
                </div>
                <div className="form-group">
                    <label>{intl.formatMessage({ id: "tags.color" })}</label>
                    <select
                        className={"form-control"}
                        value={state.color}
                        onChange={(ev) => {
                            const newVal = ev.target.value;
                            setState((old) => ({
                                ...old,
                                isChanged: true,
                                color: newVal,
                            }));
                        }}
                    >
                        <option value={""}>
                            {intl.formatMessage({ id: "tags.selectcolor" })}
                        </option>
                        <option value={"blue"}>Blue</option>
                        <option value={"indigo"}>Indigo</option>
                        <option value={"purple"}>Purple</option>
                        <option value={"pink"}>Pink</option>
                        <option value={"red"}>Red</option>
                        <option value={"yellow"}>Yellow</option>
                        <option value={"green"}>Green</option>
                        <option value={"cyan"}>Cyan</option>
                        <option value={"orange"}>Orange</option>
                    </select>
                </div>
                <div className={"obedittagform__buttonbar"}>
                    <button
                        className={"btn btn-success"}
                        disabled={!state.isChanged}
                        style={{ marginRight: "1rem" }}
                    >
                        {intl.formatMessage({ id: "save" })}
                    </button>
                    <button className={"btn btn-secondary"} onClick={onClose}>
                        {intl.formatMessage({ id: "cancel" })}
                    </button>
                </div>
            </form>
        </div>
    );
};
