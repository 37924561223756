import React, {FunctionComponent} from 'react';
import {ServerSettingsForm} from "./components/server";
import {FormattedMessage} from "react-intl";

type Props = {

};

export const SettingsPage: FunctionComponent<Props> = () => {
    return (
        <div className="page">
            <h1><FormattedMessage id="settings" /></h1>
            <ServerSettingsForm onSave={console.log} initialData={{host: "", port: null, apikey: ""}}/>
        </div>
    );
};
